import { atom } from 'recoil';

export const modalAtom = atom({
  key: 'modalState',
  default: {
    title: '',
    message: '',
    show: false,
  },
});
