import React from 'react';
import { Box, Text } from 'native-base';
import { defaultTextColors } from '../../utilites/utilities';

const CompanyBadge = (props) => {
  const { companyStatus } = props;
  const active = companyStatus === 'ATIVA';
  return (
    <Box
      py={2}
      px={4}
      borderRadius="3xl"
      alignItems="center"
      bgColor={active ? 'rgba(0, 168, 179, 0.15)' : 'rgba(236, 170, 36, 0.15)'}
      {...props}
    >
      <Text
        bold
        fontSize={['sm', 'sm', 'sm', '']}
        color={defaultTextColors.secondary}
      >
        {companyStatus}
      </Text>
    </Box>
  );
};

export default CompanyBadge;
