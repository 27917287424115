import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  id: 0,
  name: '',
  email: '',
  whitelabel_customer: {},
};

export const { Types, Creators } = createActions({
  resetUser: [''],
  removeUser: [''],
  insertUser: ['data'],
});

const insert = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.data,
});

const remove = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const reset = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [Types.RESET_USER]: reset,
  [Types.INSERT_USER]: insert,
  [Types.REMOVE_USER]: remove,
});
