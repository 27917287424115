import React from 'react';
import { get, find } from 'lodash';
import { useRecoilValue } from 'recoil';
import { Box, Button, Heading, Image, Text, ScrollView } from 'native-base';
import {
  defaultComponentsColors,
  defaultTextColors,
  handleOpenLink,
} from '../../utilites/utilities';
import ChatImage from '../../assets/chat.png';
import Container from '../../components/Container';
import { themeAtom } from '../../Recoil/Atoms/theme';

const Chat = () => {
  const theme = useRecoilValue(themeAtom);
  const companyContacts = get(theme, 'contact_infos', '');
  const whatsappContact = find(companyContacts, ['contact_info_type_id', 1]);
  return (
    <Container
      flexWrap="nowrap"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <ScrollView
        _contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 20,
          flexDirection: 'column',
        }}
      >
        <Image
          my={2}
          mx="auto"
          source={ChatImage}
          h={[280, 350, 350, 400]}
          w={[280, 350, 350, 400]}
          alt="Imagem app solucoes"
        />
        <Heading
          mt={6}
          color={defaultTextColors.secondary}
          fontSize={['2xl', '2xl', '2xl', '3xl']}
          textAlign={['left', 'left', 'left', 'center']}
        >
          Atendimento exclusivo para você
        </Heading>
        <Text
          mt={4}
          fontWeight="medium"
          color="rgba(44, 45, 73, 0.4)"
          fontSize={['md', 'md', 'md', 'lg']}
          textAlign={['left', 'left', 'left', 'center']}
        >
          Clique no botão abaixo para nos chamar no WhatsApp!
        </Text>
      </ScrollView>
      <Box
        left={0}
        right={0}
        bottom={0}
        roundedTop="2xl"
        py={[4, 4, 4, 6]}
        position="absolute"
        bgColor={['#FFF', '#FFF', '#FFF', 'rgba(0, 0, 0, 0)']}
      >
        <Button
          py={2}
          px={2}
          w="2/3"
          mx="auto"
          rounded="full"
          onPress={() =>
            handleOpenLink(
              `https://${get(whatsappContact, 'contact_info', '')}`
            )
          }
          _text={{ color: '#FFF', fontSize: 'lg' }}
          bgColor={defaultComponentsColors.warning}
        >
          Conversar com um atendente
        </Button>
      </Box>
    </Container>
  );
};

export default Chat;
