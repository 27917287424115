import axios from 'axios';
import {
  APP_URL,
  API_URL,
  // eslint-disable-next-line
} from '@env';
import { forEach, get } from 'lodash';
import store from '../redux/Store';
import { handleLogout } from '../utilites/utilities';
import { showToastMessage } from '../utilites/ToastProvider';

const instanceHeader = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const instance = axios.create({
  baseURL: `${APP_URL}${API_URL}`,
  validateStatus: (status) => status >= 200 && status < 400,
});

const tokenChangeListener = () => {
  instance.defaults.headers.common.Authorization = `Bearer ${get(
    store.getState(),
    'token.plainTextToken',
    ''
  )}`;
  instance.defaults.headers.common['amplitude-device-id'] = get(
    store.getState(),
    'amplitude.deviceId',
    null
  );
  instance.defaults.headers.common['amplitude-session-id'] = get(
    store.getState(),
    'amplitude.sessionId',
    null
  );
};

const handleErrorsArray = (errors) => {
  let errorsMessage = '';
  forEach(errors, (error) => {
    errorsMessage += `${error[0]} <br /> `;
  });
  return errorsMessage;
};

export const requestErrorHandler = (response) => {
  const requestMessage = get(response, 'request.response', null);

  if (requestMessage) {
    const dataParse = JSON.parse(requestMessage);
    const errorMessage = get(dataParse, 'data.message', null);
    if (errorMessage) {
      return errorMessage;
    }
  }

  if (response.data) {
    const { data } = response;

    if (data.errors) {
      return handleErrorsArray(data.errors);
    }

    if (data.message) {
      return data.message;
    }

    return data;
  }

  if (response.response) {
    const { data } = response.response;

    if (data.errors) {
      return handleErrorsArray(data.errors);
    }

    if (data.message) {
      return data.message;
    }

    return data.toString;
  }

  if (response && response.data && response.data.data) {
    const errorData = response.data.data;

    if (errorData.message) {
      return errorData.message;
    }

    return errorData;
  }

  if (response.message) {
    return response.message.toString();
  }

  return response;
};

store.subscribe(tokenChangeListener);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const code = get(error, 'response.status', 400);
    if (code !== 404 && code !== 401 && code !== 422) {
      showToastMessage('Erro', requestErrorHandler(error), 'error');
    }
    if (code === 401) {
      showToastMessage(
        'Sessão expirada',
        'Sua sessão expirou, faça login novamente para continuar.',
        'error'
      );
      await handleLogout();
    }
    return Promise.reject(error);
  }
);

export default async function SimpleRequestHandler(
  url,
  method,
  body = undefined
) {
  const config = {
    headers: {
      ...instanceHeader,
    },
  };

  if (method === 'GET' && body) {
    config.params = body;
    config.data = undefined;
  } else {
    config.data = body;
  }

  return instance.request({
    url,
    method,
    ...config,
  });
}
