import React, { useEffect, useReducer, useState } from 'react';
import {
  Box,
  Button,
  Center,
  CloseIcon,
  FlatList,
  Flex,
  Heading,
  Pressable,
  ScrollView,
  Spinner,
  Text,
} from 'native-base';
import { Platform } from 'react-native';
import { useRecoilValue } from 'recoil';
import { filter as _filter, get, uniq } from 'lodash';
import { useIsFocused } from '@react-navigation/native';
import DasItem from './DasItem';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';
import DasFilterDropdown from './DasFilterDropdown';
import CircleSnail from '../Global/Loading';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { selectedCompanyAtom } from '../../Recoil/Atoms/selectedCompany';
import SimpleRequestHandler from '../../services/meishop';
import ServicesIcon from '../../utilites/ServicesIcon';

const DasStatusController = ({ navigation }) => {
  const isFocused = useIsFocused();
  const colors = useRecoilValue(themeAtom).layout;
  const userCompany = useRecoilValue(selectedCompanyAtom);

  const [das, setDas] = useState([]);
  const [years, setYears] = useState([]);
  const [allDas, setAllDas] = useState([]);
  const [status, setStatus] = useState('INITIAL');
  const [pendingDas, setPendingDas] = useState([]);
  const [hasPendingDas, setHasPendingDas] = useState(false);
  const [loadingPendingDas, setLoadingPendingDas] = useState(true);
  const [filter, addFilter] = useReducer(
    (state, userState) => ({ ...state, ...userState }),
    { year: '', periodo: '' }
  );

  const fillYears = (dasObject) => {
    const availableYears = [];
    dasObject.map((item) => {
      const split = item.periodo_desc.split('/');
      availableYears.push(split[1]);
      return item;
    });
    const unifiedYears = uniq(availableYears);
    setYears(['Todos', ...unifiedYears.sort().reverse()]);
  };

  const getCompanyDas = () => {
    setLoadingPendingDas(true);
    SimpleRequestHandler(`company/${userCompany.id}/das`, 'GET')
      .then((response) => {
        const dasResponse = get(response.data, 'data.data.das', []);
        setDas(dasResponse);
        setAllDas(dasResponse);
        fillYears(dasResponse);
        setPendingDas(_filter(dasResponse, (o) => o.situacao !== 'LIQUIDADO'));
        setStatus(get(response.data, 'data.status', 'ERROR'));
        setLoadingPendingDas(false);
      })
      .catch(() => {
        setStatus('ERROR');
        setLoadingPendingDas(false);
      });
  };

  const setFilter = (value) => {
    addFilter({
      year: value,
    });
  };

  const goHome = () => {
    navigation.navigate('Dashboard');
  };

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Dashboard');
    }
  };

  useEffect(() => {
    if (!loadingPendingDas) {
      setHasPendingDas(pendingDas.length >= 1);
    }
  }, [loadingPendingDas]);

  useEffect(() => {
    if (isFocused) {
      setStatus('PROCESSING');
      getCompanyDas();
    }
  }, [isFocused]);

  useEffect(() => {
    if (filter.year === 'Todos') {
      setDas(allDas);
    } else {
      setDas(
        _filter(
          allDas,
          (o) => o.periodo_desc.split('/')[1] === filter.year
        ).reverse()
      );
    }
  }, [filter]);

  if (status === 'SUCCESS') {
    return (
      <ScrollView
        px={4}
        _contentContainerStyle={{
          flexGrow: 1,
          flexDirection: 'column',
        }}
      >
        {hasPendingDas && (
          <Flex
            zIndex={10}
            w="100%"
            flexDirection="column"
            alignItems="center"
            mb={4}
          >
            <Pressable
              mt={4}
              flexDirection="row"
              alignItems="center"
              alignSelf="flex-start"
              justifyContent="center"
              onPress={handleGoBack}
            >
              {ServicesIcon('left', '24', defaultTextColors.default)}
              <Heading
                ml={2}
                fontSize="2xl"
                alignSelf="flex-start"
                color={defaultTextColors.default}
              >
                DAS Pendentes
              </Heading>
            </Pressable>
            <Box w="100%" mt={2}>
              {loadingPendingDas && (
                <Spinner
                  size={40}
                  mx="auto"
                  color={colors.bgSecondary}
                  accessibilityLabel="Carregando das pendente"
                />
              )}
              {pendingDas && pendingDas.length >= 1 && (
                <FlatList
                  horizontal
                  data={pendingDas}
                  renderItem={({ item, index }) => (
                    <DasItem
                      w={72}
                      pending
                      isPending
                      item={item}
                      index={index}
                      key={item.id}
                    />
                  )}
                  keyExtractor={(item) => item.id.toString()}
                  showsHorizontalScrollIndicator={Platform.OS === 'web'}
                />
              )}
            </Box>
          </Flex>
        )}
        <Flex
          mb={4}
          w="100%"
          zIndex={20}
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Pressable
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            onPress={handleGoBack}
          >
            {!hasPendingDas &&
              ServicesIcon('left', '24', defaultTextColors.default)}
            <Heading ml={2} fontSize="2xl" color={defaultTextColors.default}>
              Boleto DAS
            </Heading>
          </Pressable>
          {das && das.length > 0 && (
            <DasFilterDropdown years={years} callback={setFilter} />
          )}
        </Flex>
        {loadingPendingDas && (
          <Spinner
            size={40}
            mx="auto"
            color={colors.bgSecondary}
            accessibilityLabel="Carregando boletos"
          />
        )}
        {das && das.length >= 1 && das.map((item) => <DasItem item={item} />)}
        {das && das.length === 0 && (
          <Text
            fontSize="lg"
            textAlign="center"
            fontWeight="medium"
            color={defaultTextColors.default}
          >
            Não identificamos nenhuma guia DAS para sua empresa
          </Text>
        )}
      </ScrollView>
    );
  }

  return (
    <Flex flex={1} flexDirection="column" px={4} mb={4}>
      <Center mt={24}>
        <Box p={2} rounded="full" bgColor={colors.bgSecondary}>
          {status === 'ERROR' && <CloseIcon p={2} size="md" color="#FFF" />}
          {status !== 'ERROR' && (
            <CircleSnail color="#FFF" thickness={4} fill={colors.bgSecondary} />
          )}
        </Box>
        <Flex mt={4} w="3/4" mx="auto" flexDirection="column">
          <Text
            fontSize="xl"
            lineHeight="sm"
            fontWeight="bold"
            textAlign="center"
            color={defaultTextColors.default}
          >
            {status !== 'ERROR' && 'Estamos buscando seu extrato do DAS'}
            {status === 'ERROR' &&
              'Desculpe. Houve erro ao buscar as guias DAS da sua MEI. 😞'}
          </Text>
          {status !== 'ERROR' && (
            <Text
              mt={4}
              fontSize="lg"
              lineHeight="sm"
              textAlign="center"
              color="rgba(44, 45, 73, 0.4)"
            >
              Em menos de um minuto você poderá voltar aqui e ver seu histórico
              de boleto DAS.
            </Text>
          )}
        </Flex>
      </Center>
      <Button
        py={3}
        px={5}
        mb={6}
        w="full"
        mt="auto"
        fontSize="md"
        rounded="full"
        onPress={() => goHome()}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="md" fontWeight="semibold" color="#FFF">
          Ir para home
        </Text>
      </Button>
    </Flex>
  );
};

export default DasStatusController;
