import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Input,
  Pressable,
  ScrollView,
  Text,
} from 'native-base';
import { maskBr, validateBr } from 'js-brasil';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import { useRecoilValue } from 'recoil';
import { get } from 'lodash';
import { Platform } from 'react-native';
import Logo from '../../assets/LogoAlt';
import {
  ampLogEvent,
  defaultComponentsColors,
  defaultTextColors,
  validateUserAge,
} from '../../utilites/utilities';
import FormInput from './FormInput';
import ServiceIcon from '../../utilites/ServicesIcon';
import FormPasswordInput from './FormPasswordInput';
import CircleSnail from './Loading';
import { themeAtom } from '../../Recoil/Atoms/theme';
import SimpleRequestHandler, {
  requestErrorHandler,
} from '../../services/meishop';

const RegisterForm = ({ origem,  navigation, logUser }) => {
  const colors = useRecoilValue(themeAtom).layout;
  const paperTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: colors.bgSecondary,
      background: '#f0f2f6',
    },
  };

  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState(undefined);
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [dateError, setDateError] = useState('');
  const [openDate, setOpenDate] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [errorMessage, setErrorMessage] = useState([]);
  const [dataChanged, setDataChanged] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isValidCpf, setIsValidCpf] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const [isValidEmai, setIsValidEmai] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);

  const onDismissSingle = useCallback(() => {
    setOpenDate(false);
  }, [setOpenDate]);

  const onConfirmSingle = useCallback(
    (params) => {
      setDataChanged(true);
      setDate(params.date);
      setOpenDate(false);
    },
    [setOpenDate, setDate]
  );

  const handleRegister = () => {
    const splitedDate = dateOfBirth.split('/');
    if (!validateUserAge(splitedDate[2])) {
      setDateError('Você deve possuir no mínimo 16 anos para continuar.');
      return false;
    }
    if (isValidCpf && isValidName && isValidEmai) {
      setLoading(true);
      setErrorMessage([]);

      const requestData = {
        name,
        email,
        password,
        cpf: cpf.match(/\d/g).join(''),
        password_confirmation: confirmPassword,
        date_of_birth: `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`,
      };

      if (origem) {
        requestData.whitelabel_customer_name = origem.toLowerCase();
      }

      SimpleRequestHandler('register', 'POST', requestData)
        .then(async ({ data }) => {
          await ampLogEvent(null, 'Register - Success', {});
          const userData = get(data, 'data', null);
          if (userData) {
            logUser(userData);
          }
        })
        .catch(async (error) => {
          setErrorMessage(requestErrorHandler(error).split(' <br /> '));
          await ampLogEvent(null, 'Register - Error', {
            error: requestErrorHandler(error).split(' <br /> '),
          });
          setLoading(false);
        });
    }
    return false;
  };

  useEffect(() => {
    if (dataChanged) {
      setDateOfBirth(new Intl.DateTimeFormat('pt-BR').format(date));
    }
    setDateError('');
  }, [date]);

  useEffect(() => {
    const splitedDate = dateOfBirth.split('/');
    if (dateOfBirth !== '') {
      ampLogEvent(null, 'Register - Field Fill', {
        field: 'Data de nascimento',
        valid: validateUserAge(splitedDate[2]),
      });
    }
  }, [dateOfBirth]);

  return (
    <ScrollView
      zIndex={80}
      bgColor="#FFF"
      p={[4, 4, 4, 8]}
      borderRadius="3xl"
      top={[4, 4, 4, 0]}
      left={[4, 4, 4, 0]}
      right={[4, 4, 4, 0]}
      bottom={[4, 4, 4, 0]}
      ml={[0, 0, 0, 32, 64]}
      flexDirection="column"
      mt={Platform.OS === 'ios' ? 10 : 0}
      w={['auto', 'auto', 'auto', '30%']}
      position={['absolute', 'absolute', 'absolute', 'relative']}
      contentContainerStyle={{
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}
    >
      <Logo />
      <Flex
        mt={4}
        flexDirection="column"
        alignItems={['center', 'center', 'center', 'flex-start']}
      >
        <Text
          w="full"
          fontSize="2xl"
          lineHeight="sm"
          fontWeight="bold"
          color={defaultTextColors.default}
          textAlign={['center', 'center', 'center', 'left']}
        >
          Crie sua conta
        </Text>
      </Flex>
      <Flex mt={4} w="full" flexDirection="column">
        <FormInput
          logAmp
          w="full"
          label="Email"
          value={email}
          validate
          setValue={setEmail}
          isValid={isValidEmai}
          setIsValid={setIsValidEmai}
          validation={validateBr.email}
        />
        <FormInput
          logAmp
          w="full"
          label="Nome"
          value={name}
          validate
          setValue={setName}
          isValid={isValidName}
          setIsValid={setIsValidName}
          validation={(text) => text !== ''}
        />
        <FormInput
          logAmp
          w="full"
          label="CPF"
          value={cpf}
          validate
          setValue={setCpf}
          isValid={isValidCpf}
          keyboardType="numeric"
          setIsValid={setIsValidCpf}
          validation={validateBr.cpf}
          onBlur={() => setCpf(maskBr.cpf(cpf))}
        />
        <PaperProvider theme={paperTheme}>
          <DatePickerModal
            date={date}
            mode="single"
            locale="pt-BR"
            visible={openDate}
            saveLabel="Continuar"
            label="Data de nascimento"
            onDismiss={onDismissSingle}
            onConfirm={onConfirmSingle}
            validRange={{
              endDate: new Date(),
            }}
          />
        </PaperProvider>
        <Flex w="full" flexDirection="column" my={2}>
          <Text
            mb={1}
            fontSize="md"
            color="rgba(44, 45, 73, 0.4)"
            fontWeight="semibold"
          >
            Data de nascimento
          </Text>
          <Pressable mt={2} mr={2} onPress={() => setOpenDate(true)}>
            <Input
              mt={-2}
              pl={4}
              size="lg"
              variant="rounded"
              value={dateOfBirth}
              onChange={() => {}}
              borderColor="#d4d4d4"
              placeholder="Data de nascimento"
              onFocus={() => setOpenDate(true)}
              InputRightElement={
                <Box mr={2}>
                  {ServiceIcon('calendar', 24, defaultTextColors.default)}
                </Box>
              }
            />
          </Pressable>
        </Flex>
        {dateError !== '' && (
          <Text mt={2} ml={2} color={defaultTextColors.error}>
            {dateError}
          </Text>
        )}
        <Text fontSize="xs" lineHeight="sm" color="rgba(44, 45, 73, 0.4)">
          Usamos sua data de nascimento para disponibilizar seu certificado MEI.
        </Text>
        <FormPasswordInput
          logAmp
          w="full"
          label="Senha"
          validate
          value={password}
          setValue={setPassword}
          isValid={isValidPassword}
          setIsValid={setIsValidPassword}
          validation={(pass) => pass.length >= 6}
        />
        <Text fontSize="xs" lineHeight="sm" color="rgba(44, 45, 73, 0.4)">
          Sua senha deve possuir no mínimo 6 caracteres.
        </Text>
        <FormPasswordInput
          logAmp
          w="full"
          validate
          value={confirmPassword}
          label="Confirme sua senha"
          comparePassword={password}
          setValue={setConfirmPassword}
          isValid={isValidConfirmPassword}
          setIsValid={setIsValidConfirmPassword}
          validation={(pass) => pass === password}
          customErroMessage="A senha e confirmação de senha não são iguais."
        />
      </Flex>
      <Flex mt={[0, 0, 0, 6]} flexDirection="column" w="full">
        {errorMessage.length >= 1 && (
          <Flex flexDirection="column">
            {errorMessage.map((message) => (
              <Text
                my={1}
                opacity={60}
                fontSize="md"
                lineHeight="sm"
                defaultComponentsColors
                textAlign="center"
                fontWeight="medium"
                color={defaultTextColors.error}
              >
                {message}
              </Text>
            ))}
          </Flex>
        )}
        <Button
          py={3}
          px={5}
          w="full"
          fontSize="md"
          rounded="full"
          onPress={handleRegister}
          bgColor={defaultComponentsColors.warning}
          mt={errorMessage.length >= 1 ? 4 : [4, 4, 4, 0]}
        >
          {loading ? (
            <CircleSnail
              size={25}
              color="#FFF"
              thickness={2}
              fill="rgba(0, 0, 0, 0)"
            />
          ) : (
            <Text fontSize="md" fontWeight="medium" color="#FFF">
              Cadastrar
            </Text>
          )}
        </Button>
        <Text
          px={2}
          w="full"
          fontSize="md"
          textAlign="center"
          mt={[4, 4, 4, 8]}
          color="rgba(44, 45, 73, 0.4)"
        >
          Já possui conta?{' '}
          <Text
            fontSize="md"
            color="#007fc6"
            onPress={() => navigation.navigate('Login')}
          >
            Entrar
          </Text>
        </Text>
      </Flex>
    </ScrollView>
  );
};

export default RegisterForm;
