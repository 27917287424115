import React from 'react';
import { Box, ScrollView } from 'native-base';

const ContentContainer = (props) => {
  const { children, scrollViewProps } = props;
  return (
    <Box pt={4} px={2} pb={20} w="auto" zIndex={10} elevation={1} {...props}>
      <ScrollView
        mb={-80}
        contentContainerStyle={{ flexGrow: 1 }}
        {...scrollViewProps}
      >
        {children}
      </ScrollView>
    </Box>
  );
};

export default ContentContainer;
