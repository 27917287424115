import React from 'react';
import { useRecoilState } from 'recoil';
import { Dimensions } from 'react-native';
import { Button, Flex, Pressable, Text } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { modalAtom } from '../../Recoil/Atoms/modal';
import ServiceIcon from '../../utilites/ServicesIcon';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';

const Modal = () => {
  const navigation = useNavigation();
  const [modal, setModal] = useRecoilState(modalAtom);

  const handleClose = () => {
    setModal({
      ...modal,
      show: false,
    });
  };

  const handleUpdateProfile = () => {
    handleClose();
    navigation.navigate('profile');
  };
  return (
    <Flex
      h="100%"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={100}
      w={Dimensions.width}
      position="absolute"
      alignItems="center"
      justifyContent="center"
      bgColor="rgba(0, 0, 0, 0.4)"
    >
      <Flex
        p={4}
        bgColor="#FFF"
        roundedTop={20}
        flexDirection="column"
        roundedBottom={[0, 0, 0, 20]}
        mt={['auto', 'auto', 'auto', 0]}
        w={['full', 'full', 'full', '2/4']}
      >
        <Flex
          py={2}
          pb={4}
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text
            fontSize="lg"
            fontWeight="semibold"
            color={defaultTextColors.secondary}
          >
            {modal.title}
          </Text>
          <Pressable ml={2} onPress={handleClose} opacity={40}>
            {ServiceIcon('x', 24, defaultTextColors.secondary)}
          </Pressable>
        </Flex>
        <Text mt={2} mb={6} fontSize="sm" color={defaultTextColors.secondary}>
          {modal.message}
        </Text>
        <Button
          mt={4}
          py={3}
          w="full"
          mx="auto"
          rounded="full"
          onPress={handleUpdateProfile}
          bgColor={defaultComponentsColors.warning}
        >
          <Text color="#FFF" fontWeight="semibold">
            Atualizar dados cadastrais
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};

export default Modal;
