import React from 'react';
import { Flex, Box, Text, Pressable } from 'native-base';
import { useRecoilState, useRecoilValue } from 'recoil';
import { map, get } from 'lodash';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import { NotificationsIcons } from '../../utilites/ServicesIcon';
import { defaultTextColors } from '../../utilites/utilities';
import SimpleRequestHandler from '../../services/meishop';
import { notificationsAtom } from '../../Recoil/Atoms/notifications';
import { userAtom } from '../../Recoil/Atoms/user';

const NotificationItem = ({ item, colors, setReloadingTheme, logUser }) => {
  const navigation = useNavigation();
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const user = useRecoilValue(userAtom);
  const userToken = useSelector((state) => state.token.plainTextToken);

  const notificationActions = {
    USER_ONBOARDING_FAILED: () => {
      navigation.navigate('company_cnpj');
    },
    USER_SUBSCRIPTION_IDENTIFIED: () => {
      setReloadingTheme(true);
      SimpleRequestHandler(`users/${user.id}`, 'GET')
        .then(({ data }) => {
          const userData = get(data, 'data', null);
          if (userData) {
            logUser({
              ...user,
              ...userData,
              token: userToken,
            });
            setReloadingTheme(false);
          }
        })
        .catch(() => {
          setReloadingTheme(false);
        });
    },
    COMPANY_AUTOMATICALLY_ADDED: ({ companyId = 0 }) => {
      navigation.navigate('company_cnpj', {
        id: companyId,
      });
    },
  };

  const handleNotificationAction = () => {
    const type = get(item, 'data.type', 'NO_TYPE');
    notificationActions[type] && notificationActions[type](item);
  };

  const handlePress = () => {
    SimpleRequestHandler(`notifications/${item.id}/read`, 'PUT');

    const updatedNotifications = map(notifications.notifications, (o) => {
      if (o.id === item.id) {
        return {
          ...o,
          read_at: 'Há poucos minutos.',
        };
      }
      return o;
    });

    setNotifications({
      ...notifications,
      show: false,
      counter: notifications.counter - 1,
      notifications: updatedNotifications,
    });

    handleNotificationAction();
  };

  return (
    <Pressable
      p={3}
      mb={4}
      mx={0.5}
      shadow={2}
      bgColor="#FFF"
      rounded="3xl"
      flexDirection="row"
      alignItems="flex-start"
      opacity={item.read_at !== null ? 60 : 100}
      onPress={item.read_at === null ? handlePress : () => {}}
    >
      <Box mr={2} p={2} bg="rgba(70, 54, 129, 0.05)" rounded="lg">
        {NotificationsIcons(item.data.type, 18, colors.bgSecondary)}
      </Box>
      <Flex flexDirection="column" w="full" pr={16}>
        <Text fontSize="sm" fontWeight="bold" color={defaultTextColors.default}>
          {item.data.title}
        </Text>
        <Text fontSize="xs" color={defaultTextColors.default} opacity={80}>
          {item.data.body}
        </Text>
        <Text
          fontSize="2xs"
          mt={2}
          color={defaultTextColors.default}
          opacity={60}
        >
          {item.created_at}
        </Text>
      </Flex>
    </Pressable>
  );
};

export default NotificationItem;
