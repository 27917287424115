import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  image: '',
  customer: '-',
  layout: {
    theme: 'default',
    bgPrimary: '#60519a',
    bgSecondary: '#463681',
    primary: '#2c2d49',
    secondary: '#f5b5b5',
  },
};

export const { Types, Creators } = createActions({
  resetTheme: [''],
  removeTheme: [''],
  insertTheme: ['data'],
});

const insert = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.data,
});

const remove = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const reset = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [Types.RESET_THEME]: reset,
  [Types.INSERT_THEME]: insert,
  [Types.REMOVE_THEME]: remove,
});
