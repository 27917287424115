import { atom } from 'recoil';

export const themeAtom = atom({
  key: 'themeState',
  default: {
    image: '',
    customer: '-',
    layout: {
      theme: 'default',
      bgPrimary: '#60519a',
      bgSecondary: '#463681',
      primary: '#2c2d49',
      secondary: '#f5b5b5',
    },
  },
});
