import React, { useEffect, useState } from 'react';
import { Button, Flex, ScrollView, Switch, Text } from 'native-base';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { find, get } from 'lodash';
import { maskBr, validateBr } from 'js-brasil';
import {
  defaultComponentsColors,
  defaultTextColors,
  ufs,
} from '../../../utilites/utilities';
import FormInput from '../../../components/Global/FormInput';
import { showToastMessage } from '../../../utilites/ToastProvider';
import { formalizationAtom } from '../../../Recoil/Atoms/formalization';
import FormSelect from '../../../components/Global/FormSelect';
import { requestErrorHandler } from '../../../services/meishop';

const Address = ({ handleSendStep, handleNextState }) => {
  const { address } = useRecoilValue(formalizationAtom);

  const [uf, setUf] = useState({});
  const [cep, setCep] = useState(address.cep);
  const [number, setNumber] = useState(address.numero);
  const [district, setDistrict] = useState(address.bairro);
  const [street, setStreet] = useState(address.logradouro);
  const [city, setCity] = useState(address.municipio_nome);
  const [businessUf, setBusinessUf] = useState({});
  const [requestError, setRequestError] = useState('');
  const [complement, setComplement] = useState(address.complemento);
  const [businessCep, setBusinessCep] = useState(address.comercial_cep);
  const [businessNumber, setBusinessNumber] = useState(
    address.comercial_numero
  );
  const [businessDistrict, setBusinessDistrict] = useState(
    address.comercial_bairro
  );
  const [businessStreet, setBusinessStreet] = useState(
    address.comercial_logradouro
  );
  const [businessCity, setBusinessCity] = useState(
    address.comercial_municipio_nome
  );
  const [businessComplement, setBusinessComplement] = useState(
    address.comercial_complemento
  );
  const [sameAddress, setSameAddress] = useState(
    !address.possui_endereco_comercial
  );

  const handleCepSearch = () => {
    axios
      .get(`https://brasilapi.com.br/api/cep/v2/${maskBr.cep(cep)}`)
      .then(({ data }) => {
        const responseState = get(data, 'state', null);
        if (responseState) {
          setUf(find(ufs(), ['sigla', responseState]));
        }
        setCity(get(data, 'city', ''));
        setDistrict(get(data, 'neighborhood', ''));
        setStreet(get(data, 'street', ''));
      })
      .catch(() =>
        showToastMessage(
          'CEP inválido',
          'Não encontramos o CEP digitado, verifique-o por favor.',
          'warn'
        )
      );
    setCep(maskBr.cep(cep));
  };

  const handleBusinessCepSearch = () => {
    axios
      .get(`https://brasilapi.com.br/api/cep/v2/${maskBr.cep(businessCep)}`)
      .then(({ data }) => {
        const responseState = get(data, 'state', null);
        if (responseState) {
          setBusinessUf(find(ufs(), ['sigla', responseState]));
        }
        setBusinessCity(get(data, 'city', ''));
        setBusinessDistrict(get(data, 'neighborhood', ''));
        setBusinessStreet(get(data, 'street', ''));
      })
      .catch(() =>
        showToastMessage(
          'CEP inválido',
          'Não encontramos o CEP digitado, verifique-o por favor.',
          'warn'
        )
      );
    setBusinessCep(maskBr.cep(businessCep));
  };

  const handleCepChange = (value) => {
    setCep(value);
  };

  const handleBusinessCepChange = (value) => {
    setBusinessCep(value);
  };

  const valid = () => {
    if (!cep || !validateBr.cep(cep)) {
      showToastMessage('CEP inválido', 'O CEP digitado não é válido.', 'warn');
      return false;
    }
    if (street === '') {
      showToastMessage(
        'Rua inválida',
        'Digite sua rua para continuar.',
        'warn'
      );
      return false;
    }
    if (!number || number === '') {
      showToastMessage(
        'Número inválido',
        'Digite o número do seu endereço para continuar.',
        'warn'
      );
      return false;
    }
    if (district === '') {
      showToastMessage(
        'Bairro inválido',
        'Digite seu bairro para continuar.',
        'warn'
      );
      return false;
    }
    if (get(uf, 'id', null) === null) {
      showToastMessage(
        'Estado inválido',
        'Selecione seu estado para continuar.',
        'warn'
      );
      return false;
    }
    if (city === '') {
      showToastMessage(
        'Cidade inválida',
        'Digite sua cidade para continuar.',
        'warn'
      );
      return false;
    }
    return true;
  };

  const validBusiness = () => {
    if (!validateBr.cep(businessCep)) {
      showToastMessage(
        'CEP comercial inválido',
        'O CEP comercial digitado não é válido.',
        'warn'
      );
      return false;
    }
    if (businessStreet === '') {
      showToastMessage(
        'Rua comercial inválida',
        'Digite sua rua comercial para continuar.',
        'warn'
      );
      return false;
    }
    if (businessNumber === '') {
      showToastMessage(
        'Número comercial inválido',
        'Digite o número do seu endereço comercial para continuar.',
        'warn'
      );
      return false;
    }
    if (businessDistrict === '') {
      showToastMessage(
        'Bairro comercial inválido',
        'Digite seu bairro comercial para continuar.',
        'warn'
      );
      return false;
    }
    if (get(businessUf, 'id', null) === null) {
      showToastMessage(
        'Estado comercial inválido',
        'Selecione seu estado comercial para continuar.',
        'warn'
      );
      return false;
    }
    if (businessCity === '') {
      showToastMessage(
        'Cidade comercial inválida',
        'Digite sua cidade comercial para continuar.',
        'warn'
      );
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const requestData = {};
    const regex = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;
    if (valid()) {
      requestData.numero = number;
      requestData.bairro = district;
      requestData.logradouro = street;
      requestData.municipio_nome = city;
      requestData.cep = cep.replace(regex, '$1$2-$3');
      requestData.uf_sigla = get(uf, 'sigla', '');
      if (complement !== '') {
        requestData.complemento = complement;
      }
      requestData.possui_endereco_comercial = !sameAddress;
      if (!sameAddress && validBusiness()) {
        requestData.comercial_numero = businessNumber;
        requestData.comercial_bairro = businessDistrict;
        requestData.comercial_logradouro = businessStreet;
        requestData.comercial_municipio_nome = businessCity;
        requestData.comercial_cep = businessCep.replace(regex, '$1$2-$3');
        requestData.comercial_uf_sigla = get(businessUf, 'sigla', '');
        if (businessComplement !== '') {
          requestData.comercial_complemento = businessComplement;
        }
      }
      handleSendStep(requestData)
        .then(() => {
          handleNextState();
        })
        .catch((error) => {
          setRequestError(requestErrorHandler(error).replace(' <br />', ''));
        });
    }
  };

  useEffect(() => {
    const cachedUf = find(ufs(), ['sigla', get(address, 'uf_sigla', null)]);
    const cachedBusinessUf = find(ufs(), [
      'sigla',
      get(address, 'comercial_uf_sigla', null),
    ]);
    if (cachedUf) {
      setUf(cachedUf);
    }
    if (cachedBusinessUf) {
      setBusinessUf(cachedBusinessUf);
    }
  }, []);

  return (
    <ScrollView
      px={[4, 8, 16, 20]}
      _contentContainerStyle={{
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      <Text
        fontSize="xl"
        textAlign="center"
        fontWeight="semibold"
        color={defaultTextColors.default}
      >
        Preencha com o endereço que quer utilizar no MEI
      </Text>
      <FormInput
        label="CEP"
        value={cep}
        keyboardType="numeric"
        onBlur={handleCepSearch}
        setValue={handleCepChange}
      />
      <Flex flexDirection="row">
        <FormInput
          pr={2}
          w="2/3"
          label="Rua"
          value={street}
          setValue={setStreet}
        />
        <FormInput
          w="1/3"
          label="Número"
          value={number}
          setValue={setNumber}
          keyboardType="numeric"
        />
      </Flex>
      <FormInput
        label="Complemento"
        value={complement}
        setValue={setComplement}
      />
      <Flex flexDirection="row">
        <FormInput
          pr={2}
          w="1/2"
          label="Bairro"
          value={district}
          setValue={setDistrict}
        />
        <FormInput w="1/2" value={city} label="Cidade" setValue={setCity} />
      </Flex>
      <FormSelect
        value={uf}
        label="Estado"
        options={ufs()}
        loading={false}
        setValue={setUf}
        loadingPlaceholder=""
      />
      <Flex mt={4} flexDirection="column">
        <Text fontSize="md" color={defaultTextColors.default}>
          Seu endereço comercial é o mesmo que o pessoal?
        </Text>
        <Flex mt={4} flexDirection="row">
          <Text
            mr={2}
            fontSize="md"
            textAlign="center"
            color={defaultTextColors.default}
          >
            Não
          </Text>
          <Switch
            onTrackColor={defaultTextColors.disabled}
            offTrackColor={defaultTextColors.disabled}
            offThumbColor={defaultTextColors.secondary}
            onThumbColor={defaultComponentsColors.success}
            isChecked={sameAddress}
            onToggle={() => setSameAddress(!sameAddress)}
          />
          <Text
            ml={2}
            fontSize="md"
            textAlign="center"
            color={defaultTextColors.default}
          >
            Sim
          </Text>
        </Flex>
      </Flex>
      {!sameAddress && (
        <>
          <FormInput
            label="CEP"
            value={businessCep}
            keyboardType="numeric"
            onBlur={handleBusinessCepSearch}
            setValue={handleBusinessCepChange}
          />
          <Flex flexDirection="row">
            <FormInput
              pr={2}
              w="2/3"
              label="Rua"
              value={businessStreet}
              setValue={setBusinessStreet}
            />
            <FormInput
              w="1/3"
              label="Número"
              keyboardType="numeric"
              value={businessNumber}
              setValue={setBusinessNumber}
            />
          </Flex>
          <FormInput
            label="Complemento"
            value={businessComplement}
            setValue={setBusinessComplement}
          />
          <Flex flexDirection="row">
            <FormInput
              pr={2}
              w="1/2"
              label="Bairro"
              value={businessDistrict}
              setValue={setBusinessDistrict}
            />
            <FormInput
              w="1/2"
              label="Cidade"
              value={businessCity}
              setValue={setBusinessCity}
            />
          </Flex>
          <FormSelect
            label="Estado"
            options={ufs()}
            loading={false}
            value={businessUf}
            loadingPlaceholder=""
            setValue={setBusinessUf}
          />
        </>
      )}
      {requestError !== '' && (
        <Text
          mt={6}
          fontSize="sm"
          textAlign="center"
          color={defaultTextColors.error}
        >
          {requestError}
        </Text>
      )}
      <Button
        py={3}
        px={5}
        mt={2}
        mb={4}
        mx="auto"
        fontSize="md"
        rounded="full"
        onPress={handleSubmit}
        w={['full', 'full', 'full', '1/3']}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="md" fontWeight="medium" color="#FFF">
          Continuar
        </Text>
      </Button>
    </ScrollView>
  );
};

export default Address;
