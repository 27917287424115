import React, { useEffect, useState } from 'react';
import { DeviceEventEmitter, Platform } from 'react-native';
import { Button, Pressable, Radio, ScrollView, Text } from 'native-base';
import { get } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { maskBr } from 'js-brasil';
import FormInput from '../../components/Global/FormInput';
import ContentContainer from '../../components/Global/ContentContainer';
import FormCurrencyInput from '../../components/Global/FormCurrencyInput';
import {
  convertFloatToCents,
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';
import { themeAtom } from '../../Recoil/Atoms/theme';
import ServicesIcon from '../../utilites/ServicesIcon';
import SimpleRequestHandler from '../../services/meishop';
import { modalDasnAtom } from '../../Recoil/Atoms/modalDasn';
import { showToastMessage } from '../../utilites/ToastProvider';
import { selectedCompanyAtom } from '../../Recoil/Atoms/selectedCompany';

const DasnDeclaration = ({ navigation, route }) => {
  const dasn = get(route, 'params', null);

  const theme = useRecoilValue(themeAtom);
  const colors = theme.layout;

  const selectedCompany = useRecoilValue(selectedCompanyAtom);
  const [dasnModal, setDasnModal] = useRecoilState(modalDasnAtom);

  const [revenue, setRevenue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState('no');
  const [productRevenues, setProductRevenues] = useState(0);
  const [servicesRevenues, setServicesRevenues] = useState(0);
  const [productIsValid, setProductIsValid] = useState(false);
  const [servicesIsValid, setServicesIsValid] = useState(false);

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Dashboard');
    }
  };

  const validateValue = (value) => value !== null;

  const handleSubmit = () => {
    if (!loading) {
      const requestUrl = `company/${selectedCompany.id}/dasns/${dasn.company_dasn_id}/declaration`;
      if (validateValue(productRevenues) && validateValue(servicesRevenues)) {
        setLoading(true);
        SimpleRequestHandler(requestUrl, 'POST', {
          teve_funcionarios: employees === 'yes',
          faturamento_servico: convertFloatToCents(servicesRevenues),
          faturamento_comercio: convertFloatToCents(productRevenues),
        })
          .then(() => {
            showToastMessage(
              'Declaração enviada',
              'Sua declaração foi enviada e está em processamento.',
              'success'
            );
            setLoading(false);
            DeviceEventEmitter.emit('listen_dasn_reload', {
              companyId: selectedCompany.id,
            });
            navigation.navigate('declaracao_dasn');
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (dasnModal.useValue) {
      setServicesRevenues(dasnModal.servicesValue);
      setDasnModal({
        ...dasnModal,
        useValue: false,
      });
    }
  }, [dasnModal]);

  useEffect(() => {
    setRevenue(productRevenues + servicesRevenues);
  }, [productRevenues, servicesRevenues]);

  return (
    <ScrollView
      flex={1}
      px={[4, 4, 16, 20]}
      _contentContainerStyle={{ flexGrow: 1, paddingBottom: 10 }}
    >
      <Pressable
        mt={4}
        flexDirection="row"
        alignItems="center"
        alignSelf="flex-start"
        justifyContent="flex-start"
        onPress={handleGoBack}
      >
        {ServicesIcon('left', '24', defaultTextColors.default)}
        <Text
          ml={2}
          lineHeight="md"
          fontWeight="bold"
          color={defaultTextColors.default}
          fontSize={['lg', 'lg', 'lg', 'xl']}
        >
          {`Declaração DASN ${dasn.ano}`}
        </Text>
      </Pressable>
      <Text
        my={4}
        opacity={60}
        lineHeight="md"
        textAlign="center"
        fontWeight="semibold"
        color={defaultTextColors.default}
        fontSize={['md', 'md', 'md', 'lg']}
      >
        Preencha os dados para realizar sua declaração anual
      </Text>
      <ContentContainer
        pb={0}
        px={4}
        h="auto"
        rounded="2xl"
        bgColor="#FFF"
        flexWrap="nowrap"
        flexDirection="column"
        scrollViewProps={{
          mb: 0,
        }}
        flex={Platform.OS === 'web' ? '' : 1}
        mb={Platform.OS === 'web' ? 0 : [64, 64, 0]}
      >
        <Text
          fontSize="md"
          fontWeight="medium"
          colors={defaultTextColors.default}
        >
          CNPJ
        </Text>
        <FormInput
          label=""
          disabled
          setValue={() => {}}
          value={maskBr.cnpj(selectedCompany.cnpj.cnpj)}
        />
        <Text
          mt={2}
          fontSize="md"
          fontWeight="medium"
          colors={defaultTextColors.default}
        >
          Ano da declaração
        </Text>
        <FormInput label="" disabled value={dasn.ano} setValue={() => {}} />
        <Text
          mt={2}
          mb="auto"
          fontSize="md"
          fontWeight="medium"
          colors={defaultTextColors.default}
        >
          {`Seu faturamento em ${dasn.ano} vendendo `}
          <Text
            fontSize="md"
            fontWeight="bold"
            colors={defaultTextColors.default}
          >
            produtos
          </Text>
        </Text>
        <FormCurrencyInput
          label=""
          validate
          value={productRevenues}
          isValid={productIsValid}
          setValue={setProductRevenues}
          setIsValid={setProductIsValid}
          validation={validateValue}
          customErroMessage="Você precisa inserir um valor para continuar, mesmo que seja de R$ 0,00."
        />
        <Text
          mt={2}
          mb={-2}
          fontSize="md"
          fontWeight="medium"
          colors={defaultTextColors.default}
        >
          {`Seu faturamento em ${dasn.ano} vendendo `}
          <Text
            fontSize="md"
            fontWeight="bold"
            colors={defaultTextColors.default}
          >
            serviços
          </Text>
        </Text>
        {theme.id > 1 && (
          <Pressable
            mt={2}
            onPress={() =>
              setDasnModal({
                ...dasnModal,
                show: true,
                year: dasn.ano,
              })
            }
          >
            <Text
              underline
              fontSize="sm"
              fontWeight="semibold"
              color={colors.bgPrimary}
            >
              Calcular meu faturamento
            </Text>
          </Pressable>
        )}
        <FormCurrencyInput
          label=""
          validate
          value={servicesRevenues}
          isValid={servicesIsValid}
          validation={validateValue}
          setValue={setServicesRevenues}
          setIsValid={setServicesIsValid}
          customErroMessage="Você precisa inserir um valor para continuar, mesmo que seja de R$ 0,00."
        />
        <Text
          mt={2}
          mb={-2}
          fontSize="md"
          fontWeight="medium"
          colors={defaultTextColors.default}
        >
          {`Receita bruta total em ${dasn.ano}`}
        </Text>
        <FormCurrencyInput
          label=""
          disabled
          value={revenue}
          setValue={() => {}}
        />
        <Text mt={2} fontSize="md" color={defaultTextColors.default}>
          Teve funcionário em 2021?
        </Text>
        <Radio.Group
          mt={2}
          value={employees}
          name="hasEmployees"
          onChange={(nextValue) => {
            setEmployees(nextValue);
          }}
          accessibilityLabel="Teve funcionário em 2021?"
        >
          <Radio value="no" my={1} bg="#FFF">
            <Text
              ml={2}
              opacity={60}
              fontSize="md"
              fontWeight="medium"
              color={defaultTextColors.default}
            >
              Não
            </Text>
          </Radio>
          <Radio my={1} bg="#FFF" value="yes">
            <Text
              ml={2}
              opacity={60}
              fontSize="md"
              fontWeight="medium"
              color={defaultTextColors.default}
            >
              Sim
            </Text>
          </Radio>
        </Radio.Group>
        <Button
          py={3}
          mb={4}
          px={5}
          mt={6}
          mx="auto"
          fontSize="md"
          rounded="full"
          isLoading={loading}
          onPress={handleSubmit}
          w={['full', 'full', 'full', '1/3']}
          bgColor={defaultComponentsColors.warning}
        >
          <Text fontSize="md" fontWeight="medium" color="#FFF">
            {loading ? 'Enviando declaração' : 'Submeter declaração'}
          </Text>
        </Button>
      </ContentContainer>
    </ScrollView>
  );
};

export default DasnDeclaration;
