import { Linking, Platform } from 'react-native';
import * as SecureStore from 'expo-secure-store';
import amplitude from 'amplitude-js';
import * as Amplitude from 'expo-analytics-amplitude';
import store from '../redux/Store';
import { Creators as TokenActions } from '../redux/Store/Ducks/token';
// eslint-disable-next-line import/no-cycle
import { showToastMessage } from './ToastProvider';

const getDeepLink = (path = '') => {
  const scheme = 'meu-negocio';
  const prefix =
    Platform.OS === 'android' ? `${scheme}://dicasmei/` : `${scheme}://`;
  return prefix + path;
};

export const defaultComponentsColors = {
  error: '#fa3e60',
  success: '#00a8b3',
  warning: '#ecaa24',
  default: '#ee754c',
  disabled: '#ecf1f7',
  transparent: 'rgba(0, 0, 0, 0)',
};

export const defaultTextColors = {
  error: '#fa3e60',
  success: '#00a8b3',
  warning: '#ecaa24',
  default: '#3c4858',
  secondary: '#2c2d49',
  disabled: '#cccccc',
};

export const handleOpenLink = async (url) => {
  if (Platform.OS === 'web') {
    if (window && window.open) {
      window.open(url, '_blank');
    } else {
      showToastMessage(
        'Erro',
        'Ocorreu um erro ao abrir, por favor, tente novamente.',
        'error'
      );
    }
  } else {
    Linking.canOpenURL(url)
      .then(() => {
        Linking.openURL(url);
      })
      .catch(() => {
        showToastMessage(
          'Erro',
          'Ocorreu um erro ao abrir, por favor, tente novamente.',
          'error'
        );
      });
  }
};

export const storeData = async (key, data, isJson = false) => {
  let storeValue = null;
  if (isJson) {
    storeValue = JSON.stringify(data);
  } else {
    storeValue = data;
  }
  if (Platform.OS === 'web' && window && window.localStorage) {
    window.localStorage.setItem(key, storeValue);
  } else {
    await SecureStore.setItemAsync(key, storeValue);
  }
};

export const getStoredData = async (key, isJson = false) => {
  let data = null;
  if (Platform.OS === 'web' && window && window.localStorage) {
    data = window.localStorage.getItem(key);
  } else {
    data = await SecureStore.getItemAsync(key);
  }

  if (data && isJson) {
    return JSON.parse(data);
  }
  return data;
};

export const destroyStoredData = async (key) => {
  if (Platform.OS === 'web' && window && window.localStorage) {
    window.localStorage.removeItem(key);
  } else {
    await SecureStore.deleteItemAsync(key);
  }
};

export const handleLogout = async () => {
  const userKey = 'com.meishop.meunegocio.user';
  const themeKey = 'com.meishop.meunegocio.theme';
  const tokenKey = 'com.meishop.meunegocio.token';

  store.dispatch(TokenActions.resetToken());
  if (Platform.OS === 'web' && window && window.localStorage) {
    window.localStorage.clear();
  } else {
    await SecureStore.deleteItemAsync(userKey);
    await SecureStore.deleteItemAsync(tokenKey);
    await SecureStore.deleteItemAsync(themeKey);
  }
};

export const initializeAmplitude = async (KEY, storeTokens) => {
  if (Platform.OS === 'web') {
    amplitude.getInstance().init(KEY, null, {
      saveEvents: true,
      includeUtm: true,
      includeReferrer: true,
      domain: '.meishop.com.br',
    });
    storeTokens(
      amplitude.getInstance().options.deviceId,
      // eslint-disable-next-line no-underscore-dangle
      amplitude.getInstance()._sessionId
    );
  } else {
    await Amplitude.initializeAsync(KEY);
  }
};

export const ampLogEvent = async (
  userId = null,
  eventName = '',
  eventProperties = {}
) => {
  if (Platform.OS === 'web') {
    if (userId) {
      amplitude.getInstance().setUserId(userId);
    }
    amplitude.getInstance().logEvent(eventName, eventProperties);
  } else if (userId) {
    Amplitude.setUserIdAsync(userId.toString()).then(async () => {
      await Amplitude.logEventWithPropertiesAsync(eventName, eventProperties);
    });
  } else {
    await Amplitude.logEventWithPropertiesAsync(eventName, eventProperties);
  }
};

export const validateUserAge = (userYear) => {
  const currentYear = new Intl.DateTimeFormat('pt-BR')
    .format(new Date())
    .split('/')[2];
  return currentYear - userYear >= 16;
};

export const validateEmail = (email) =>
  email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

export const convertFloatToCents = (value) =>
  parseInt(value.toFixed(2).toString().replace('.', ''));

export const ufs = () => [
  {
    id: '1',
    sigla: 'AC',
    item: 'Acre',
  },
  {
    id: '2',
    sigla: 'AL',
    item: 'Alagoas',
  },
  {
    id: '3',
    sigla: 'AM',
    item: 'Amazonas',
  },
  {
    id: '4',
    sigla: 'AP',
    item: 'Amapá',
  },
  {
    id: '5',
    sigla: 'BA',
    item: 'Bahia',
  },
  {
    id: '6',
    sigla: 'CE',
    item: 'Ceará',
  },
  {
    id: '7',
    sigla: 'DF',
    item: 'Distrito Federal',
  },
  {
    id: '8',
    sigla: 'ES',
    item: 'Espírito Santo',
  },
  {
    id: '9',
    sigla: 'GO',
    item: 'Goiás',
  },
  {
    id: '10',
    sigla: 'MA',
    item: 'Maranhão',
  },
  {
    id: '11',
    sigla: 'MG',
    item: 'Minas Gerais',
  },
  {
    id: '12',
    sigla: 'MS',
    item: 'Mato Grosso do Sul',
  },
  {
    id: '13',
    sigla: 'MT',
    item: 'Mato Grosso',
  },
  {
    id: '14',
    sigla: 'PA',
    item: 'Pará',
  },
  {
    id: '15',
    sigla: 'PB',
    item: 'Paraíba',
  },
  {
    id: '16',
    sigla: 'PE',
    item: 'Pernambuco',
  },
  {
    id: '17',
    sigla: 'PI',
    item: 'Piauí',
  },
  {
    id: '18',
    sigla: 'PR',
    item: 'Paraná',
  },
  {
    id: '19',
    sigla: 'RJ',
    item: 'Rio de Janeiro',
  },
  {
    id: '20',
    sigla: 'RN',
    item: 'Rio Grande do Norte',
  },
  {
    id: '21',
    sigla: 'RO',
    item: 'Rondônia',
  },
  {
    id: '22',
    sigla: 'RR',
    item: 'Roraima',
  },
  {
    id: '23',
    sigla: 'RS',
    item: 'Rio Grande do Sul',
  },
  {
    id: '24',
    sigla: 'SC',
    item: 'Santa Catarina',
  },
  {
    id: '25',
    sigla: 'SE',
    item: 'Sergipe',
  },
  {
    id: '26',
    sigla: 'SP',
    item: 'São Paulo',
  },
  {
    id: '27',
    sigla: 'TO',
    item: 'Tocantins',
  },
];

export default getDeepLink;
