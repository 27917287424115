import React, { useState } from 'react';
import { Flex, Input, Text } from 'native-base';
import {
  ampLogEvent,
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';

const FormInput = ({
  value,
  label,
  isValid,
  setValue,
  pr = 0,
  w = 'full',
  onBlur = () => {},
  onFocus = () => {},
  setIsValid = () => {},
  logAmp = false,
  disabled = false,
  validate = false,
  validation = () => false,
  customErroMessage = null,
  keyboardType = 'default',
}) => {
  const [startValidation, setStartValidation] = useState(false);

  const validationColors = {
    true: defaultTextColors.success,
    false: defaultTextColors.error,
  };

  const doValidation = (text) => {
    setStartValidation(true);
    if (validate) {
      setIsValid(validation(text));
    } else {
      setIsValid(true);
    }
  };

  const handleInputChange = (text) => {
    setValue(text);
    if (startValidation) {
      doValidation(text);
    }
  };

  const logAmplitudeEvent = async () => {
    await ampLogEvent(null, 'Register - Field Fill', {
      field: label,
      valid: validation(value),
    });
  };

  return (
    <Flex w={w} flexDirection="column" my={2} pr={pr}>
      <Text fontSize="md" color="rgba(44, 45, 73, 0.4)" fontWeight="semibold">
        {label}
      </Text>
      <Input
        mt={1}
        fontSize="md"
        value={value}
        rounded="full"
        autoFocus={false}
        onFocus={onFocus}
        isDisabled={disabled}
        autoCapitalize="none"
        keyboardType={keyboardType}
        bgColor={disabled ? defaultComponentsColors.disabled : '#FFF'}
        onBlur={async (params) => {
          onBlur(params);
          doValidation(value);
          if (logAmp) {
            await logAmplitudeEvent();
          }
        }}
        fontWeight="semibold"
        onChangeText={handleInputChange}
        borderColor={startValidation ? validationColors[isValid] : '#d4d4d4'}
        color={
          disabled ? defaultTextColors.disabled : defaultTextColors.secondary
        }
      />
      {validate && startValidation && !isValid && (
        <Text mt={2} ml={2} color={defaultTextColors.error}>
          {customErroMessage || `${label} inválido.`}
        </Text>
      )}
    </Flex>
  );
};

export default FormInput;
