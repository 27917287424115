import { atom } from 'recoil';

export const formalizationAtom = atom({
  key: 'formalizationState',
  default: {
    id: 0,
    status: '',
    initial: {
      termos_de_uso: false,
      termos_de_acesso: false,
    },
    credentials: {
      nome: '',
      data_nascimento: '',
      nome_mae: '',
      nome_pai: '',
      senha: '',
      requer_criacao: false,
      email: '',
      numero_telefone: '',
    },
    'company-data': {
      capital: '',
      nome_empresarial: '',
      atividade_principal_mei: '',
      atividade_secundaria_mei: '',
      formas_atuacao_mei: '',
    },
    'personal-data': {
      cpf_numero: '',
      rg_numero: '',
      rg_uf: '',
      rg_emissor: '',
      titulo_de_eleitor: '',
    },
    address: {
      cep: '',
      bairro: '',
      complemento: '',
      logradouro: '',
      numero: '',
      uf_sigla: '',
      municipio_nome: '',
      possui_endereco_comercial: false,
      comercial_cep: '',
      comercial_bairro: '',
      comercial_complemento: '',
      comercial_logradouro: '',
      comercial_numero: '',
      comercial_uf_sigla: '',
      comercial_municipio_nome: '',
    },
    confirmation: null,
    availableActivities: [],
    availableMethods: [],
  },
});
