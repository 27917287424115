import React from 'react';
import { Flex, Image } from 'native-base';
import Background from '../../assets/Login/login1.jpg';
import LoginForm from '../../components/Global/LoginForm';

const Login = ({ navigation, logUser }) => (
  <Flex
    h="100%"
    w="100%"
    flex={1}
    py={[0, 0, 0, 10]}
    position="absolute"
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    backgroundColor={{
      linearGradient: {
        colors: ['#463681', '#60519a'],
        start: [0, 0],
        end: [0, 1],
      },
    }}
  >
    <Image
      left={0}
      source={Background}
      position="absolute"
      alt="login background"
      width={[0, 0, 0, '55%']}
      defaultSource={Background}
      height={[0, 0, 0, '100%']}
    />
    <LoginForm logUser={logUser} navigation={navigation} />
  </Flex>
);

export default Login;
