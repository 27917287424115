import React, { useState } from 'react';
import { Flex, Text, Button, Image } from 'native-base';
import { validateBr } from 'js-brasil';
import { Platform } from 'react-native';
import {
  defaultTextColors,
  defaultComponentsColors,
} from '../../utilites/utilities';
import Logo from '../../assets/LogoAlt';
import Background from '../../assets/Login/login1.jpg';
import CircleSnail from '../../components/Global/Loading';
import FormInput from '../../components/Global/FormInput';
import SimpleRequestHandler, {
  requestErrorHandler,
} from '../../services/meishop';

const Recovery = ({ navigation }) => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const handleSubmit = () => {
    if (isEmailValid) {
      setLoading(true);
      setErrorMessage([]);
      SimpleRequestHandler('forgot-password', 'POST', {
        email,
      })
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage(requestErrorHandler(error).split(' <br /> '));
          setLoading(false);
        });
    }
  };

  return (
    <Flex
      backgroundColor={{
        linearGradient: {
          colors: ['#463681', '#60519a'],
          start: [0, 0],
          end: [0, 1],
        },
      }}
      h="100%"
      w="100%"
      flex={1}
      position="absolute"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Image
        left={0}
        source={Background}
        position="absolute"
        alt="login background"
        width={[0, 0, 0, '55%']}
        defaultSource={Background}
        height={[0, 0, 0, '100%']}
      />
      <Flex
        zIndex={80}
        bgColor="#FFF"
        p={[4, 4, 4, 8]}
        borderRadius="3xl"
        top={[4, 4, 4, 0]}
        left={[4, 4, 4, 0]}
        right={[4, 4, 4, 0]}
        bottom={[4, 4, 4, 0]}
        ml={[0, 0, 0, 32, 64]}
        flexDirection="column"
        justifyContent="space-evenly"
        mt={Platform.OS === 'ios' ? 10 : 0}
        w={['auto', 'auto', 'auto', '30%']}
        h={['auto', 'auto', 'auto', '5/6']}
        alignItems={['center', 'center', 'center']}
        position={['absolute', 'absolute', 'absolute', 'relative']}
      >
        <Logo />
        <Flex
          mt={4}
          flexDirection="column"
          alignItems={['center', 'center', 'center', 'flex-start']}
        >
          <Text
            w="full"
            fontSize="2xl"
            lineHeight="sm"
            fontWeight="bold"
            color={defaultTextColors.default}
            textAlign={['center', 'center', 'center', 'left']}
          >
            Recupere sua senha
          </Text>
          <Text
            mt={2}
            w="full"
            opacity={60}
            fontSize="md"
            textAlign="left"
            fontWeight="medium"
            color={defaultTextColors.default}
          >
            Enviaremos um email com um link para recuperar sua senha.
          </Text>
        </Flex>
        <Flex mt={4} w="full" flexDirection="column">
          <FormInput
            w="full"
            label="Email"
            value={email}
            validate
            setValue={setEmail}
            isValid={isEmailValid}
            setIsValid={setIsEmailValid}
            validation={validateBr.email}
            customErroMessage="Digite um email válido"
          />
        </Flex>
        <Flex mt={[0, 0, 0, 6]} flexDirection="column" w="full">
          {success && (
            <Text
              mb={2}
              opacity={60}
              fontSize="md"
              lineHeight="sm"
              textAlign="center"
              fontWeight="medium"
              color={defaultTextColors.success}
            >
              Enviamos um link de recuperação de senha para seu email.
            </Text>
          )}
          {errorMessage.length >= 1 && (
            <Flex flexDirection="column">
              {errorMessage.map((message) => (
                <Text
                  my={1}
                  opacity={60}
                  fontSize="md"
                  lineHeight="sm"
                  textAlign="center"
                  fontWeight="medium"
                  color={defaultTextColors.error}
                >
                  {message}
                </Text>
              ))}
            </Flex>
          )}
          <Button
            py={3}
            px={5}
            w="full"
            fontSize="md"
            rounded="full"
            mt={[4, 4, 4, 0]}
            onPress={handleSubmit}
            bgColor={
              isEmailValid
                ? defaultComponentsColors.warning
                : defaultTextColors.disabled
            }
          >
            {loading ? (
              <CircleSnail
                size={25}
                color="#FFF"
                thickness={2}
                fill="rgba(0, 0, 0, 0)"
              />
            ) : (
              <Text fontSize="md" fontWeight="medium" color="#FFF">
                Enviar link de recuperação
              </Text>
            )}
          </Button>
          <Text
            px={2}
            w="full"
            fontSize="md"
            textAlign="center"
            mt={[2, 2, 2, 4]}
            color="rgba(44, 45, 73, 0.4)"
            onPress={() => navigation.navigate('Login')}
          >
            Voltar ao login
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Recovery;
