import React, { useState } from 'react';
import { Flex, Text, Button, Image } from 'native-base';
import { get } from 'lodash';
import {
  defaultTextColors,
  defaultComponentsColors,
} from '../../utilites/utilities';
import Logo from '../../assets/LogoAlt';
import Background from '../../assets/Login/login1.jpg';
import CircleSnail from '../../components/Global/Loading';
import FormPasswordInput from '../../components/Global/FormPasswordInput';
import { showToastMessage } from '../../utilites/ToastProvider';
import SimpleRequestHandler, {
  requestErrorHandler,
} from '../../services/meishop';

const Redefine = ({ route, navigation }) => {
  const token = get(route, 'params.token', null);
  const email = get(route, 'params.email', null);

  if (!token || !email) {
    navigation.navigate('Login');
  }

  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleLogin = () => {
    if (password === '') {
      showToastMessage(
        'Senha não pode ser vazia.',
        'Digite sua senha para continuar.',
        'warn'
      );
    } else if (password !== confirmPassword) {
      showToastMessage(
        'Senhas não conferem',
        'As senhas não são iguais.',
        'warn'
      );
    } else {
      setLoading(true);
      setErrorMessage([]);
      SimpleRequestHandler('reset-password', 'POST', {
        token,
        email,
        password,
        password_confirmation: confirmPassword,
      })
        .then(() => {
          showToastMessage(
            'Sucesso',
            'Sua senha foi alterada com sucesso.',
            'success'
          );
          navigation.navigate('Login');
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage(requestErrorHandler(error).split(' <br /> '));
          setLoading(false);
        });
    }
  };

  return (
    <Flex
      backgroundColor={{
        linearGradient: {
          colors: ['#463681', '#60519a'],
          start: [0, 0],
          end: [0, 1],
        },
      }}
      h="100%"
      w="100%"
      flex={1}
      position="absolute"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Image
        left={0}
        source={Background}
        position="absolute"
        width={[0, 0, 0, '55%']}
        defaultSource={Background}
        height={[0, 0, 0, '100%']}
      />
      <Flex
        zIndex={80}
        bgColor="#FFF"
        p={[4, 4, 4, 8]}
        borderRadius="3xl"
        top={[4, 4, 4, 0]}
        left={[4, 4, 4, 0]}
        right={[4, 4, 4, 0]}
        bottom={[4, 4, 4, 0]}
        ml={[0, 0, 0, 32, 64]}
        flexDirection="column"
        justifyContent="space-evenly"
        w={['auto', 'auto', 'auto', '30%']}
        h={['auto', 'auto', 'auto', '5/6']}
        alignItems={['center', 'center', 'center']}
        position={['absolute', 'absolute', 'absolute', 'relative']}
      >
        <Logo />
        <Flex
          mt={4}
          flexDirection="column"
          alignItems={['center', 'center', 'center', 'flex-start']}
        >
          <Text
            w="full"
            fontSize="2xl"
            lineHeight="sm"
            fontWeight="bold"
            color={defaultTextColors.default}
            textAlign={['center', 'center', 'center', 'left']}
          >
            Redefinir senha
          </Text>
          <Text
            mt={2}
            w="full"
            opacity={60}
            fontSize="md"
            textAlign="left"
            fontWeight="medium"
            color={defaultTextColors.default}
          >
            Crie sua nova senha e volte a acessar todos os benefícios.
          </Text>
        </Flex>
        <Flex mt={4} w="full" flexDirection="column">
          <FormPasswordInput
            w="full"
            label="Senha"
            value={password}
            setValue={setPassword}
          />
          <Text
            opacity={60}
            fontSize="xs"
            lineHeight="sm"
            color={defaultTextColors.default}
          >
            Sua senha deve possuir no mínimo 6 caracteres.
          </Text>
          <FormPasswordInput
            w="full"
            value={confirmPassword}
            label="Confirme sua senha"
            setValue={setConfirmPassword}
          />
        </Flex>
        <Flex mt={[0, 0, 0, 6]} flexDirection="column" w="full">
          {errorMessage.length >= 1 && (
            <Flex flexDirection="column">
              {errorMessage.map((message) => (
                <Text
                  my={1}
                  opacity={60}
                  fontSize="md"
                  lineHeight="sm"
                  textAlign="center"
                  fontWeight="medium"
                  color={defaultTextColors.error}
                >
                  {message}
                </Text>
              ))}
            </Flex>
          )}
          <Button
            py={3}
            px={5}
            w="full"
            fontSize="md"
            rounded="full"
            mt={[4, 4, 4, 0]}
            onPress={handleLogin}
            bgColor={defaultComponentsColors.warning}
          >
            {loading ? (
              <CircleSnail
                size={25}
                color="#FFF"
                thickness={2}
                fill="rgba(0, 0, 0, 0)"
              />
            ) : (
              <Text fontSize="md" fontWeight="medium" color="#FFF">
                Redefinir senha
              </Text>
            )}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Redefine;
