import React, { useEffect, useState } from 'react';
import { get, last } from 'lodash';
import {
  AMP_KEY,
  // eslint-disable-next-line
} from '@env';
import Toast from 'react-native-toast-message';
import { Box, Center, Flex } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useDispatch, useSelector } from 'react-redux';
import { Platform } from 'react-native';
import * as SecureStore from 'expo-secure-store';
import { userAtom } from '../Recoil/Atoms/user';
import { themeAtom } from '../Recoil/Atoms/theme';
import { tokenAtom } from '../Recoil/Atoms/token';
import { servicesAtom } from '../Recoil/Atoms/services';
import { userCompaniesAtom } from '../Recoil/Atoms/userCompanies';
import { servicesStatusAtom } from '../Recoil/Atoms/servicesStatus';
import { selectedCompanyAtom } from '../Recoil/Atoms/selectedCompany';
import { viewingCompanyAtom } from '../Recoil/Atoms/viewingCompany';
import { viewingCategoryAtom } from '../Recoil/Atoms/viewingCategory';
import { Creators as TokenActions } from '../redux/Store/Ducks/token';
import { Creators as AmplitudeActions } from '../redux/Store/Ducks/amplitude';
import {
  initializeAmplitude,
  ampLogEvent,
  defaultTextColors,
} from '../utilites/utilities';

import Home from '../Screens/Home';
import Das from '../Screens/Das/Das';
import Chat from '../Screens/Chat/index';
import Header from '../components/Header/Header';
import Feed from '../Screens/TipsForYou/Feed';
import Post from '../Screens/TipsForYou/Post';
import Details from '../Screens/Companies/Details';
import Solutions from '../Screens/Solutions/Index';
import ScreenHeader from '../components/Header/ScreenHeader';
import SimpleRequestHandler from '../services/meishop';
import Companies from '../Screens/Companies/Companies';
import NewCompany from '../Screens/Companies/NewCompany';
import ConfirmCompany from '../Screens/Companies/ConfirmCompany';
import CategoryFeed from '../Screens/TipsForYou/CategoryFeed';
import HeaderApps from '../components/Header/HeaderApps';
import { modalAtom } from '../Recoil/Atoms/modal';
import Modal from '../components/Global/Modal';
import Profile from '../Screens/User/Profile';
import { notificationsAtom } from '../Recoil/Atoms/notifications';
import Notifications from '../Screens/Notifications/Notifications';
import notificationCountListener from '../services/notificationCountListener';
import Login from '../Screens/Login/Login';
import FormalizationController from '../Screens/Formalization/FormalizationController';
import Register from '../Screens/Login/Register';
import Recovery from '../Screens/Login/Recovery';
import Redefine from '../Screens/Login/Redefine';
import CircleSnail from '../components/Global/Loading';
import MagicLink from '../Screens/Login/MagicLink';
import ServiceIcon from '../utilites/ServicesIcon';
import ChangePassword from '../Screens/User/ChangePassword';
import VerifyEmail from '../Screens/Login/VerifyEmail';
import Dasn from '../Screens/Dasn/Dasn';
import DasnModal from '../Screens/Dasn/DasnModal';
import DasnDeclaration from '../Screens/Dasn/DasnDeclaration';
import { modalDasnAtom } from '../Recoil/Atoms/modalDasn';
import CompanyNeedRoute from './CompanyNeedRoute';

const Router = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const Stack = createNativeStackNavigator();

  const setToken = useSetRecoilState(tokenAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const showModal = useRecoilValue(modalAtom).show;
  const [theme, setTheme] = useRecoilState(themeAtom);
  const showDasnModal = useRecoilValue(modalDasnAtom).show;
  const viewingCategory = useRecoilValue(viewingCategoryAtom);
  const [services, setServices] = useRecoilState(servicesAtom);
  const setUserCompanies = useSetRecoilState(userCompaniesAtom);
  const setServicesStatus = useSetRecoilState(servicesStatusAtom);
  const showNotifications = useRecoilValue(notificationsAtom).show;
  const setSelectedCompany = useSetRecoilState(selectedCompanyAtom);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const [viewingCompany, setViewingCompany] =
    useRecoilState(viewingCompanyAtom);

  const userToken = useSelector((state) => state.token.plainTextToken);
  const isLogged = userToken !== '';
  const colors = theme.layout;

  const [loading, setLoading] = useState(true);
  const [customer, setCustomer] = useState(null);
  const [currentRoute, setCurrentRoute] = useState('');
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [showSideBar, setShowSideBar] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const screenStyles = {
    zIndex: 10,
    flex: loading ? 0 : 1,
    borderRadiusBottom: 20,
    backgroundColor: '#f0f2f6',
  };

  const userKey = 'com.meishop.meunegocio.user';
  const themeKey = 'com.meishop.meunegocio.theme';
  const tokenKey = 'com.meishop.meunegocio.token';

  const setReloadingTheme = (value) => {
    setLoading(value);
    setLoadingAuth(value);
  };

  const TOAST_PRESS_ACTIONS = {
    USER_SUBSCRIPTION_IDENTIFIED: () => {
      setReloadingTheme(true);
      SimpleRequestHandler(`users/${user.id}`, 'GET')
        .then(({ data }) => {
          const userData = get(data, 'data', null);
          if (userData) {
            logUser({
              ...user,
              ...userData,
              token: userToken,
            });
            setReloadingTheme(false);
          }
        })
        .catch(() => {
          setReloadingTheme(false);
        });
    },
    COMPANY_AUTOMATICALLY_ADDED: ({ companyId = 0 }) => {
      navigation.navigate('company_cnpj', {
        id: companyId,
      });
    },
  };

  const loadAppStates = () => {
    SimpleRequestHandler('features/apps/status', 'GET')
      .then(({ data }) => {
        setServicesStatus(data);
        setLoading(false);
        setLoadingAuth(false);
      })
      .catch(() => {
        setLoading(false);
        setLoadingAuth(false);
      });
  };

  const getUserCompanies = (shouldLoadAppStates = true) => {
    SimpleRequestHandler('company', 'GET')
      .then((companyResponse) => {
        const userCompaniesResponse = get(companyResponse.data, 'data', []);
        setUserCompanies(userCompaniesResponse);
        setViewingCompany(userCompaniesResponse[0]);
        setSelectedCompany(userCompaniesResponse[0]);
        if (shouldLoadAppStates) {
          loadAppStates();
        }
      })
      .catch(() => {
        if (shouldLoadAppStates) {
          loadAppStates();
        }
      });
  };

  const handleReceiveNotification = (data) => {
    const notification = get(data, 'notification', false);
    if (notification.type === 'COMPANY_AUTOMATICALLY_ADDED') {
      getUserCompanies(false);
    }
    Toast.show({
      type: 'info',
      text1: notification.title,
      text2: notification.body,
      onPress: () => {
        TOAST_PRESS_ACTIONS[notification.type] &&
          TOAST_PRESS_ACTIONS[notification.type](notification);
      },
    });
    setNotifications({
      ...notifications,
      counter: get(data, 'unreadCount', 0),
    });
  };

  const getNotificationsCount = () => {
    SimpleRequestHandler('notifications/count', 'GET').then(({ data }) => {
      setNotifications({
        ...notifications,
        counter: get(data, 'count', 0),
      });
    });
  };

  const getSessionData = async () => {
    let sessionUser = null;
    let sessionToken = null;
    let sessionTheme = null;
    if (Platform.OS === 'web' && window && window.localStorage) {
      sessionUser = window.localStorage.getItem(userKey);
      sessionToken = window.localStorage.getItem(tokenKey);
      sessionTheme = window.localStorage.getItem(themeKey);
    } else {
      sessionUser = await SecureStore.getItemAsync(userKey);
      sessionToken = await SecureStore.getItemAsync(tokenKey);
      sessionTheme = await SecureStore.getItemAsync(themeKey);
    }
    if (sessionUser && sessionToken && themeKey) {
      const userData = JSON.parse(sessionUser);
      setUser(userData);
      setToken(sessionToken);
      setTheme(get(userData, 'whitelabel_customer', sessionTheme));
      dispatch(TokenActions.insertToken({ plainTextToken: sessionToken }));
      setServices(get(userData, 'whitelabel_customer.apps', []));
      getUserCompanies();
      getNotificationsCount();
    } else {
      setLoading(true);
      setLoadingAuth(false);
    }
  };

  const storeAmplitudeIds = (deviceId, sessionId) => {
    dispatch(
      AmplitudeActions.insertAmpIds({
        deviceId,
        sessionId,
      })
    );
  };

  useEffect(() => {
    if (services.length === 0) {
      getSessionData();
    }
    initializeAmplitude(AMP_KEY, storeAmplitudeIds);
    ampLogEvent(null, 'View - Enter', {});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userToken !== '' && user.id !== 0) {
      notificationCountListener(user.id, userToken, handleReceiveNotification);
    }
  }, [userToken, user]);

  const setStoreItems = async (user) => {
    if (Platform.OS === 'web' && window && window.localStorage) {
      window.localStorage.setItem(tokenKey, user.token);
      window.localStorage.setItem(userKey, JSON.stringify(user));
      window.localStorage.setItem(
        themeKey,
        JSON.stringify(get(user, 'whitelabel_customer', theme))
      );
    } else {
      await SecureStore.setItemAsync(userKey, JSON.stringify(user));
      await SecureStore.setItemAsync(tokenKey, user.token);
      await SecureStore.setItemAsync(
        themeKey,
        JSON.stringify(get(user, 'whitelabel_customer', theme))
      );
    }
  };

  const logUser = async (user) => {
    setLoadingAuth(true);
    await setStoreItems(user);
    setUser(user);
    setToken(user.token);
    setTheme(get(user, 'whitelabel_customer', theme));
    setServices(get(user, 'whitelabel_customer.apps', []));
    dispatch(TokenActions.insertToken({ plainTextToken: user.token }));
    getUserCompanies();
    getNotificationsCount();
  };

  useEffect(() => {
    if (customer) {
      setTheme({
        ...theme,
        customer,
      });
    }
  }, [customer]);

  const ampLogAppView = async (service) => {
    if (get(user, 'name', '') !== '') {
      const event = 'App View';
      const eventProperties = {
        app: service,
      };
      await ampLogEvent(user.id, event, eventProperties);
    }
  };

  if (loading || !isLogged) {
    return (
      <Flex flex={1} h="full" w="full">
        <Stack.Navigator>
          <Stack.Screen
            name="Login"
            options={{
              title: 'Entrar',
              headerShown: false,
            }}
          >
            {(props) => <Login logUser={logUser} {...props} />}
          </Stack.Screen>
          <Stack.Screen
            name="Register"
            options={{
              title: 'Cadastro',
              headerShown: false,
            }}
          >
            {(props) => <Register logUser={logUser} {...props} />}
          </Stack.Screen>
          <Stack.Screen
            name="Recovery"
            component={Recovery}
            options={{
              headerShown: false,
              title: 'Recuperar senha',
            }}
          />
          <Stack.Screen
            name="Redefine"
            component={Redefine}
            options={{
              headerShown: false,
              title: 'Redefinir senha',
            }}
          />
          <Stack.Screen
            name="Magiclink"
            options={{
              headerShown: false,
              title: 'Link mágico',
            }}
          >
            {(props) => <MagicLink logUser={logUser} {...props} />}
          </Stack.Screen>
          <Stack.Screen
            name="VerifyEmail"
            options={{
              headerShown: false,
              title: 'Verificar Email',
            }}
          >
            {(props) => <VerifyEmail isLogged={isLogged} {...props} />}
          </Stack.Screen>
        </Stack.Navigator>
        {loadingAuth && (
          <Center
            top={0}
            left={0}
            w="full"
            h="full"
            zIndex={80}
            position="absolute"
            backgroundColor={{
              linearGradient: {
                colors: ['#463681', '#60519a'],
                start: [0, 0],
                end: [0, 1],
              },
            }}
          >
            <CircleSnail
              size={80}
              color="#FFF"
              thickness={4}
              fill="rgba(0, 0, 0, 0)"
            />
          </Center>
        )}
      </Flex>
    );
  }

  return (
    <Box
      flex={1}
      p={loading ? 0 : [0, 0, 4]}
      backgroundColor={{
        linearGradient: {
          colors: [colors.bgSecondary, colors.bgPrimary],
          start: [0, 0],
          end: [0, 1],
        },
      }}
    >
      <Flex flex={1} flexDirection={['column', 'column', 'row']}>
        <Header
          openAuth={() => {}}
          isLogged={isLogged}
          sideBarOpen={showSideBar}
          userMenuOpen={showUserMenu}
          currentRoute={currentRoute}
          showSideBar={setShowSideBar}
          showUserMenu={setShowUserMenu}
        />
        <Flex
          flex={1}
          zIndex={10}
          bgColor="#f0f2f6"
          borderTopRadius={20}
          marginTop={showSideBar ? 200 : 0}
          borderBottomRadius={[0, 0, 0, 20]}
          paddingTop={Platform.OS === 'web' ? [4, 4, 4, 0] : 0}
        >
          <Flex flex={1} pt={[0, 4, 6, 16]} px={[0, 6, 10, 32, 40]}>
            <Stack.Navigator
              screenListeners={{
                state: (e) => {
                  const customerParams = get(
                    e,
                    'data.state.routes[0].params.customer',
                    ''
                  );
                  setCustomer(customerParams);
                  const routes = get(e, 'data.state.routes', null);
                  if (routes) {
                    setCurrentRoute(last(routes).name);
                    if (get(last(routes), 'name', '') !== '') {
                      ampLogAppView(last(routes).name);
                    }
                  }
                },
              }}
            >
              {/* DASHBOARD */}
              <Stack.Screen
                name="Dashboard"
                component={Home}
                options={{
                  title: 'Dashboard',
                  headerShown: true,
                  contentStyle: screenStyles,
                  cardStyle: {
                    backgroundColor: 'transparent',
                  },
                  header: ({ navigation }) => (
                    <ScreenHeader
                      home
                      title=""
                      iconSize={0}
                      navigation={navigation}
                    />
                  ),
                }}
              />
              {/* COMPANY ROUTES */}
              <Stack.Screen
                name="company"
                component={Companies}
                options={{
                  title: 'Minha empresa',
                  headerShown: true,
                  contentStyle: {
                    ...screenStyles,
                    padding: 0,
                    borderRadius: 0,
                  },
                  header: ({ navigation }) => (
                    <ScreenHeader
                      buttonText="Adicionar"
                      navigation={navigation}
                      title="Minhas Empresas"
                      titleSize={['lg', 'lg', 'xl', '2xl']}
                      buttonAction={async () => {
                        await ampLogEvent(user.id, 'Company Register CTA', {
                          location: 'company',
                        });
                        navigation.navigate('company_add');
                      }}
                    />
                  ),
                }}
              />
              <Stack.Screen
                name="company_add"
                component={NewCompany}
                options={{
                  title: 'Adicionar empresa',
                  headerShown: true,
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader
                      navigation={navigation}
                      title="Cadastre sua empresa"
                    />
                  ),
                }}
              />
              <Stack.Screen
                name="company_confirm"
                component={ConfirmCompany}
                options={{
                  title: 'Confirmar empresa',
                  headerShown: true,
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader
                      navigation={navigation}
                      title="Cadastre sua empresa"
                    />
                  ),
                }}
              />
              <Stack.Screen
                name="company_cnpj"
                component={Details}
                options={{
                  title: 'Cartão CNPJ',
                  headerShown: true,
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader
                      iconSize={24}
                      navigation={navigation}
                      titleSize={['lg', 'lg', 'lg', 'xl']}
                      title={get(
                        viewingCompany,
                        'cnpj.razao_social',
                        'Cartão CNPJ'
                      )}
                      badgeStatus={get(
                        viewingCompany,
                        'cnpj.situacao_cadastral',
                        'Não identificado'
                      )}
                    />
                  ),
                }}
              />
              {/* DAS */}
              <Stack.Screen
                name="boleto_das"
                component={Das}
                options={{
                  title: 'Boletos DAS',
                  headerShown: false,
                  contentStyle: screenStyles,
                }}
              />
              <Stack.Screen
                name="tips_for_you"
                component={Feed}
                options={{
                  title: 'Dicas par você',
                  headerShown: true,
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader
                      navigation={navigation}
                      title="Dicas pra você"
                    />
                  ),
                }}
              />
              <Stack.Screen
                name="tips"
                component={CategoryFeed}
                options={{
                  title: 'Dicas',
                  headerShown: true,
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader
                      navigation={navigation}
                      title={viewingCategory}
                    />
                  ),
                }}
              />
              <Stack.Screen
                name="tips_post"
                component={Post}
                options={{
                  title: 'Post',
                  headerShown: true,
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader navigation={navigation} title="Voltar" />
                  ),
                }}
              />
              <Stack.Screen
                name="solutions"
                component={Solutions}
                options={{
                  title: 'Solucoes',
                  headerShown: true,
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader navigation={navigation} title="Soluções" />
                  ),
                }}
              />
              <Stack.Screen
                name="atendimento"
                component={Chat}
                options={{
                  title: 'Atendimento',
                  headerShown: true,
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader navigation={navigation} title="Atendimento" />
                  ),
                }}
              />
              <Stack.Screen
                name="profile"
                component={Profile}
                options={{
                  headerShown: true,
                  title: 'Meus dados',
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader
                      title="Meus dados"
                      navigation={navigation}
                      buttonText="Alterar senha"
                      buttonAction={() =>
                        navigation.navigate('change_password')
                      }
                      icon={ServiceIcon('key', 18, defaultTextColors.success)}
                    />
                  ),
                }}
              />
              <Stack.Screen
                name="change_password"
                component={ChangePassword}
                options={{
                  headerShown: true,
                  title: 'Alterar senha',
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader
                      title="Alterar senha"
                      navigation={navigation}
                    />
                  ),
                }}
              />
              <Stack.Screen
                name="formalizacao"
                component={FormalizationController}
                options={{
                  title: 'Formalização',
                  headerShown: true,
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader
                      navigation={navigation}
                      title="Formalização"
                    />
                  ),
                }}
              />
              <Stack.Screen
                name="declaracao_dasn"
                options={{
                  title: 'DASN',
                  headerShown: true,
                  contentStyle: screenStyles,
                  header: ({ navigation }) => (
                    <ScreenHeader
                      navigation={navigation}
                      title="Declaração Anual DASN"
                    />
                  ),
                }}
              >
                {(props) => (
                  <CompanyNeedRoute px={4}>
                    <Dasn {...props} />
                  </CompanyNeedRoute>
                )}
              </Stack.Screen>
              <Stack.Screen
                name="dasn_declaration"
                component={DasnDeclaration}
                options={{
                  title: 'Declaração DASN',
                  headerShown: false,
                  contentStyle: screenStyles,
                }}
              />
            </Stack.Navigator>
          </Flex>
        </Flex>
      </Flex>
      <HeaderApps />
      {showDasnModal && <DasnModal />}
      {showNotifications && (
        <Notifications
          setReloadingTheme={setReloadingTheme}
          logUser={logUser}
        />
      )}
      {showModal && <Modal />}
    </Box>
  );
};

export default Router;
