import React, { useState, useEffect } from 'react';
import { filter, get, orderBy } from 'lodash';
import { useRecoilValue } from 'recoil';
import {
  Box,
  Flex,
  Text,
  Center,
  Button,
  FlatList,
  CloseIcon,
} from 'native-base';
import { DeviceEventEmitter } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { themeAtom } from '../../Recoil/Atoms/theme';
import CircleSnail from '../../components/Global/Loading';
import SimpleRequestHandler from '../../services/meishop';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';
import DasnItem from '../../components/Dasn/DasnItem';
import { selectedCompanyAtom } from '../../Recoil/Atoms/selectedCompany';

const Dasn = ({ navigation }) => {
  const isFocused = useIsFocused();
  const colors = useRecoilValue(themeAtom).layout;
  const userCompany = useRecoilValue(selectedCompanyAtom);

  const [dasn, setDasn] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('INITIAL');

  const getDasn = (companyId = null) => {
    const getFromCompany = companyId || userCompany.id;
    SimpleRequestHandler(`company/${getFromCompany}/dasns`, 'GET')
      .then((response) => {
        const responseStatus = get(response.data, 'data.status', 'ERROR');
        const dasnList = get(response.data, 'data.dasns', []);
        // Sort dasn per year & remove dasn from current year.
        setDasn(
          filter(
            orderBy(dasnList, ['ano'], ['desc']),
            (o) => o.ano !== new Date().getFullYear()
          )
        );
        setStatus(responseStatus === null ? 'SUCCESS' : responseStatus);
        setLoading(false);
      })
      .catch(() => {
        setStatus('ERROR');
        setLoading(false);
      });
  };

  useEffect(() => {
    DeviceEventEmitter.addListener('listen_dasn_reload', ({ companyId }) => {
      setStatus('PROCESSING');
      getDasn(companyId);
    });
  }, []);

  useEffect(() => {
    if (isFocused) {
      setStatus('PROCESSING');
      getDasn();
    }
  }, [isFocused, userCompany]);

  if (status === 'SUCCESS') {
    return (
      <Flex flex={1} flexGrow={1} px={4}>
        {!loading && dasn && dasn.length === 0 && (
          <Text
            mt={20}
            mx="auto"
            fontSize="lg"
            textAlign="center"
            fontWeight="medium"
            color={defaultTextColors.default}
          >
            Não identificamos nenhuma declaração DASN para sua empresa
          </Text>
        )}
        {!loading && dasn && dasn.length >= 1 && (
          <FlatList
            mt={4}
            data={dasn}
            keyExtractor={(item) => item.company_dasn_id}
            renderItem={(props) => (
              <DasnItem navigation={navigation} {...props} />
            )}
            _contentContainerStyle={{ flexGrow: 1, paddingBottom: 5 }}
          />
        )}
      </Flex>
    );
  }

  return (
    <Flex flex={1} flexDirection="column" px={4} mb={4}>
      <Center mt={24}>
        <Box p={2} rounded="full" bgColor={colors.bgSecondary}>
          {status === 'ERROR' && <CloseIcon p={2} size="md" color="#FFF" />}
          {status !== 'ERROR' && (
            <CircleSnail color="#FFF" thickness={4} fill={colors.bgSecondary} />
          )}
        </Box>
        <Flex mt={4} w="3/4" mx="auto" flexDirection="column">
          <Text
            fontSize="xl"
            lineHeight="sm"
            fontWeight="bold"
            textAlign="center"
            color={defaultTextColors.default}
          >
            {status !== 'ERROR' &&
              'Estamos buscando o histórico da DASN da sua MEI'}
            {status === 'ERROR' && 'Erro ao buscar o DASN da sua MEI.'}
          </Text>
          {status !== 'ERROR' && (
            <Text
              mt={4}
              fontSize="lg"
              lineHeight="sm"
              textAlign="center"
              color="rgba(44, 45, 73, 0.4)"
            >
              Em menos de um minuto você poderá voltar aqui e ver seu histórico
              da DASN.
            </Text>
          )}
          {status === 'ERROR' && (
            <Text
              mt={4}
              fontSize="lg"
              lineHeight="sm"
              textAlign="center"
              color="rgba(44, 45, 73, 0.4)"
            >
              Por favor, tente novamente depois.
            </Text>
          )}
        </Flex>
      </Center>
      <Button
        py={3}
        px={5}
        mb={6}
        mt="auto"
        w="full"
        fontSize="md"
        rounded="full"
        onPress={() => navigation.navigate('Dashboard')}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="md" fontWeight="semibold" color="#FFF">
          Ir para home
        </Text>
      </Button>
    </Flex>
  );
};

export default Dasn;
