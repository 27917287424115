import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Pressable, Image, Text, Flex } from 'native-base';
import { Platform } from 'react-native';
import Fallback from '../../assets/fallback.png';
import { defaultTextColors } from '../../utilites/utilities';

const MiniCards = ({ item, isCategoryShowUp }) => {
  const navigation = useNavigation();

  const handleOpenPost = () => {
    navigation.navigate('tips_post', { ...item, ...item.img_urls });
  };

  const devicesWidth = {
    web: '3/4',
    ios: 'auto',
    android: 'auto',
  };

  return (
    <Pressable
      mb={3}
      onPress={() => handleOpenPost()}
      px={isCategoryShowUp ? 2 : [4, 4, 4, 0]}
      w={
        isCategoryShowUp
          ? devicesWidth[Platform.OS]
          : ['100%', '100%', '100%', 'auto']
      }
    >
      <Flex
        bg="#fff"
        shadow={2}
        rounded="2xl"
        p={isCategoryShowUp ? 4 : [3, 3, 3, 4]}
        mr={isCategoryShowUp ? 2 : [0, 0, 0, 4]}
        w={isCategoryShowUp ? 56 : ['100%', '100%', '100%', 56]}
        flexDirection={
          isCategoryShowUp ? 'column' : ['row', 'row', 'row', 'column']
        }
        justifyContent={
          isCategoryShowUp
            ? 'space-between'
            : ['flex-start', 'flex-start', 'flex-start', 'space-between']
        }
      >
        <Image
          alt="Imagem do post"
          fallbackSource={Fallback}
          source={
            item.img_urls.medium ? { uri: item.img_urls.medium } : Fallback
          }
          h={isCategoryShowUp ? 32 : [16, 16, 16, 32]}
          w={isCategoryShowUp ? '100%' : [16, 16, 16, '100%']}
          rounded={isCategoryShowUp ? '2xl' : ['xl', 'xl', 'xl', '2xl']}
        />
        <Flex
          h={16}
          w="100%"
          flexDirection="column"
          justifyContent="space-between"
          ml={isCategoryShowUp ? 0 : [3, 3, 4, 0]}
        >
          <Text
            bold
            h={12}
            mt={2}
            flex={1}
            fontSize="sm"
            lineHeight="sm"
            numberOfLines={2}
            color={defaultTextColors.default}
            width={isCategoryShowUp ? '100%' : ['75%', '75%', '75%', '100%']}
          >
            {item.title}
          </Text>
          <Text
            mt={2}
            color="rgba(44, 45, 73, 0.4)"
            fontSize="2xs"
            fontWeight="semibold"
          >
            {new Intl.DateTimeFormat('pt-BR').format(new Date(item.date))}
          </Text>
        </Flex>
      </Flex>
    </Pressable>
  );
};

export default MiniCards;
