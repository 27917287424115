import React, { useState } from 'react';
import { View } from 'moti';
import { split } from 'lodash';
import * as Clipboard from 'expo-clipboard';
import { Box, Flex, Text, Pressable, Button } from 'native-base';
import DasStatusBagde from './DasStatusBagde';
import ServiceIcon from '../../utilites/ServicesIcon';
import {
  defaultComponentsColors,
  defaultTextColors,
  handleOpenLink,
} from '../../utilites/utilities';

const DasItem = ({
  item,
  index,
  w = '100%',
  pending = false,
  isPending = false,
}) => {
  const [open, setOpen] = useState(isPending);
  const [copyText, setCopyText] = useState('Copiar código');

  const debit = item.situacao !== 'LIQUIDADO';

  const splitPeriod = (period) => {
    const letters = split(period, '');
    const splitedYear = split(period, '/')[1];

    return `${letters[0]}${letters[1]}${letters[2]}. ${splitedYear}`;
  };

  const handleExpand = () => {
    if (!isPending && debit) {
      setOpen(!open);
    }
  };

  const copyDasCode = (code) => {
    Clipboard.setString(code);
    setCopyText('Código copiado!');
    setTimeout(() => {
      setCopyText('Copiar código');
    }, 4000);
  };

  return (
    <Flex
      w={w}
      mb={4}
      rounded="2xl"
      mr={pending ? 4 : 0}
      backgroundColor="#FFF"
      flexDirection="column"
      opacity={debit ? 1 : 0.7}
      p={isPending ? 4 : [4, 4, 6]}
      ml={pending && index === 0 ? 1 : 0}
    >
      <Pressable onPress={() => handleExpand()}>
        {debit && (
          <Box
            display={pending ? 'flex' : ['flex', 'flex', 'flex', 'none']}
            w="100%"
            mb={2}
          >
            <DasStatusBagde situacao={item.situacao} />
          </Box>
        )}
        <Flex
          flexDirection="row"
          justifyContent={['space-between', 'space-between', 'space-between']}
        >
          <Flex flexDirection="row" alignItems="center" justifyContent="center">
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Text
                textAlign="left"
                fontWeight="semibold"
                color="rgba(44, 45, 73, 0.4)"
                fontSize={isPending ? 'xs' : 'sm'}
              >
                Período
              </Text>
              <Text
                textAlign="left"
                fontWeight="bold"
                color="rgba(44, 45, 73, 1)"
                fontSize={isPending ? 'xs' : 'sm'}
              >
                {splitPeriod(item.periodo_desc)}
              </Text>
            </Flex>
            {!pending && debit && (
              <Box
                w={32}
                top={0}
                left={16}
                position="absolute"
                alignItems="flex-end"
                display={pending ? 'flex' : ['none', 'none', 'none', 'flex']}
              >
                <DasStatusBagde situacao={item.situacao} px={4} py={1} />
              </Box>
            )}
          </Flex>
          {item.data_vencimento && (
            <Flex
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Text
                textAlign="center"
                fontWeight="semibold"
                color="rgba(44, 45, 73, 0.4)"
                fontSize={isPending ? 'xs' : 'sm'}
              >
                Vencimento
              </Text>
              <Text
                mt={1}
                fontWeight="bold"
                textAlign="center"
                color="rgba(44, 45, 73, 1)"
                fontSize={isPending ? 'xs' : 'sm'}
              >
                {new Intl.DateTimeFormat('pt-BR').format(
                  new Date(item.data_vencimento)
                )}
              </Text>
            </Flex>
          )}
          {item.valor_total !== 0 && (
            <Flex
              alignItems="flex-end"
              flexDirection="column"
              justifyContent="center"
            >
              <Text
                textAlign="right"
                fontWeight="semibold"
                color="rgba(44, 45, 73, 0.4)"
                fontSize={isPending ? 'xs' : 'sm'}
              >
                Valor
              </Text>
              <Text
                mt={1}
                textAlign="right"
                fontWeight="bold"
                color="rgba(44, 45, 73, 1)"
                fontSize={isPending ? 'xs' : 'sm'}
              >
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(item.valor_total)}
              </Text>
            </Flex>
          )}
          {!debit && (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {ServiceIcon('check-circle', 24, '#00a8b3')}
              <Text bold mt={1} color="rgba(44, 45, 73, 0.4)">
                Pago
              </Text>
            </Flex>
          )}
        </Flex>
        {!isPending && debit && (
          <Flex mt={2} w="100%" justifyContent="center" alignItems="center">
            <View
              animate={{
                rotate: open ? '180deg' : '0deg',
              }}
              transition={{
                duration: 140,
                type: 'timing',
              }}
            >
              {ServiceIcon('down', 24, defaultTextColors.default)}
            </View>
          </Flex>
        )}
      </Pressable>
      {open && (
        <Flex
          mt={2}
          alignItems={
            isPending
              ? 'flex-start'
              : ['flex-start', 'flex-start', 'flex-start', 'center']
          }
          flexDirection={
            isPending ? 'column' : ['column', 'column', 'column', 'row']
          }
        >
          {!isPending && (
            <Text
              px={4}
              opacity={60}
              lineHeight={16}
              borderWidth={1}
              py={[2, 2, 2, 3]}
              textAlign="center"
              borderRadius="full"
              borderColor="#d7dce4"
              color={defaultTextColors.default}
              w={['100%', '100%', '100%', '60%']}
            >
              {item.boleto_codigo_barras}
            </Text>
          )}
          <Flex
            w="100%"
            flex={1}
            flexDirection="row"
            justifyContent="space-between"
            mb={isPending ? 2 : open ? 0 : 8}
            ml={isPending ? 0 : [0, 0, 0, 2]}
            mt={isPending ? 2 : [4, 4, 4, 0]}
          >
            <Button
              w="48%"
              rounded="full"
              borderWidth={1}
              borderColor="#d7dce4"
              bgColor="rgba(0, 0, 0, 0)"
              h={isPending ? 8 : [8, 8, 8, 'full']}
              onPress={() => handleOpenLink(item.boleto_url)}
            >
              <Text
                fontSize="sm"
                fontWeight="semibold"
                textColor={defaultTextColors.default}
              >
                Ver boleto
              </Text>
            </Button>
            <Button
              w="48%"
              rounded="full"
              h={isPending ? 8 : [8, 8, 8, 'full']}
              bgColor={defaultComponentsColors.warning}
              onPress={() => copyDasCode(item.boleto_codigo_barras)}
            >
              <Text color="#FFF" fontSize="sm" fontWeight="semibold">
                {copyText}
              </Text>
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default DasItem;
