import React from 'react';
import { Box, Flex, Text } from 'native-base';

const DasStatusBagde = ({ situacao, w = '100%', px = 2, py = 1 }) => (
  <Flex
    w={w}
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
  >
    <Box
      py={py}
      px={px}
      w="100%"
      rounded="full"
      bgColor={
        situacao === 'DEVEDOR'
          ? 'rgba(236,36,36,0.2)'
          : 'rgba(236, 170, 36, 0.2)'
      }
    >
      <Text
        textAlign="center"
        fontSize="xs"
        color="rgba(44, 45, 73, 1)"
        bold
        isTruncated
      >
        {situacao}
      </Text>
    </Box>
  </Flex>
);

export default DasStatusBagde;
