import React from 'react';
import { get } from 'lodash';
import { cnpj } from 'cpf-cnpj-validator';
import { Box, Divider } from 'native-base';
import { Platform } from 'react-native';
import Button from '../Buttons/Button';
import CardRow from '../Global/CardRow';
import CompanyBadge from './CompanyBadge';

const CompanyCard = ({ item, navigation, setViewingCompany }) => {
  const active = item.cnpj.situacao_cadastral !== 'INAPTA';

  const handleOpenDetails = () => {
    setViewingCompany(item);
    navigation.navigate('company_cnpj', { id: item.id });
  };

  return (
    <Box
      p={4}
      mt={4}
      shadow={1}
      bgColor="white"
      borderRadius={20}
      h={Platform.OS === 'web' ? 'auto' : 40}
    >
      <CardRow label="Nome fantasia" value={item.cnpj.nome_fantasia} />
      <CompanyBadge
        top={4}
        right={4}
        active={active}
        position="absolute"
        companyStatus={get(item, 'cnpj.situacao_cadastral', 'Não identificado')}
      />
      <Divider w="100%" my="2" />
      <CardRow label="CNPJ" value={cnpj.format(item.cnpj.cnpj)} />
      <Button
        py={2}
        mt={4}
        flex={1}
        text="Ver mais"
        fontWeight="bold"
        borderColor="#d7dce4"
        action={handleOpenDetails}
      />
    </Box>
  );
};

export default CompanyCard;
