import React from 'react';
import { Button, ScrollView, Text } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../../utilites/utilities';

const Success = () => {
  const navigation = useNavigation();

  return (
    <ScrollView
      px={[4, 8, 16, 20]}
      _contentContainerStyle={{
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      <Text
        fontSize="xl"
        textAlign="center"
        fontWeight="semibold"
        color={defaultTextColors.default}
      >
        PARABÉNS, VOCÊ ESTÁ QUASE LÁ
      </Text>
      <Text
        mt={4}
        fontSize="md"
        fontWeight="medium"
        color={defaultTextColors.default}
      >
        O seu pedido para cadastro de CNPJ na categoria MEI foi recebido com
        sucesso e, em breve, você será um microempreendedor individual.
      </Text>
      <Text
        mt={4}
        fontSize="md"
        fontWeight="medium"
        color={defaultTextColors.default}
      >
        Fique de olho na caixa de entrada do seu e-mail, é por lá que você será
        comunicado sobre a aprovação do seu cadastro pelo Portal do
        Empreendedor.
      </Text>
      <Text
        mt={4}
        fontSize="md"
        fontWeight="medium"
        color={defaultTextColors.default}
      >
        Caso haja algum dado incorreto ou com problemas de verificação, a
        #EquipeMEiShop entrará em contato pelo telefone ou e-mail cadastrados
        para solucionar qualquer pendência que esteja impedindo o cadastro do
        seu CNPJ, combinado?
      </Text>
      <Button
        py={3}
        px={5}
        mb={4}
        mt={6}
        mx="auto"
        fontSize="md"
        rounded="full"
        w={['full', 'full', 'full', '1/3']}
        bgColor={defaultComponentsColors.warning}
        onPress={() => navigation.navigate('Dashboard')}
      >
        <Text fontSize="md" fontWeight="medium" color="#FFF">
          Voltar ao Dashboard
        </Text>
      </Button>
    </ScrollView>
  );
};

export default Success;
