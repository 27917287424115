import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Center, Flex, Pressable, Text } from 'native-base';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Animated, Dimensions } from 'react-native';
import { get } from 'lodash';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { modalDasnAtom } from '../../Recoil/Atoms/modalDasn';
import ServicesIcon from '../../utilites/ServicesIcon';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';
import CircleSnail from '../../components/Global/Loading';
import SimpleRequestHandler from '../../services/meishop';
import { selectedCompanyAtom } from '../../Recoil/Atoms/selectedCompany';

const DasnModal = () => {
  const colors = useRecoilValue(themeAtom).layout;
  const windowWidth = Dimensions.get('window').width;
  const selectedCompany = useRecoilValue(selectedCompanyAtom);
  const [dasnModal, setDasnModal] = useRecoilState(modalDasnAtom);

  const [value, setValue] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const yOffset = useRef(
    new Animated.Value(windowWidth >= 768 ? 0 : 800)
  ).current;
  const xOffset = useRef(
    new Animated.Value(windowWidth >= 768 ? 400 : 0)
  ).current;

  const slideIn = () => {
    if (windowWidth >= 768) {
      Animated.timing(xOffset, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(yOffset, {
        toValue: 0,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  };

  const slideOut = () => {
    if (windowWidth >= 768) {
      Animated.timing(xOffset, {
        toValue: 400,
        duration: 200,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(yOffset, {
        toValue: 800,
        duration: 200,
        useNativeDriver: true,
      }).start();
    }
  };

  const formatValue = () =>
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value / 100);

  const handleClose = () => {
    slideOut();
    setTimeout(() => {
      setDasnModal({
        ...dasnModal,
        show: false,
        year: null,
      });
    }, 200);
  };

  const handleConfirm = () => {
    slideOut();
    setTimeout(() => {
      setDasnModal({
        ...dasnModal,
        year: null,
        show: false,
        useValue: true,
        servicesValue: value / 100,
      });
    }, 200);
  };

  const getCompanyValue = () => {
    SimpleRequestHandler(
      `company/${selectedCompany.id}/notafiscal/revenue/${dasnModal.year}`,
      'GET'
    )
      .then(({ data }) => {
        setLoading(false);
        setValue(get(data, 'data.total', 0));
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    slideIn();
  }, []);

  useEffect(() => {
    if (dasnModal.year !== null) {
      getCompanyValue();
    }
  }, [dasnModal]);

  return (
    <Flex
      flex={1}
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={80}
      overflow="hidden"
      position="absolute"
      justifyContent="flex-end"
      bgColor="rgba(0, 0, 0, 0.4)"
    >
      <Animated.View
        style={{
          height: windowWidth >= 768 ? '100%' : 'auto',
          transform: [
            {
              translateY: yOffset,
              translateX: xOffset,
            },
          ],
        }}
      >
        <Flex
          h="full"
          flex={1}
          bgColor="#FFF"
          px={[4, 4, 4, 10]}
          pb={[4, 4, 4, 10]}
          pt={[6, 6, 6, 10]}
          ml={[0, 0, 0, 'auto']}
          w={['full', 'full', 'full', '1/3']}
          roundedTop={['2xl', '2xl', '2xl', '']}
        >
          <Flex
            alignItems="center"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={defaultTextColors.default}
            >
              Seu faturamento com a DicasMei
            </Text>
            <Pressable opacity={40} onPress={handleClose}>
              {ServicesIcon('x', 24, '#2c2d49')}
            </Pressable>
          </Flex>
          <Text
            fontSize="md"
            mt={[6, 6, 6, 10]}
            fontWeight="medium"
            color={defaultTextColors.default}
          >
            {`Abaixo está o valor bruto total das notas fiscais de serviço (NFSe) que você emitiu com a DicasMEI em ${dasnModal.year}.`}
          </Text>
          {loading && (
            <Center my={[6, 6, 6, 0]} flex={1}>
              <CircleSnail size={80} thickness={4} color={colors.bgSecondary} />
            </Center>
          )}
          {!loading && error && (
            <Flex my={[6, 6, 6, 4]} flexDirection="row" alignItems="center">
              <Box mr={2} mx="auto">
                {ServicesIcon('x', 24, defaultTextColors.error)}
              </Box>
              <Text
                fontSize="md"
                fontWeight="medium"
                color={defaultTextColors.default}
              >
                Não foi possivel carregar seu faturamento, tente novamento mais
                tarde.
              </Text>
            </Flex>
          )}
          {!loading && !error && (
            <Flex>
              <Box p={6} mt={[6, 6, 6, 10]} rounded="3xl" bgColor="#f0f2f6">
                <Text fontSize="2xl" fontWeight="bold" color={colors.bgPrimary}>
                  {formatValue()}
                </Text>
              </Box>
              <Text
                fontSize="md"
                mt={[4, 4, 4, 8]}
                fontWeight="medium"
                color={defaultTextColors.default}
              >
                {`Esse é o seu faturamento bruto total com serviços em ${dasnModal.year} baseado apenas nas notas que você emitiu com a DicasMEI.`}
              </Text>
              <Text
                fontSize="md"
                fontWeight="bold"
                mt={[4, 4, 4, 8]}
                color={defaultTextColors.default}
              >
                {`Se você emitiu outras notas, você deve somar o total emitido a esse valor de ${formatValue()}.`}
              </Text>
              <Text
                fontSize="md"
                mt={[4, 4, 4, 8]}
                fontWeight="medium"
                color={defaultTextColors.default}
              >
                Se você só emitiu notas com a DicasMEI, você pode usar esse
                valor em sua declaração como o valor de faturamento bruto com
                serviços.
              </Text>
              <Button
                py={3}
                mb={4}
                px={5}
                mt={6}
                mx="auto"
                w="full"
                fontSize="md"
                rounded="full"
                onPress={handleConfirm}
                bgColor={defaultComponentsColors.warning}
              >
                <Text fontSize="md" fontWeight="semibold" color="#FFF">
                  Usar valor na declaração
                </Text>
              </Button>
            </Flex>
          )}
        </Flex>
      </Animated.View>
    </Flex>
  );
};

export default DasnModal;
