import React, { useState } from 'react';
import { get } from 'lodash';
import { MotiView } from 'moti';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Flex, Text, Divider, Pressable, FlatList } from 'native-base';
import Icons from '../../utilites/ServicesIcon';
import { selectedCompanyAtom } from '../../Recoil/Atoms/selectedCompany';
import { userAtom } from '../../Recoil/Atoms/user';
import { userCompaniesAtom } from '../../Recoil/Atoms/userCompanies';
import { viewingCompanyAtom } from '../../Recoil/Atoms/viewingCompany';
import ServiceItem from './ServiceItem';

const HeaderMenu = ({
  handleScreenLoad,
  currentRoute,
  services,
  servicesState,
  isLogged,
}) => {
  const [selectedCompany, setSelectedCompany] =
    useRecoilState(selectedCompanyAtom);
  const user = useRecoilValue(userAtom);
  const userCompanies = useRecoilValue(userCompaniesAtom);
  const setViewingCompany = useSetRecoilState(viewingCompanyAtom);
  const userName = user.name === '' ? user.name : user.name.split(' ')[0];

  const [openCompanySelector, setOpenCompanySelector] = useState(false);

  const handleSelectCompany = (company) => {
    setViewingCompany(company);
    setSelectedCompany(company);
    setOpenCompanySelector(false);
  };

  return (
    <Flex w="100%" flex={1} zIndex={20} flexDirection="column">
      {isLogged && userCompanies.length !== 0 && (
        <Flex
          p={2}
          mb={2}
          rounded="xl"
          borderWidth={1}
          flexDirection="column"
          borderColor="rgba(255, 255, 255, 0.2)"
        >
          <Pressable
            onPress={() => setOpenCompanySelector(!openCompanySelector)}
          >
            <Text bold pl={2} pb={2} color="rgba(255, 255 ,255 ,0.4)">
              {`Olá, ${userName}`}
            </Text>
            <Flex
              p={2}
              roundedTop="md"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              bgColor="rgba(255, 255, 255, 0.1)"
              roundedBottom={openCompanySelector ? 0 : 'md'}
            >
              <Text w="90%" color="#FFF" fontWeight="semibold" isTruncated>
                {`Empresa: ${get(selectedCompany, 'cnpj.razao_social', '')}`}
              </Text>
              <MotiView
                animate={{
                  rotate: openCompanySelector ? '180deg' : '0deg',
                }}
                transition={{
                  duration: 140,
                  type: 'timing',
                }}
              >
                {Icons('down', 25, '#FFF')}
              </MotiView>
            </Flex>
          </Pressable>
          <Flex
            roundedBottom="md"
            flexDirection="column"
            bgColor="rgba(255, 255, 255, 0.1)"
          >
            {openCompanySelector &&
              userCompanies.map((company, key) => (
                <Pressable
                  key={key.toString()}
                  onPress={() => handleSelectCompany(company)}
                >
                  <Divider
                    w="100%"
                    bg="rgba(255, 255, 255, 0.2)"
                    thickness="1"
                  />
                  <Flex
                    p={2}
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Text w="90%" color="#FFF" fontWeight="medium">
                      {company.cnpj.razao_social}
                    </Text>
                    {company.id === selectedCompany.id &&
                      Icons('check', 18, '#FFF')}
                  </Flex>
                </Pressable>
              ))}
          </Flex>
        </Flex>
      )}
      <Flex flex={1}>
        <FlatList
          data={[
            {
              name: 'Início',
              path: 'Dashboard',
            },
            ...services,
          ]}
          keyExtractor={(item) => item.path}
          renderItem={({ item, index }) => (
            <ServiceItem
              item={item}
              index={index}
              currentRoute={currentRoute}
              servicesState={servicesState}
              handleScreenLoad={handleScreenLoad}
            />
          )}
          _contentContainerStyle={{ paddingBottom: 5 }}
        />
      </Flex>
    </Flex>
  );
};

export default HeaderMenu;
