import React, { useEffect } from 'react';
import { find, get } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Text, Flex, Pressable, Box, ScrollView } from 'native-base';
import { Dimensions } from 'react-native';
import { tokenAtom } from '../Recoil/Atoms/token';
import { themeAtom } from '../Recoil/Atoms/theme';
import ServiceIcon from '../utilites/ServicesIcon';
import { servicesAtom } from '../Recoil/Atoms/services';
import { ampLogEvent, defaultTextColors } from '../utilites/utilities';
import { showToastMessage } from '../utilites/ToastProvider';
import { servicesStatusAtom } from '../Recoil/Atoms/servicesStatus';
import { selectedCompanyAtom } from '../Recoil/Atoms/selectedCompany';

const Home = ({ navigation, route }) => {
  const windowWidth = Dimensions.get('window').width;

  const services = useRecoilValue(servicesAtom);
  const isLogged = useRecoilValue(tokenAtom) !== '';
  const [theme, setTheme] = useRecoilState(themeAtom);
  const userCompany = useRecoilValue(selectedCompanyAtom);
  const servicesState = useRecoilValue(servicesStatusAtom);

  const handleScreenLoad = (path) => {
    if (isLogged) {
      if (path === 'company_cnpj') {
        const companyId = get(userCompany, 'id', null);
        navigation.navigate(path, {
          id: companyId,
        });
      } else {
        navigation.navigate(path);
      }
    } else {
      showToastMessage(
        'Acesso restrito',
        'Você precisa estar logado para acessar este serviço.',
        'warn'
      );
    }
  };

  useEffect(() => {
    if (route.params) {
      setTheme({
        ...theme,
        customer: route.params.customer,
      });
    }
    ampLogEvent(null, 'View - Enter', {});
  }, []);

  return (
    <ScrollView
      contentContainerStyle={{
        flexWrap: 'wrap',
        paddingBottom: 20,
        flexDirection: 'row',
        flexGrow: windowWidth >= 768 ? 0 : 1,
        justifyContent: windowWidth >= 768 ? 'flex-start' : 'space-around',
      }}
    >
      {services.length !== 0 &&
        services.map((service, index) => {
          const isPublished =
            parseInt(
              get(
                find(servicesState, ['name', service.name]),
                'is_published',
                0
              ),
              10
            ) !== 0;
          return (
            <Pressable
              key={index.toString()}
              onPress={() => {
                if (isPublished) {
                  handleScreenLoad(service.path);
                }
              }}
            >
              <Flex
                m={2}
                bg="white"
                shadow={1}
                py={[4, 0]}
                rounded="2xl"
                alignItems="center"
                flexDirection="column"
                width={[140, 140, 160]}
                height={[160, 160, 180]}
              >
                {!isPublished && (
                  <Box
                    py={1}
                    px={2}
                    top={2}
                    right={2}
                    rounded="full"
                    bgColor="#007fc6"
                    position="absolute"
                  >
                    <Text color="#f0f2f6" fontSize="xs" bold>
                      Em breve
                    </Text>
                  </Box>
                )}
                <Box
                  py={5}
                  px={8}
                  mb={[2, 2]}
                  mt={[2, 4]}
                  borderRadius={12}
                  opacity={isPublished ? 1 : 60}
                  backgroundColor="rgba(96, 81, 154, 0.05)"
                >
                  {ServiceIcon(service.path, 45, theme.layout.secondary)}
                </Box>
                <Text
                  bold
                  mt={[0, 2]}
                  p={[0, 0, 2]}
                  fontSize="md"
                  textAlign="center"
                  opacity={isPublished ? 1 : 60}
                  color={defaultTextColors.default}
                >
                  {service.name}
                </Text>
              </Flex>
            </Pressable>
          );
        })}
    </ScrollView>
  );
};

export default Home;
