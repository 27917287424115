import React from 'react';
import { useRecoilValue } from 'recoil';
import { Flex, Text } from 'native-base';
import SelectBox from 'react-native-multi-selectbox';
import { Platform } from 'react-native';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { defaultTextColors } from '../../utilites/utilities';

const FormSelect = ({
  label,
  value,
  loading,
  options,
  setValue,
  loadingPlaceholder,
  isMulti = false,
  selectedValues = [],
  onMultiSelect = () => {},
}) => {
  const colors = useRecoilValue(themeAtom).layout;

  return (
    <Flex flexDirection="column" my={2}>
      <Text fontSize="md" color="rgba(44, 45, 73, 0.4)" fontWeight="semibold">
        {label}
      </Text>
      <SelectBox
        label=""
        value={value}
        isMulti={isMulti}
        options={options}
        onChange={setValue}
        hideInputFilter={false}
        onTapClose={onMultiSelect}
        onMultiSelect={onMultiSelect}
        selectedValues={selectedValues}
        arrowIconColor={colors.bgSecondary}
        searchIconColor={colors.bgSecondary}
        toggleIconColor={colors.bgSecondary}
        listEmptyText="Nenhuma atividade encontrada"
        inputPlaceholder={loading ? loadingPlaceholder : 'Selecionar'}
        multiOptionContainerStyle={{
          borderColor: '#e5e5e5',
          color: defaultTextColors.secondary,
          backgroundColor: colors.bgSecondary,
        }}
        containerStyle={{
          padding: 10,
          borderWidth: 1,
          borderRadius: 20,
          alignItems: 'center',
          borderColor: '#e5e5e5',
          backgroundColor: '#FFF',
          marginTop: Platform.OS === 'web' ? 0 : -12,
        }}
        multiListEmptyLabelStyle={{
          fontWeight: '600',
          fontSize: 16,
          color: defaultTextColors.secondary,
        }}
        selectedItemStyle={{
          fontSize: 16,
          maxWidth: '65%',
          fontWeight: '600',
          color: defaultTextColors.secondary,
        }}
        optionContainerStyle={{
          padding: 8,
          marginTop: 4,
          borderRadius: 10,
          backgroundColor: '#FFF',
        }}
      />
    </Flex>
  );
};

export default FormSelect;
