import Echo from 'laravel-echo';
import pusher from './pusher';

const notificationCountListener = (userId, userToken, callback) => {
  const echo = new Echo({
    client: pusher(userToken),
    broadcaster: 'pusher',
  });

  echo
    .private(`user.${userId}`)
    .listen(
      '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
      callback
    );
};

export default notificationCountListener;
