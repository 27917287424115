import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Center } from 'native-base';
import { useRecoilState } from 'recoil';
import CircleSnail from '../../components/Global/Loading';
import SimpleRequestHandler from '../../services/meishop';
import { magicLinkAtom } from '../../Recoil/Atoms/magicLink';
import { showToastMessage } from '../../utilites/ToastProvider';

const MagicLink = ({ navigation, logUser, route }) => {
  const token = get(route, 'params.token', null);

  const [loading, setLoading] = useState(false);
  const [usedMagicLink, setUsedMagicLink] = useRecoilState(magicLinkAtom);

  const handleTokenError = () => {
    showToastMessage('Erro', 'Token inválido ou não encontrado.', 'error');
    navigation.navigate('Login');
  };

  const doLogin = async () => {
    if (!usedMagicLink) {
      setUsedMagicLink(true);
      SimpleRequestHandler(`magiclink/${token}`, 'GET')
        .then(({ data }) => {
          const userData = get(data, 'data', null);
          if (userData) {
            logUser(userData);
          }
        })
        .catch(() => {
          handleTokenError();
        });
    }
  };

  useEffect(async () => {
    if (loading) {
      if (!token) {
        handleTokenError();
      } else {
        await doLogin();
      }
    } else {
      setLoading(true);
    }
  }, [loading]);

  return (
    <Center
      top={0}
      left={0}
      w="full"
      h="full"
      zIndex={80}
      position="absolute"
      backgroundColor={{
        linearGradient: {
          colors: ['#463681', '#60519a'],
          start: [0, 0],
          end: [0, 1],
        },
      }}
    >
      <CircleSnail
        size={80}
        color="#FFF"
        thickness={4}
        fill="rgba(0, 0, 0, 0)"
      />
    </Center>
  );
};

export default MagicLink;
