import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Flex, ScrollView, Text } from 'native-base';
import { find, get, head } from 'lodash';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../../utilites/utilities';
import ServiceIcon from '../../../utilites/ServicesIcon';
import { themeAtom } from '../../../Recoil/Atoms/theme';
import SimpleRequestHandler from '../../../services/meishop';
import { formalizationAtom } from '../../../Recoil/Atoms/formalization';

const Welcome = ({ handleNextState, setCurrentState }) => {
  const colors = useRecoilValue(themeAtom).layout;
  const [formalization, setFormalization] = useRecoilState(formalizationAtom);

  const requiredDocuments = [
    'R.G',
    'CPF',
    'Titulo de eleitor',
    'Comprovante de Endereço da Empresa e da Residência',
  ];

  const stepToEnum = {
    new: -1,
    welcome: 0,
    initial: 1,
    credentials: 2,
    company_data: 3,
    personal_data: 4,
    address: 5,
    confirmation: 6,
    success: 7,
  };

  useEffect(async () => {
    let activities = [];
    let methods = [];
    await SimpleRequestHandler('formalizacao/atividades-mei').then(
      ({ data }) => {
        const fixedData = [];
        data.map((item) => {
          fixedData.push({
            id: item.id,
            item: item.nome,
          });
        });
        activities = fixedData;
      }
    );
    await SimpleRequestHandler('formalizacao/formas-atuacao').then(
      ({ data }) => {
        const fixedData = [];
        data.map((item) => {
          fixedData.push({
            id: item.id,
            item: item.titulo,
          });
        });
        methods = fixedData;
      }
    );
    await SimpleRequestHandler('formalizacao').then(({ data }) => {
      const dataDictionary = {
        ...formalization,
        id: get(data, 'formalizacao.id', 0),
        status: get(
          data,
          'formalizacao.user_progress_status',
          'initial'
        ).toLowerCase(),
        credentials: {
          nome: get(data, 'personal_info.nome', ''),
          data_nascimento: get(data, 'personal_info.data_nascimento', ''),
          nome_mae: get(data, 'personal_info.nome_mae', ''),
          nome_pai: get(data, 'personal_info.nome_pai', ''),
          senha: get(data, 'sso_access.senha', ''),
          requer_criacao: get(data, 'sso_access.requer_criacao', '') !== 0,
          email: get(data, 'personal_info.email', ''),
          numero_telefone: get(data, 'personal_info.numero_telefone', ''),
        },
        'company-data': {
          capital: get(data, 'company_details.capital', 0),
          nome_empresarial: get(data, 'company_details.nome_empresarial', ''),
        },
        'personal-data': {
          cpf_numero: get(data, 'documents.cpf_numero', ''),
          rg_numero: get(data, 'documents.rg_numero', ''),
          rg_uf: get(data, 'documents.rg_uf', ''),
          rg_emissor: get(data, 'documents.rg_emissor', ''),
          titulo_de_eleitor: get(data, 'documents.titulo_de_eleitor', ''),
        },
        address: {},
        availableMethods: methods,
        availableActivities: activities,
      };
      const companyMethods = get(data, 'atuacoes_mei', []);
      if (companyMethods) {
        dataDictionary['company-data'].formas_atuacao_mei = get(
          data,
          'atuacoes_mei',
          []
        ).map((item) => item.forma_atuacao_mei_id);
      }
      const companyActivities = get(data, 'atividades_mei', []);
      const principal_activity = head(companyActivities);
      if (principal_activity) {
        dataDictionary['company-data'].atividade_principal_mei =
          principal_activity.atividade_mei_id;
      }
      if (companyActivities && companyActivities.length >= 2) {
        dataDictionary['company-data'].atividade_secundaria_mei =
          companyActivities[1].atividade_mei_id;
      }
      const companyAddress = get(data, 'addresses', []);
      const personalAddress = find(companyAddress, ['is_comercial', 0]);
      const businessAddress = find(companyAddress, ['is_comercial', 1]);
      if (businessAddress) {
        dataDictionary.address = {
          ...personalAddress,
          possui_endereco_comercial: true,
          comercial_cep: businessAddress.cep,
          comercial_bairro: businessAddress.bairro,
          comercial_complemento: businessAddress.complemento,
          comercial_logradouro: businessAddress.logradouro,
          comercial_numero: businessAddress.numero,
          comercial_uf_sigla: businessAddress.uf_sigla,
          comercial_municipio_nome: businessAddress.municipio_nome,
        };
      } else if (personalAddress) {
        dataDictionary.address = personalAddress;
      }
      setFormalization(dataDictionary);
      setCurrentState(
        stepToEnum[
          get(data, 'formalizacao.user_progress_status', 'new').toLowerCase()
        ] + 1
      );
    });
  }, []);

  return (
    <ScrollView
      px={[4, 8, 16, 20]}
      _contentContainerStyle={{
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      <Text
        fontSize="2xl"
        textAlign="center"
        fontWeight="semibold"
        color={defaultTextColors.default}
      >
        Abra seu MEI
      </Text>
      <Text
        mt={4}
        fontSize="md"
        lineHeight="sm"
        fontWeight="medium"
        color={defaultTextColors.default}
      >
        No Meu Negócio, você tem acesso ao seu número de CNPJ rapidinho e de
        maneira totalmente segura, sem o risco de golpes ou cobranças indevidas.
      </Text>
      <Text
        mt={4}
        fontSize="md"
        lineHeight="sm"
        fontWeight="medium"
        color={defaultTextColors.default}
      >
        Antes de iniciar o procedimento, tenha os seguintes documentos em mãos:
      </Text>
      <Flex mt={4} flexDirection="column">
        {requiredDocuments.map((document, key) => (
          <Flex flexDirection="row" alignItems="center" key={key.toString()}>
            {ServiceIcon('check-circle', 18, colors.bgSecondary)}
            <Text
              my={1}
              ml={2}
              fontSize="md"
              fontWeight="medium"
              color={defaultTextColors.default}
            >
              {document}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Text
        mt={4}
        fontSize="md"
        lineHeight="sm"
        fontWeight="medium"
        color={defaultTextColors.default}
      >
        Pronto! Agora, já podemos ir para o próximo passo e você estará uma
        etapa mais perto de se tornar um microempreendedor individual
        #VamosJuntos
      </Text>
      <Button
        py={3}
        px={5}
        mb={4}
        mt={6}
        mx="auto"
        fontSize="md"
        rounded="full"
        w={['full', 'full', 'full', '1/3']}
        bgColor={defaultComponentsColors.warning}
        onPress={handleNextState}
      >
        <Text fontSize="md" fontWeight="medium" color="#FFF">
          Começar
        </Text>
      </Button>
    </ScrollView>
  );
};

export default Welcome;
