import React from 'react';
import { Flex, Heading, Pressable } from 'native-base';
import { Platform } from 'react-native';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';
import Button from '../Buttons/Button';
import ServicesIcon from '../../utilites/ServicesIcon';
import CompanyBadge from '../Company/CompanyBadge';

const ScreenHeader = ({
  title,
  navigation,
  icon = null,
  home = false,
  iconSize = 28,
  titleSize = '2xl',
  badgeStatus = '',
  buttonText = '',
  buttonAction = () => {},
}) => {
  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Dashboard');
    }
  };

  return (
    <Flex
      px={4}
      pb={2}
      w="full"
      alignItems="center"
      flexDirection="row"
      borderTopRadius={20}
      backgroundColor="#f0f2f6"
      justifyContent="space-between"
      pt={Platform.OS === 'web' ? [0, 0, 0, 4] : 4}
    >
      {!home && (
        <Pressable
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          onPress={handleGoBack}
          w={badgeStatus !== '' ? '4/5' : buttonText ? '3/5' : 'full'}
        >
          {ServicesIcon('left', iconSize, defaultTextColors.default)}
          <Heading
            ml={2}
            w="full"
            isTruncated
            flexShrink={1}
            fontSize={titleSize}
            color={defaultTextColors.default}
          >
            {title}
          </Heading>
        </Pressable>
      )}
      {badgeStatus !== '' && (
        <CompanyBadge
          w="1/5"
          px={[4, 4, 4, 6]}
          py={[1, 1, 1, 2]}
          companyStatus={badgeStatus}
        />
      )}
      {buttonText !== '' && (
        <Button
          py={1}
          flex={1}
          icon={icon}
          text={buttonText}
          px={[2, 2, 4, 4]}
          action={buttonAction}
          alignItems="flex-end"
          mr={[0, 0, 12, 0, 'auto']}
          fontSize={['sm', 'sm', 'md', 'md']}
          textColor={defaultTextColors.success}
          w={['auto', 'auto', 'auto', '80%', '60%', '40%']}
          borderColor={defaultComponentsColors.success}
        />
      )}
    </Flex>
  );
};

export default ScreenHeader;
