import React from 'react';
import {
  SENTRY_DNS,
  // eslint-disable-next-line
} from '@env';
import { RecoilRoot } from 'recoil';
import 'react-native-gesture-handler';
import { Provider } from 'react-redux';
import Toast from 'react-native-toast-message';
import { extendTheme, NativeBaseProvider } from 'native-base';
import { LinearGradient } from 'expo-linear-gradient';
import { NotifierWrapper } from 'react-native-notifier';
import { NavigationContainer } from '@react-navigation/native';
import { registerTranslation } from 'react-native-paper-dates';
import Router from './src/navigation/Router';
import store from './src/redux/Store';
import { get } from 'lodash';
import * as Sentry from 'sentry-expo';

Sentry.init({
  dsn: SENTRY_DNS,
  enableInExpoDevelopment: true,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'console') {
      return null
    }
    return breadcrumb
  },
});

if (typeof Intl === 'undefined') {
  require('intl')
  require('intl/locale-data/jsonp/pt-BR')
}

registerTranslation('pl', {
  save: 'Salvar',
  selectSingle: 'Escolher data',
  selectMultiple: 'Escolher datas',
  selectRange: 'Selecionar periodo',
  notAccordingToDateFormat: (inputFormat) =>
    `O formato da data perecisa ser ${inputFormat}`,
  mustBeHigherThan: 'Precisa ser depois de',
  mustBeLowerThan: 'Precisa ser antes de',
  mustBeBetween: 'Precisa ser entre',
  dateIsDisabled: 'Dia não está habilitado',
});

const linkingConfig = {
  screens: {
    Login: 'entrar',
    Register: 'cadastro',
    profile: 'meus_dados',
    solutions: 'solucoes',
    tips_for_you: 'dicas',
    Magiclink: 'magiclink',
    Dashboard: 'dashboard',
    tips_post: 'dicas/post',
    tips: 'dicas/:category',
    company: 'minha-empresa',
    boleto_das: 'boleto-das',
    VerifyEmail: 'verify-email',
    company_cnpj: 'dados_cnpj',
    Redefine: 'reset-password',
    Recovery: 'recuperar_senha',
    change_password: 'alterar_senha',
    company_add: 'adicionar_empresa',
    company_confirm: 'confirma_empresa',
    declaracao_dasn: 'declaracao_dasn',
    dasn_declaration: 'declarar_dasn',
    'company_cnpj/:id': 'minha-empresa/cnpj',
  },
};

// Convert route name to page title
const pageTitles = {
  Login: 'Entrar',
  Dashboard: 'Dashboard',
  profile: 'Meus Dados',
  Magiclink: 'Link Mágico',
  change_password: 'Alterar senha',
  solutions: 'Soluções para o MEI',
  tips_for_you: 'Dicas para o MEI',
  tips_post: 'Dicas para o MEI',
  tips: 'Dicas para o MEI',
  company: 'Minha Empresa',
  boleto_das: 'Boleto DAS',
  company_cnpj: 'Cartão CNPJ',
  company_add: 'Adicionar Empresa',
  declaracao_dasn: 'Declaracão DASN',
  dasn_declaration: 'Declarar DASN',
  company_confirm: 'Adicionar Empresa',
}

function App() {
  // Native base provider config for linear gradient
  const config = {
    dependencies: {
      'linear-gradient': LinearGradient,
    },
  };
  const linking = {
    prefixes: ['https://localhost:19006/', 'meu-negocio://'],
    config: linkingConfig,
  };

  const theme = extendTheme({
    components: {
      Radio: {
        baseStyle: {
          _icon: {
            color: '#60519a'
          },
          borderColor: '#60519a',
          _checked: {
            _interactionBox: {
              borderColor: '#60519a',
            },
            borderColor: '#60519a',
          }
        }
      }
    }
  })

  return (
    <RecoilRoot>
      <Provider store={store}>
        <NavigationContainer
          linking={linking}
          documentTitle={{
            formatter: (options, route) =>
              `Meu Negócio${route?.name ? ` - ${get(pageTitles, route.name, '')}` : ''}`,
          }}
        >
          <NativeBaseProvider theme={theme} config={config}>
            <NotifierWrapper>
              <Router />
              <Toast />
            </NotifierWrapper>
          </NativeBaseProvider>
        </NavigationContainer>
      </Provider>
    </RecoilRoot>
  );
}

export default App;
