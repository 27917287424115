import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Box, Button, Flex, Input, Pressable, Text } from 'native-base';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import { validateBr } from 'js-brasil';
import { userAtom } from '../../Recoil/Atoms/user';
import CompanyDetailRow from '../../components/Company/CompanyDetailRow';
import {
  defaultComponentsColors,
  defaultTextColors,
  validateUserAge,
} from '../../utilites/utilities';
import ServiceIcon from '../../utilites/ServicesIcon';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { showToastMessage } from '../../utilites/ToastProvider';
import SimpleRequestHandler, {
  requestErrorHandler,
} from '../../services/meishop';
import Container from '../../components/Container';

const Profile = () => {
  const colors = useRecoilValue(themeAtom).layout;
  const [user, setUser] = useRecoilState(userAtom);
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [dataChanged, setDataChanged] = useState(false);
  const [cpf, setCpf] = useState(get(user, 'cpf', ''));
  const [name, setName] = useState(get(user, 'name', ''));
  const [email, setEmail] = useState(get(user, 'email', ''));
  const [date, setDate] = useState(new Date(get(user, 'date_of_birth', '')));
  const paperTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: colors.bgSecondary,
      background: '#f0f2f6',
    },
  };

  const onDismissSingle = useCallback(() => {
    setOpenDate(false);
  }, [setOpenDate]);

  const onConfirmSingle = useCallback(
    (params) => {
      setDataChanged(true);
      setDate(params.date);
      setOpenDate(false);
    },
    [setOpenDate, setDate]
  );

  const handleButton = () => {
    if (editing) {
      if (cpf === '' || name === '' || dateOfBirth === '') {
        showToastMessage(
          'Campos em branco',
          'Todos os campos devem ser preenchidos.',
          'warn'
        );
        return;
      }
      if (!cpfValidator.isValid(cpf)) {
        showToastMessage(
          'CPF inválido',
          'O CPF informado não é inválido.',
          'warn'
        );
        return;
      }
      const splitedDate = dateOfBirth.split('/');
      if (!validateUserAge(splitedDate[2])) {
        showToastMessage(
          'Aviso',
          'Você deve possuir no minimo 16 anos para utilizar nossos serviços.',
          'warn'
        );
        return;
      }
      if (!validateBr.email(email)) {
        showToastMessage(
          'Email inválido',
          'O Email informado não é inválido.',
          'warn'
        );
        return;
      }
      setLoading(true);
      SimpleRequestHandler(`user/profile-information`, 'PUT', {
        cpf,
        name,
        email,
        date_of_birth: `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`,
      })
        .then(() => {
          setLoading(false);
          setEditing(false);
          showToastMessage(
            'Sucesso',
            'Seus dados foram atualizados.',
            'success'
          );
          setUser({
            ...user,
            name,
            cpf,
            date_of_birth: dateOfBirth,
          });
        })
        .catch((error) => {
          showToastMessage('Erro', requestErrorHandler(error), 'error');
          setLoading(false);
        });
    } else {
      setEditing(true);
    }
  };

  useEffect(() => {
    if (user.date_of_birth !== '') {
      if (dataChanged) {
        setDateOfBirth(new Intl.DateTimeFormat('pt-BR').format(date));
      } else {
        setDateOfBirth(
          new Intl.DateTimeFormat('pt-BR').format(
            date.setDate(date.getDate() + 1)
          )
        );
      }
    }
  }, [date]);

  return (
    <Container
      width="100%"
      justifyContent="flex-start"
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Flex mt={2} bgColor="#FFF" p={4} borderRadius="2xl">
        <Flex flexDirection="column">
          <CompanyDetailRow
            noDivisor
            label="Nome"
            value={name}
            setValue={setName}
            editing={editing}
          />
          <CompanyDetailRow
            noDivisor
            label="CPF"
            setValue={setCpf}
            editing={editing}
            value={cpfValidator.format(cpf)}
          />
          <CompanyDetailRow
            noDivisor
            label="Email"
            value={email}
            editing={editing}
            setValue={setEmail}
          />
          <PaperProvider theme={paperTheme}>
            <DatePickerModal
              date={date}
              mode="single"
              locale="pt-BR"
              visible={openDate}
              saveLabel="Continuar"
              label="Data de nascimento"
              onDismiss={onDismissSingle}
              onConfirm={onConfirmSingle}
              validRange={{
                endDate: new Date(),
              }}
            />
          </PaperProvider>
          <CompanyDetailRow label="Data de nascimento" noDivisor />
          <Pressable
            mr={2}
            onPress={() => {
              if (editing) {
                setOpenDate(true);
              }
            }}
          >
            <Input
              mt={-2}
              pl={4}
              size="lg"
              variant="rounded"
              value={dateOfBirth}
              isReadOnly={!editing}
              placeholder="Data de nascimento"
              onChange={() => {}}
              onFocus={() => {
                if (editing) {
                  setOpenDate(true);
                }
              }}
              InputRightElement={
                <Box mr={2}>
                  {ServiceIcon('calendar', 24, defaultTextColors.default)}
                </Box>
              }
            />
          </Pressable>
        </Flex>
      </Flex>
      <Button
        mt={6}
        py={2}
        rounded="full"
        isLoading={loading}
        onPress={handleButton}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="lg" fontWeight="semibold" color="#FFF">
          {/* eslint-disable-next-line no-nested-ternary */}
          {editing ? (loading ? 'Salvando dados...' : 'Salvar') : 'Editar'}
        </Text>
      </Button>
    </Container>
  );
};

export default Profile;
