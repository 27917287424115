import React from 'react';
import { Pressable, Box, Text, Spinner, Flex, Center } from 'native-base';

const Button = (props) => {
  const {
    px,
    py,
    text,
    action,
    textColor,
    mt = 0,
    mr = 0,
    ml = 0,
    flex = 0,
    icon = null,
    w = '100%',
    fontSize = 'xs',
    requestState = 'done',
    fontWeight = 'normal',
    bgColor = 'transparent',
    isRequestButton = false,
    alignItems = 'flex-start',
    justifyContent = 'center',
    borderColor = 'transparent',
  } = props;
  return (
    <Pressable
      p={0}
      mt={mt}
      mr={mr}
      ml={ml}
      flex={flex}
      display="flex"
      onPress={action}
      variant="unstyled"
      alignItems={alignItems}
    >
      <Box
        w={w}
        py={py}
        px={px}
        flex={flex}
        display="flex"
        bgColor={bgColor}
        borderRadius="full"
        borderColor={borderColor}
        justifyContent={justifyContent}
        borderWidth={borderColor !== '' ? 1 : ''}
      >
        {isRequestButton && requestState === 'loading' ? (
          <Spinner
            color={textColor}
            textAlign="center"
            accessibilityLabel="Carregando serviços"
          />
        ) : (
          <Flex flexDirection="row">
            {icon && <Center mr={2}>{icon}</Center>}
            <Text
              mx="auto"
              color={textColor}
              fontSize={fontSize}
              textAlign="center"
              fontWeight={fontWeight}
            >
              {text}
            </Text>
          </Flex>
        )}
      </Box>
    </Pressable>
  );
};

export default Button;
