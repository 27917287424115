import React, { useState } from 'react';
import { Button, Flex, ScrollView, Text } from 'native-base';
import { validateBr } from 'js-brasil';
import { get } from 'lodash';
import { osName } from 'expo-device';
import { Platform } from 'react-native';
import Logo from '../../assets/LogoAlt';
import {
  ampLogEvent,
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';
import FormInput from './FormInput';
import FormPasswordInput from './FormPasswordInput';
import CircleSnail from './Loading';
import SimpleRequestHandler, {
  requestErrorHandler,
} from '../../services/meishop';

const LoginForm = ({ navigation, logUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);

  const handleLogin = () => {
    if (isValidEmail) {
      setLoading(true);
      setErrorMessage([]);
      SimpleRequestHandler('login', 'POST', {
        email,
        password,
        device_name: osName || 'Unidentified',
      })
        .then(async ({ data }) => {
          await ampLogEvent(null, 'Login - Success', {});
          const userData = get(data, 'data', null);
          if (userData) {
            logUser(userData);
          }
        })
        .catch(async (error) => {
          setLoading(false);
          setErrorMessage(requestErrorHandler(error).split(' <br /> '));
          await ampLogEvent(null, 'Login - Error', {
            error: requestErrorHandler(error).split(' <br /> '),
          });
        });
    }
  };

  return (
    <ScrollView
      zIndex={80}
      bgColor="#FFF"
      p={[4, 4, 4, 8]}
      borderRadius="3xl"
      top={[4, 4, 4, 0]}
      left={[4, 4, 4, 0]}
      right={[4, 4, 4, 0]}
      bottom={[4, 4, 4, 0]}
      ml={[0, 0, 0, 32, 64]}
      flexDirection="column"
      mt={Platform.OS === 'ios' ? 10 : 0}
      w={['auto', 'auto', 'auto', '30%']}
      h={['auto', 'auto', 'auto', '5/6']}
      contentContainerStyle={{
        alignItems: 'center',
        justifyContent: 'space-evenly',
      }}
      position={['absolute', 'absolute', 'absolute', 'relative']}
    >
      <Logo />
      <Flex
        mt={4}
        flexDirection="column"
        alignItems={['center', 'center', 'center', 'flex-start']}
      >
        <Text
          w="full"
          fontSize="2xl"
          lineHeight="sm"
          fontWeight="bold"
          color={defaultTextColors.default}
          textAlign={['center', 'center', 'center', 'left']}
        >
          Boas-vindas ao Meu Negócio
        </Text>
        <Text
          mt={2}
          w="full"
          opacity={60}
          fontSize="md"
          textAlign="left"
          fontWeight="medium"
          color={defaultTextColors.default}
        >
          Tudo o que você precisa para a sua MEI está aqui.
        </Text>
        <Text
          w="full"
          opacity={60}
          fontSize="md"
          textAlign="left"
          fontWeight="medium"
          color={defaultTextColors.default}
        >
          Acesse sua conta.
        </Text>
      </Flex>
      <Flex mt={4} w="full" flexDirection="column">
        <FormInput
          w="full"
          label="Email"
          value={email}
          validate
          setValue={setEmail}
          isValid={isValidEmail}
          setIsValid={setIsValidEmail}
          validation={validateBr.email}
        />
        <FormPasswordInput
          w="full"
          label="Senha"
          value={password}
          setValue={setPassword}
        />
        <Text
          ml="auto"
          fontSize="xs"
          textAlign="right"
          color="rgba(44, 45, 73, 0.4)"
          onPress={() => navigation.navigate('Recovery')}
        >
          Esqueceu a senha?
        </Text>
      </Flex>
      <Flex mt={[0, 0, 0, 6]} flexDirection="column" w="full">
        {errorMessage.length >= 1 && (
          <Flex flexDirection="column">
            {errorMessage.map((message) => (
              <Text
                my={1}
                opacity={60}
                fontSize="md"
                lineHeight="sm"
                textAlign="center"
                fontWeight="medium"
                color={defaultTextColors.error}
              >
                {message}
              </Text>
            ))}
          </Flex>
        )}
        <Button
          py={3}
          px={5}
          w="full"
          fontSize="md"
          rounded="full"
          onPress={handleLogin}
          bgColor={defaultComponentsColors.warning}
          mt={errorMessage.length >= 1 ? 4 : [4, 4, 4, 0]}
        >
          {loading ? (
            <CircleSnail
              size={25}
              color="#FFF"
              thickness={2}
              fill="rgba(0, 0, 0, 0)"
            />
          ) : (
            <Text fontSize="md" fontWeight="medium" color="#FFF">
              Entrar
            </Text>
          )}
        </Button>
        <Text
          px={2}
          w="full"
          fontSize="md"
          textAlign="center"
          mt={[4, 4, 4, 8]}
          color="rgba(44, 45, 73, 0.4)"
        >
          Ainda nao tem uma conta?{' '}
          <Text
            fontSize="md"
            color="#007fc6"
            onPress={() => navigation.navigate('Register')}
          >
            Cadastre-se
          </Text>
        </Text>
      </Flex>
    </ScrollView>
  );
};

export default LoginForm;
