import React from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Flex, Pressable, Text } from 'native-base';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { defaultTextColors } from '../../utilites/utilities';
import ServiceIcon from '../../utilites/ServicesIcon';
import SimpleRequestHandler from '../../services/meishop';

const NotificationsHeader = ({
  notifications,
  requestNotifications,
  setLoading,
}) => {
  const colors = useRecoilValue(themeAtom).layout;

  const markAllAsRead = () => {
    setLoading(true);
    SimpleRequestHandler('notifications/mark-all-as-read', 'POST').then(() =>
      requestNotifications()
    );
  };

  return (
    <Flex flexDirection="row" justifyContent="space-between">
      <Box py={2} px={3} bg={colors.bgPrimary} opacity={80} rounded="full">
        <Text color="#FFF" fontWeight="bold">
          {notifications.counter}
          {`${notifications.counter > 1 ? ' mensagens' : ' mensagem'}`}
        </Text>
      </Box>
      <Pressable
        flexDirection="row"
        alignItems="center"
        onPress={markAllAsRead}
      >
        <Text
          mr={2}
          opacity={40}
          fontSize="sm"
          fontWeight="semibold"
          color={defaultTextColors.default}
        >
          Marcar todas como lida
        </Text>
        {ServiceIcon('check-circle', 18, colors.bgSecondary)}
      </Pressable>
    </Flex>
  );
};

export default NotificationsHeader;
