import React, { useEffect } from 'react';
import { Flex, Image } from 'native-base';
import { ampLogEvent } from '../../utilites/utilities';
import Background from '../../assets/Login/login1.jpg';
import RegisterForm from '../../components/Global/RegisterForm';
import { get } from 'lodash';

const Register = ({ route, navigation, logUser }) => {
  const origem = get(route, 'params.origem', null);

  useEffect(() => {
    ampLogEvent(null, 'View - Register', {});
  }, []);

  return (
    <Flex
      backgroundColor={{
        linearGradient: {
          colors: ['#463681', '#60519a'],
          start: [0, 0],
          end: [0, 1],
        },
      }}
      h="100%"
      w="100%"
      flex={1}
      py={[0, 0, 0, 10]}
      position="absolute"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Image
        left={0}
        source={Background}
        position="absolute"
        alt="login background"
        width={[0, 0, 0, '55%']}
        defaultSource={Background}
        height={[0, 0, 0, '100%']}
      />
      <RegisterForm origem={origem} logUser={logUser} navigation={navigation} />
    </Flex>
  );
};

export default Register;
