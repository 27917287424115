import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Checkbox, Flex, ScrollView, Text } from 'native-base';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../../utilites/utilities';
import { showToastMessage } from '../../../utilites/ToastProvider';
import SimpleRequestHandler from '../../../services/meishop';
import { formalizationAtom } from '../../../Recoil/Atoms/formalization';

const Initial = ({ handleNextState }) => {
  const { initial } = useRecoilValue(formalizationAtom);
  const [formalization, setFormalization] = useRecoilState(formalizationAtom);

  const [govAgree, setGovAgree] = useState(initial.termos_de_acesso);
  const [termsOfUse, setTermsOfUse] = useState(initial.termos_de_uso);

  const handleGovAgree = (value) => {
    setGovAgree(value);
  };

  const handleTermsOfUseAgree = (value) => {
    setTermsOfUse(value);
  };

  const handleSubmit = () => {
    if (!govAgree || !termsOfUse) {
      showToastMessage(
        'Termos e Acesso.gov',
        'Você precisa aceitar os termos de uso e a permissão para o portal do governo.',
        'warn'
      );
      return;
    }
    SimpleRequestHandler('formalizacao', 'POST', {
      termos_de_uso: termsOfUse,
      termos_de_acesso: govAgree,
    }).then(({ data }) => {
      setFormalization({
        ...formalization,
        id: data.formalizacao_id,
        status: data.user_progress_status.toLowerCase(),
      });
      handleNextState();
    });
  };

  return (
    <ScrollView
      px={[4, 8, 16, 20]}
      _contentContainerStyle={{
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      <Text
        fontSize="2xl"
        textAlign="center"
        fontWeight="semibold"
        color={defaultTextColors.default}
      >
        VAMOS COMEÇAR?
      </Text>
      <Text
        mt={4}
        fontSize="md"
        lineHeight="sm"
        fontWeight="medium"
        color={defaultTextColors.default}
      >
        Todo o processo de formalização, ou seja, de abertura da empresa MEI,
        acontece no Portal Gov.br. Esse é o canal oficial do governo federal, o
        único Portal do Empreendedor seguro para que você obtenha o seu número
        de CNPJ.
      </Text>
      <Text
        mt={4}
        fontSize="md"
        fontWeight="medium"
        color={defaultTextColors.default}
      >
        Para que possamos te ajudar a abrir a sua empresa de forma segura e
        totalmente dentro da legalidade, o Meu Negócio precisa que você
        compartilhe seus dados de login do Gov.br, que também é usado para
        outras funcionalidades, como o ConecteSUS.
      </Text>
      <Flex mt={6} flexDirection="row" alignItems="center">
        <Checkbox
          size="md"
          value={govAgree}
          colorScheme="cyan"
          onChange={handleGovAgree}
          accessibilityLabel="Permissão para validar dados no portal do governo"
        />
        <Text
          ml={2}
          fontSize="md"
          fontWeight="medium"
          color={defaultTextColors.default}
        >
          Autorizo o MEiShop a validar as informações no Portal do Governo
          (gov.br) e declaro que as informações preenchidas por mim são
          verdadeiras
        </Text>
      </Flex>
      <Flex mt={6} flexDirection="row" alignItems="center">
        <Checkbox
          size="md"
          value={termsOfUse}
          colorScheme="cyan"
          onChange={handleTermsOfUseAgree}
          accessibilityLabel="Aceito os termos de uso do MeiShop"
        />
        <Text
          ml={2}
          fontSize="md"
          fontWeight="medium"
          color={defaultTextColors.default}
        >
          Declaro estar de acordo com os Termos & Condições.
        </Text>
      </Flex>
      <Button
        py={3}
        px={5}
        mb={4}
        mt={6}
        mx="auto"
        fontSize="md"
        rounded="full"
        onPress={handleSubmit}
        w={['full', 'full', 'full', '1/3']}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="md" fontWeight="medium" color="#FFF">
          Continuar
        </Text>
      </Button>
    </ScrollView>
  );
};

export default Initial;
