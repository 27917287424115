import React from 'react';
import { Flex } from 'native-base';

const Container = (props) => {
  const { children } = props;
  return (
    <Flex
      px={4}
      flex={1}
      flexWrap="wrap"
      flexDirection="row"
      justifyContent={[
        'space-around',
        'space-around',
        'space-around',
        'flex-start',
      ]}
      {...props}
    >
      {children}
    </Flex>
  );
};

export default Container;
