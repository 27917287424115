import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { get } from 'lodash';
import { Center, Spinner } from 'native-base';
import NoCompany from '../components/Global/NoCompany';
import { userCompaniesAtom } from '../Recoil/Atoms/userCompanies';
import SimpleRequestHandler from '../services/meishop';
import { viewingCompanyAtom } from '../Recoil/Atoms/viewingCompany';
import { selectedCompanyAtom } from '../Recoil/Atoms/selectedCompany';
import { themeAtom } from '../Recoil/Atoms/theme';

const CompanyNeedRoute = ({ children, px = 0 }) => {
  const colors = useRecoilValue(themeAtom).layout;
  const [loading, setLoading] = useState(true);
  const setViewingCompany = useSetRecoilState(viewingCompanyAtom);
  const setSelectedCompany = useSetRecoilState(selectedCompanyAtom);
  const [userCompanies, setUserCompanies] = useRecoilState(userCompaniesAtom);

  const verifyCompanies = () => {
    SimpleRequestHandler('company', 'GET')
      .then((companyResponse) => {
        const userCompaniesResponse = get(companyResponse.data, 'data', []);
        setUserCompanies(userCompaniesResponse);
        setViewingCompany(userCompaniesResponse[0]);
        setSelectedCompany(userCompaniesResponse[0]);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userCompanies.length === 0) {
      verifyCompanies();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <Center mt={24}>
        <Spinner
          size={40}
          mx="auto"
          color={colors.bgSecondary}
          accessibilityLabel="Carregando empresas"
        />
      </Center>
    );
  }

  if (userCompanies.length > 0) {
    return children;
  }

  return <NoCompany px={px} />;
};

export default CompanyNeedRoute;
