import React, { useEffect, useState } from 'react';
import { Flex, Input, Pressable, Text } from 'native-base';
import { ampLogEvent, defaultTextColors } from '../../utilites/utilities';
import ServicesIcon from '../../utilites/ServicesIcon';

const FormPasswordInput = ({
  value,
  label,
  setValue,
  isValid,
  pr = 0,
  w = 'full',
  onBlur = () => {},
  onFocus = () => {},
  setIsValid = () => {},
  logAmp = false,
  validation = () => false,
  validate = false,
  comparePassword = null,
  customErroMessage = null,
}) => {
  const [show, setShow] = useState(false);
  const [startValidation, setStartValidation] = useState(false);

  const validationColors = {
    true: defaultTextColors.success,
    false: defaultTextColors.error,
  };

  const doValidation = (text) => {
    setStartValidation(true);
    if (validate) {
      setIsValid(validation(text));
    } else {
      setIsValid(true);
    }
  };

  const handleInputChange = (text) => {
    setValue(text);
    if (startValidation) {
      doValidation(text);
    }
  };

  const logAmplitudeEvent = async () => {
    await ampLogEvent(null, 'Register - Field Fill', {
      field: label,
      valid: validation(value),
    });
  };

  useEffect(() => {
    if (comparePassword && startValidation) {
      doValidation(value);
    }
  }, [comparePassword]);

  return (
    <Flex w={w} flexDirection="column" my={2} pr={pr}>
      <Text fontSize="md" color="rgba(44, 45, 73, 0.4)" fontWeight="semibold">
        {label}
      </Text>
      <Input
        mt={1}
        shadow={2}
        fontSize="md"
        value={value}
        bgColor="#FFF"
        onFocus={onFocus}
        variant="rounded"
        fontWeight="semibold"
        type={show ? 'text' : 'password'}
        color={defaultTextColors.secondary}
        onChangeText={handleInputChange}
        borderColor={startValidation ? validationColors[isValid] : '#d4d4d4'}
        onBlur={async (params) => {
          onBlur(params);
          doValidation(value);
          if (logAmp) {
            await logAmplitudeEvent();
          }
        }}
        InputRightElement={
          <Pressable mr={2} onPress={() => setShow(!show)}>
            {ServicesIcon(show ? 'eyeOff' : 'eye', 18, 'rgba(44, 45, 73, 0.4)')}
          </Pressable>
        }
      />
      {validate && startValidation && !isValid && (
        <Text mt={2} ml={2} color={defaultTextColors.error}>
          {customErroMessage || `${label} inválida.`}
        </Text>
      )}
    </Flex>
  );
};

export default FormPasswordInput;
