import React, { useEffect, useRef, useState } from 'react';
import { Animated, Platform } from 'react-native';
import {
  Box,
  Center,
  Divider,
  FlatList,
  Flex,
  Pressable,
  Spinner,
  Text,
} from 'native-base';
import { useRecoilState, useRecoilValue } from 'recoil';
import { get } from 'lodash';
import Container from '../../components/Container';
import { notificationsAtom } from '../../Recoil/Atoms/notifications';
import SimpleRequestHandler from '../../services/meishop';
import { themeAtom } from '../../Recoil/Atoms/theme';
import NotificationItem from './NotificationItem';
import NotificationsHeader from '../../components/Header/NotificationsHeader';
import ServiceIcon from '../../utilites/ServicesIcon';

const Notifications = ({ logUser, setReloadingTheme }) => {
  const colors = useRecoilValue(themeAtom).layout;
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);

  const xOffset = useRef(new Animated.Value(400)).current;

  const slideIn = () => {
    Animated.timing(xOffset, {
      toValue: 0,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };

  const slideOut = () => {
    Animated.timing(xOffset, {
      toValue: 400,
      duration: 200,
      useNativeDriver: true,
    }).start();
  };

  const requestNotifications = async (refreshCounter = true) => {
    setLoading(true);
    let newCounter = notifications.counter;
    if (refreshCounter) {
      await SimpleRequestHandler('notifications/count', 'GET').then(
        ({ data }) => {
          newCounter = get(data, 'count', 0);
        }
      );
    }
    SimpleRequestHandler('notifications', 'GET')
      .then(({ data }) => {
        setNotifications({
          ...notifications,
          counter: newCounter,
          notifications: get(data, 'data', []),
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const getNotifications = () => {
    slideIn();
    requestNotifications(false);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const handleClose = () => {
    slideOut();
    setTimeout(() => {
      setNotifications({
        ...notifications,
        show: false,
      });
    }, 200);
  };

  return (
    <Flex
      flex={1}
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={80}
      overflow="hidden"
      position="absolute"
      bgColor="rgba(0, 0, 0, 0.4)"
    >
      <Animated.View
        style={{
          height: '100%',
          transform: [
            {
              translateX: xOffset,
            },
          ],
        }}
      >
        <Box
          mr={0}
          h="full"
          flex={1}
          ml="auto"
          w={['full', 'full', '2/3', '1/3']}
          pt={Platform.OS !== 'web' ? 12 : 0}
          bgColor={{
            linearGradient: {
              colors: [colors.bgSecondary, colors.bgPrimary],
              start: [0, 0],
              end: [0, 1],
            },
          }}
        >
          <Flex p={4} flexDirection="row" alignItems="center">
            <Pressable
              p={2}
              rounded="xl"
              bgColor="rgba(255, 255, 255, 0.2)"
              onPress={handleClose}
            >
              {ServiceIcon('left', 18, '#FFF')}
            </Pressable>
            <Text ml={4} fontSize="xl" color="#FFF" fontWeight="semibold">
              Notificações
            </Text>
          </Flex>
          <Flex
            p={4}
            flex={1}
            roundedTop="2xl"
            bgColor="#f0f2f6"
            flexDirection="column"
          >
            <NotificationsHeader
              setLoading={setLoading}
              notifications={notifications}
              requestNotifications={requestNotifications}
            />
            {loading ? (
              <Center mt={12}>
                <Spinner
                  mx="auto"
                  size="lg"
                  color={colors.bgSecondary}
                  accessibilityLabel="Carregando notificações"
                />
              </Center>
            ) : (
              <Container
                px={0}
                flexDirection="column"
                justifyContent="flex-start"
                flexWrap="nowrap"
              >
                <Divider w="full" my={4} borderColor="rgba(0, 0, 0, 0.4)" />
                {notifications.notifications.length === 0 && (
                  <Text
                    fontSize="lg"
                    mt={12}
                    textAlign="center"
                    fontWeight="semibold"
                  >
                    Nada aqui por enquanto 😄
                  </Text>
                )}
                <FlatList
                  renderItem={({ item }) => (
                    <NotificationItem
                      item={item}
                      colors={colors}
                      logUser={logUser}
                      setReloadingTheme={setReloadingTheme}
                      requestNotifications={requestNotifications}
                    />
                  )}
                  keyExtractor={(item) => item.id}
                  data={notifications.notifications}
                />
              </Container>
            )}
          </Flex>
        </Box>
      </Animated.View>
    </Flex>
  );
};

export default Notifications;
