import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Center, Flex, ScrollView, Text } from 'native-base';
import { useRecoilValue } from 'recoil';
import { themeAtom } from '../../Recoil/Atoms/theme';
import SimpleRequestHandler from '../../services/meishop';
import CompanyDetailRow from '../../components/Company/CompanyDetailRow';
import CompanyNeedRoute from '../../navigation/CompanyNeedRoute';
import { selectedCompanyAtom } from '../../Recoil/Atoms/selectedCompany';
import { ampLogEvent, defaultTextColors } from '../../utilites/utilities';
import Ccmei from '../../components/Buttons/Ccmei';
import { userAtom } from '../../Recoil/Atoms/user';
import Container from '../../components/Container';
import CircleSnail from '../../components/Global/Loading';

const Details = ({ navigation, route }) => {
  const userId = useRecoilValue(userAtom).id;
  const [cnpj, setCnpj] = useState(null);
  const colors = useRecoilValue(themeAtom).layout;
  const [loading, setLoading] = useState(true);
  const [reloadData, setReloadData] = useState(false);
  const selectedCompany = useRecoilValue(selectedCompanyAtom);
  const [companyId, setCompanyId] = useState(
    get(route, 'params.id', selectedCompany.id)
  );

  const formatSecondaryActivities = (activities) => {
    let stringRow = '';
    activities.map((activity) => {
      stringRow += `${activity.code} - ${activity.description} \n\n`;
      return activity;
    });
    return stringRow;
  };

  const requestCompanyData = () => {
    setLoading(true);
    SimpleRequestHandler(`company/${companyId}`, 'GET')
      .then((response) => {
        const detailsResponse = get(response.data, 'data', null);
        setCnpj(detailsResponse);
        setLoading(false);
        setReloadData(false);
      })
      .catch(() => {
        setReloadData(false);
      });
  };

  useEffect(
    () =>
      navigation.addListener('focus', () => {
        if (companyId) {
          requestCompanyData();
        }
      }),
    [navigation]
  );

  useEffect(() => {
    if (!loading && selectedCompany) {
      setCompanyId(selectedCompany.id);
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (reloadData) {
      requestCompanyData();
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    if (cnpj) {
      ampLogEvent(userId, 'Company Details', {
        mei: cnpj.simples.simples,
        ccmei: cnpj.ccmei !== null,
      });
    }
  }, [cnpj]);

  return (
    <Container flexDirection="column" justifyContent="flex-start">
      <CompanyNeedRoute>
        {loading ? (
          <Center w="full" mt={24}>
            <CircleSnail size={80} thickness={4} color={colors.bgSecondary} />
          </Center>
        ) : (
          <ScrollView flexDirection="column" pb={12} px={1}>
            {cnpj.simples.simples && (
              <Ccmei
                companyId={cnpj.id}
                setReload={setReloadData}
                currentStatus={get(cnpj, 'ccmei', null)}
              />
            )}
            <Text
              bold
              mt={4}
              mb={2}
              fontSize="2xl"
              textColor={defaultTextColors.secondary}
            >
              Receita federal
            </Text>
            <Flex bgColor="#FFF" p={4} borderRadius="2xl" shadow={1}>
              <Flex flexDirection="column">
                <CompanyDetailRow
                  label="Nome Fantasia"
                  value={cnpj.cnpj.nome_fantasia}
                />
                <CompanyDetailRow
                  label="Razão Social"
                  value={cnpj.cnpj.razao_social}
                />
                <CompanyDetailRow label="CNPJ" value={cnpj.cnpj.cnpj} />
                <CompanyDetailRow
                  label="Natureza Jurídica"
                  value={cnpj.cnpj.natureza_juridica}
                />
                <CompanyDetailRow
                  label="Abertura"
                  value={new Intl.DateTimeFormat('pt-BR').format(
                    new Date(cnpj.cnpj.data_abertura)
                  )}
                />
                <CompanyDetailRow label="Porte" value={cnpj.cnpj.porte} />
                <CompanyDetailRow
                  label="Capital Social"
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(cnpj.cnpj.capital_social)}
                />
                <CompanyDetailRow
                  label="Situação Cadastral"
                  value={get(
                    cnpj,
                    'cnpj.situacao_cadastral',
                    'Não identificado'
                  )}
                />
                <CompanyDetailRow label="E-mail" value={cnpj.cnpj.email} />
                <CompanyDetailRow label="Telefone" value={cnpj.cnpj.telefone} />
                <CompanyDetailRow label="CEP" value={cnpj.cnpj.endereco_cep} />
                <CompanyDetailRow
                  label="Endereço"
                  value={`${cnpj.cnpj.endereco_logradouro}, ${cnpj.cnpj.endereco_numero}`}
                />
                <CompanyDetailRow
                  label="Cidade"
                  value={cnpj.cnpj.endereco_cidade}
                />
                <CompanyDetailRow
                  label="Estado"
                  value={cnpj.cnpj.endereco_estado}
                />
                <CompanyDetailRow
                  label="Bairro"
                  value={cnpj.cnpj.endereco_bairro}
                  noDivisor
                />
              </Flex>
            </Flex>
            {cnpj.activity && (
              <Text
                mt={4}
                mb={2}
                fontSize="xl"
                textColor={defaultTextColors.secondary}
                bold
              >
                Atividade econômica
              </Text>
            )}
            {cnpj.activity && (
              <Flex bgColor="#FFF" p={4} borderRadius="2xl" shadow={1}>
                <Flex flexDirection="column">
                  <CompanyDetailRow
                    label="Primária"
                    value={`${cnpj.cnpj.activity.code} - ${cnpj.cnpj.activity.description}`}
                  />
                  <CompanyDetailRow
                    noDivisor
                    label="Secundárias"
                    value={formatSecondaryActivities(cnpj.cnpj.activities)}
                  />
                </Flex>
              </Flex>
            )}
            <Text
              mt={4}
              mb={2}
              fontSize="xl"
              textColor={defaultTextColors.secondary}
              bold
            >
              Simples Nacional
            </Text>
            <Flex bgColor="#FFF" p={4} borderRadius="2xl" shadow={1} mb={8}>
              <Flex flexDirection="column">
                <CompanyDetailRow
                  label="Optante Simples"
                  value={cnpj.simples.simples ? 'Sim' : 'Não'}
                />
                <CompanyDetailRow
                  noDivisor
                  label="Optante SIMEI"
                  value={cnpj.simples.simei ? 'Sim' : 'Não'}
                />
              </Flex>
            </Flex>
          </ScrollView>
        )}
      </CompanyNeedRoute>
    </Container>
  );
};

export default Details;
