import React from 'react';
import {
  X,
  Eye,
  Key,
  Bell,
  User,
  Grid,
  Star,
  File,
  Check,
  Info,
  Award,
  Share,
  LogOut,
  EyeOff,
  FileText,
  FilePlus,
  Calendar,
  Briefcase,
  CreditCard,
  DollarSign,
  HelpCircle,
  CheckCircle,
  ChevronLeft,
  ChevronDown,
  ChevronRight,
  MessageSquare,
  AlertTriangle,
} from 'react-native-feather';
import { Ionicons } from '@expo/vector-icons';

export const NotificationsIcons = (type, size = 18, color = '#fff') => {
  const NOTIFICATIONS_ICONS = {
    USER_ONBOARDING_FAILED: <Info stroke={color} height={size} width={size} />,
    USER_ONBOARDING_STARTED: (
      <Award stroke={color} height={size} width={size} />
    ),
    COMPANY_AUTOMATICALLY_ADDED: (
      <FileText stroke={color} height={size} width={size} />
    ),
  };

  return (
    NOTIFICATIONS_ICONS[type] || (
      <Info stroke={color} height={size} width={size} />
    )
  );
};

const ServiceIcon = (service, size = 24, color = '#fff') => {
  const SERVICES_ICONS = {
    x: <X stroke={color} height={size} width={size} />,
    eye: <Eye stroke={color} height={size} width={size} />,
    key: <Key stroke={color} height={size} width={size} />,
    user: <User stroke={color} height={size} width={size} />,
    check: <Check stroke={color} height={size} width={size} />,
    share: <Share stroke={color} height={size} width={size} />,
    logout: <LogOut stroke={color} height={size} width={size} />,
    eyeOff: <EyeOff stroke={color} height={size} width={size} />,
    Dashboard: <Grid stroke={color} height={size} width={size} />,
    left: <ChevronLeft stroke={color} height={size} width={size} />,
    down: <ChevronDown stroke={color} height={size} width={size} />,
    company: <Briefcase stroke={color} height={size} width={size} />,
    calendar: <Calendar stroke={color} height={size} width={size} />,
    formalizacao: <Star stroke={color} height={size} width={size} />,
    right: <ChevronRight stroke={color} height={size} width={size} />,
    notifications: <Bell stroke={color} height={size} width={size} />,
    question: <HelpCircle stroke={color} height={size} width={size} />,
    alert: <AlertTriangle stroke={color} height={size} width={size} />,
    nota_fiscal: <FilePlus stroke={color} height={size} width={size} />,
    declaracao_dasn: <File stroke={color} height={size} width={size} />,
    boleto_das: <DollarSign stroke={color} height={size} width={size} />,
    company_cnpj: <FileText stroke={color} height={size} width={size} />,
    solutions: <Ionicons name="cart-outline" size={size} color={color} />,
    atendimento: <MessageSquare stroke={color} height={size} width={size} />,
    'check-circle': <CheckCircle stroke={color} height={size} width={size} />,
    'declaracao-dasn': <FileText stroke={color} height={size} width={size} />,
    'parcelamento-das': (
      <CreditCard stroke={color} height={size} width={size} />
    ),
    tips_for_you: (
      <Ionicons name="newspaper-outline" size={size} color={color} />
    ),
  };

  return (
    SERVICES_ICONS[service] || (
      <Star stroke={color} height={size} width={size} />
    )
  );
};

export default ServiceIcon;
