import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Center } from 'native-base';
import { useRecoilState } from 'recoil';
import CircleSnail from '../../components/Global/Loading';
import SimpleRequestHandler from '../../services/meishop';
import { showToastMessage } from '../../utilites/ToastProvider';
import { verifyEmailAtom } from '../../Recoil/Atoms/verifyEmail';

const VerifyEmail = ({ navigation, isLogged, route }) => {
  const id = get(route, 'params.id', null);
  const hash = get(route, 'params.hash', null);
  const expires = get(route, 'params.expires', null);
  const signature = get(route, 'params.signature', null);

  const [loading, setLoading] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useRecoilState(verifyEmailAtom);

  const handleLinkError = () => {
    showToastMessage('Erro', 'Link inválido.', 'error');
    navigation.navigate(isLogged ? 'Dashboard' : 'Login');
  };

  const doLogin = async () => {
    if (!verifiedEmail) {
      setVerifiedEmail(true);
      SimpleRequestHandler(`email/verify/${id}/${hash}`, 'GET', {
        expires,
        signature,
      })
        .then(() => {
          showToastMessage(
            'Sucesso',
            'Email verificado com sucesso.',
            'success'
          );
          navigation.navigate('Login');
        })
        .catch(() => {
          handleLinkError();
        });
    }
  };

  useEffect(async () => {
    if (loading) {
      if (expires && hash && id && signature) {
        await doLogin();
      } else {
        handleLinkError();
      }
    } else {
      setLoading(true);
    }
  }, [loading]);

  return (
    <Center
      top={0}
      left={0}
      w="full"
      h="full"
      zIndex={80}
      position="absolute"
      backgroundColor={{
        linearGradient: {
          colors: ['#463681', '#60519a'],
          start: [0, 0],
          end: [0, 1],
        },
      }}
    >
      <CircleSnail
        size={80}
        color="#FFF"
        thickness={4}
        fill="rgba(0, 0, 0, 0)"
      />
    </Center>
  );
};

export default VerifyEmail;
