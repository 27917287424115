import { atom } from 'recoil';

export const modalDasnAtom = atom({
  key: 'modalDasnState',
  default: {
    year: null,
    show: false,
    useValue: false,
    servicesValue: 0,
  },
});
