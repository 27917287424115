import React from 'react';
import { Platform } from 'react-native';
import { Box, Flex, Text } from 'native-base';
// eslint-disable-next-line import/no-cycle
import { defaultComponentsColors } from '../../utilites/utilities';

const Toast = ({ title, description, type }) => {
  const backgroundTypes = {
    error: defaultComponentsColors.error,
    warn: defaultComponentsColors.warning,
    success: defaultComponentsColors.success,
  };

  return (
    <Box position="absolute" w="100%" alignItems="center">
      <Flex
        py={2}
        top={Platform.OS === 'web' ? 4 : 12}
        px={[4, 8]}
        rounded="full"
        textAlign="center"
        mx={[4, 2, 'auto']}
        flexDirection="column"
        bgColor={backgroundTypes[type]}
      >
        <Text fontSize="sm" color="#FFF" fontWeight="semibold">
          {title}
        </Text>
        <Text fontSize="xs" color="#FFF" fontWeight="medium">
          {description}
        </Text>
      </Flex>
    </Box>
  );
};

export default Toast;
