import React, { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Spinner, Text, Box, Pressable, Flex } from 'native-base';
import * as Sharing from 'expo-sharing';
import { get } from 'lodash';
import { Platform, Share } from 'react-native';
import { themeAtom } from '../../Recoil/Atoms/theme';
import ServiceIcon from '../../utilites/ServicesIcon';
import {
  ampLogEvent,
  defaultTextColors,
  handleOpenLink,
} from '../../utilites/utilities';
import SimpleRequestHandler from '../../services/meishop';
import { modalAtom } from '../../Recoil/Atoms/modal';
import { userAtom } from '../../Recoil/Atoms/user';
import { viewingCompanyAtom } from '../../Recoil/Atoms/viewingCompany';

const StatusIcon = ({ status, color }) => {
  if (status === 'SUCCESS') {
    return '';
  }
  if (status === 'ERROR') {
    return ServiceIcon('x', 24, defaultTextColors.error);
  }
  return <Spinner size="sm" color={color} />;
};

const Ccmei = ({ companyId, currentStatus, setReload }) => {
  const theme = useRecoilValue(themeAtom);
  const userId = useRecoilValue(userAtom).id;
  const setModal = useSetRecoilState(modalAtom);
  const [ccmei, setCcmei] = useState('');
  const currentCompany = useRecoilValue(viewingCompanyAtom);
  const [status, setStatus] = useState('PROCESSING');
  const [modalObj, setModalObj] = useState({
    title: '',
    message: '',
    show: false,
  });

  const colors = theme.layout;

  const messages = {
    SUCCESS: 'Ver certificado MEI',
    ERROR: 'Erro ao buscar seu CCMEI.',
    PROCESSING: 'Buscando seu CCMEI',
    NEW: 'Buscando seu CCMEI',
  };

  const handleCcmei = async () => {
    if (status === 'SUCCESS' && ccmei !== '') {
      await ampLogEvent(userId, 'View CCMEI', {});
      await handleOpenLink(ccmei.url);
    }
  };

  const requestCcmeiStatus = () => {
    SimpleRequestHandler(`company/${companyId}/ccmei/status`, 'GET')
      .then(({ data }) => {
        setStatus(data.status);
        if (data.status === 'ERROR') {
          setModalObj({
            title: 'Não conseguimos buscar seu CCMEI',
            message:
              'Isso normalmente ocorre quando o CPF ou data de nascimento informados não batem com os dados que constam no Portal do Empreendedor.\n\n Clique em "Atualizar dados cadastrais" para conferir e atualizar seus dados.',
            show: true,
          });
        } else if (data.status === 'PROCESSING' || data.status === 'NEW') {
          setTimeout(() => {
            requestCcmeiStatus();
          }, 5000);
        } else {
          setReload(true);
        }
      })
      .catch(() => {
        setStatus('ERROR');
        setModalObj({
          title: 'Não conseguimos buscar seu CCMEI',
          message:
            'Isso normalmente ocorre quando o CPF ou data de nascimento informados não batem com os dados que constam no Portal do Empreendedor.\n\n Clique em "Atualizar dados cadastrais" para conferir e atualizar seus dados.',
          show: true,
        });
      });
  };

  const handleCcmeiShare = () => {
    if (Platform.OS === 'ios') {
      Share.share({
        title: get(currentCompany, 'cnpj.nome_fantasia', ''),
        message: `Certificado MEI - ${get(
          currentCompany,
          'cnpj.nome_fantasia',
          ''
        )}`,
        url: ccmei.url,
      });
    } else {
      Sharing.shareAsync(ccmei.url, {
        dialogTitle: `Certificado MEI - ${get(
          currentCompany,
          'cnpj.nome_fantasia',
          ''
        )}`,
      });
    }
  };

  useEffect(() => {
    if (currentStatus !== null) {
      setStatus('SUCCESS');
      setCcmei(currentStatus);
    } else {
      requestCcmeiStatus();
    }
  }, [currentStatus]);

  return (
    <Flex
      mt={2}
      flex={1}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Pressable
        py={2}
        px={3}
        rounded="full"
        flexDirection="row"
        alignItems="center"
        onPress={handleCcmei}
        borderColor="rgba(44, 45, 73, 0.4)"
        w={Platform.OS === 'web' ? 'full' : '5/6'}
        borderWidth={status === 'SUCCESS' ? 1 : 0}
        bgColor={status === 'SUCCESS' ? 'rgba(0, 0, 0, 0)' : '#FFF'}
      >
        {status !== 'SUCCESS' && (
          <StatusIcon status={status} color={colors.bgSecondary} />
        )}
        <Text
          mr="auto"
          fontSize="lg"
          fontWeight="semibold"
          ml={status === 'SUCCESS' ? 'auto' : 2}
        >
          {messages[status]}
        </Text>
        {status === 'PROCESSING' && (
          <Box py={2} px={4} mr="0" rounded="full" bgColor="#f0f2f6">
            <Text fontSize="xs" fontWeight="semibold">
              Aguarde
            </Text>
          </Box>
        )}
        {status === 'ERROR' && (
          <Pressable
            p={1}
            mr="0"
            rounded="full"
            bgColor="#f0f2f6"
            onPress={() => setModal(modalObj)}
          >
            {ServiceIcon('question', 26, defaultTextColors.secondary)}
          </Pressable>
        )}
      </Pressable>
      {Platform.OS !== 'web' && (
        <Pressable
          p={2}
          rounded="full"
          bgColor={colors.bgPrimary}
          onPress={handleCcmeiShare}
        >
          {ServiceIcon('share', 24, '#FFF')}
        </Pressable>
      )}
    </Flex>
  );
};

export default Ccmei;
