import React, { useCallback, useEffect, useState } from 'react';
import { get } from 'lodash';
import { cnpj as validator } from 'cpf-cnpj-validator';
import {
  Flex,
  Button,
  Heading,
  Input,
  VStack,
  Text,
  Divider,
  Pressable,
  Box,
  ScrollView,
} from 'native-base';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import { useRecoilState, useRecoilValue } from 'recoil';
import CardRow from '../../components/Global/CardRow';
import SimpleRequestHandler from '../../services/meishop';
import {
  ampLogEvent,
  defaultComponentsColors,
  defaultTextColors,
  validateUserAge,
} from '../../utilites/utilities';
import { showToastMessage } from '../../utilites/ToastProvider';
import ServiceIcon from '../../utilites/ServicesIcon';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { userAtom } from '../../Recoil/Atoms/user';
import Container from '../../components/Container';

const ConfirmCompany = ({ navigation, route }) => {
  const mei = get(route, 'params.data');
  const [user, setUser] = useRecoilState(userAtom);
  const colors = useRecoilValue(themeAtom).layout;
  const paperTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: colors.bgSecondary,
      background: '#f0f2f6',
    },
  };
  const userHasBirthDate = get(user, 'date_of_birth', false);

  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [formatedBirth, setFormatedBirth] = useState('');
  const [startDateInput, setStartDateInput] = useState(false);

  const onDismissSingle = useCallback(() => {
    setOpenDate(false);
  }, [setOpenDate]);

  const onConfirmSingle = useCallback(
    (params) => {
      setOpenDate(false);
      setDate(params.date);
    },
    [setOpenDate, setDate]
  );

  useEffect(() => {
    if (startDateInput) {
      setFormatedBirth(new Intl.DateTimeFormat('pt-BR').format(date));
    }
  }, [date, startDateInput]);

  const sendCompanyData = (body) => {
    setLoading(true);
    SimpleRequestHandler('company/confirm', 'POST', body)
      .then(() => {
        setLoading(false);
        navigation.navigate('company');
      })
      .catch(() => setLoading(false));
  };

  const handleSubmit = () => {
    const requestBody = {
      cnpj: mei.cartao.cnpj.replace(/[^0-9]/g, ''),
    };
    if (!userHasBirthDate) {
      const splitedDate = formatedBirth.split('/');
      const validAge = validateUserAge(splitedDate[2]);
      if (validAge) {
        requestBody.date_of_birth = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
        setUser({
          ...user,
          date_of_birth: requestBody.date_of_birth,
        });
        sendCompanyData(requestBody);
      } else {
        showToastMessage(
          'Data inválida',
          'É necessário ter no minímo 16 anos para possuir uma MEI.',
          'warn'
        );
      }
    } else {
      sendCompanyData(requestBody);
    }
  };

  useEffect(() => {
    ampLogEvent(user.id, 'Company Register - Validate company', {
      require_dob: !userHasBirthDate,
    });
  }, []);

  return (
    <Container
      flexWrap="nowrap"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <ScrollView
        _contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 10,
        }}
      >
        <Text mt={2} fontSize="sm" color="rgba(44, 45, 73, 0.5)">
          Confirme os dados a baixo. Se estiver tudo certinho, é só clicar em
          Confirmar.
        </Text>
        <VStack
          p={4}
          mt={6}
          shadow={1}
          isRequired
          bgColor="white"
          borderRadius={20}
        >
          <Flex flexDirection="column">
            <Heading fontSize="md" color={defaultTextColors.default}>
              Confirme seus dados
            </Heading>
            <Input
              my={4}
              pl={4}
              size="lg"
              isDisabled
              variant="rounded"
              onChange={() => {}}
              placeholder={validator.format(get(mei, 'cartao.cnpj', ''))}
            />
            <Divider my={1} />
            <CardRow
              label="Razão Social"
              value={get(mei, 'cartao.razao_social', '')}
            />
            <Divider my={1} />
            <CardRow label="Telefone" value={get(mei, 'cartao.telefone', '')} />
            <Divider my={1} />
            <CardRow
              label="Município"
              value={get(mei, 'cartao.endereco_cidade', '')}
            />
            <Divider my={1} />
            <CardRow
              label=""
              value={`MEI: este CNPJ ${
                get(mei, 'simples.simei', false) ? 'é' : 'não é'
              } MEI`}
            />
            {!userHasBirthDate && (
              <>
                <Divider my={1} />
                <Heading mt={1} fontSize="md" color={defaultTextColors.default}>
                  Data de nascimento
                </Heading>
                <PaperProvider theme={paperTheme}>
                  <DatePickerModal
                    date={date}
                    mode="single"
                    locale="pt-BR"
                    visible={openDate}
                    saveLabel="Continuar"
                    label="Data de nascimento"
                    onDismiss={onDismissSingle}
                    onConfirm={onConfirmSingle}
                    validRange={{
                      endDate: new Date(),
                    }}
                  />
                </PaperProvider>
                <Pressable mr={2} onPress={() => setOpenDate(true)}>
                  <Input
                    mt={2}
                    mb={4}
                    pl={4}
                    size="lg"
                    variant="rounded"
                    value={formatedBirth}
                    w={['2/3', '2/3', '2/3', '1/3']}
                    placeholder="Sua data de nascimento"
                    onChange={() => {}}
                    onFocus={() => {
                      setStartDateInput(true);
                      setOpenDate(true);
                    }}
                    InputRightElement={
                      <Box mr={2}>
                        {ServiceIcon('calendar', 24, defaultTextColors.default)}
                      </Box>
                    }
                  />
                </Pressable>
                <Text fontSize="xs" color={defaultTextColors.default}>
                  Utilizamos sua data de nascimento e seu CPF para
                  disponibilizar o Certificado MEI
                </Text>
              </>
            )}
          </Flex>
        </VStack>
        <Button
          py={3}
          px={5}
          mt={4}
          fontSize="md"
          rounded="full"
          isRequestButton
          isLoading={loading}
          onPress={handleSubmit}
          w={['full', 'full', 'full', '1/3']}
          bgColor={defaultComponentsColors.warning}
        >
          <Text fontSize="md" fontWeight="medium" color="#FFF">
            {loading ? 'Salvando empresa' : 'Confirmar cadastro'}
          </Text>
        </Button>
      </ScrollView>
    </Container>
  );
};

export default ConfirmCompany;
