import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = [];

export const { Types, Creators } = createActions({
  removeServices: [''],
  insertService: ['data'],
  insertServices: ['data'],
});

const insert = (state = INITIAL_STATE, action) => [...state, action.data];

const insertMany = (state, action) => [...action.data];

const remove = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [Types.INSERT_SERVICE]: insert,
  [Types.REMOVE_SERVICES]: remove,
  [Types.INSERT_SERVICES]: insertMany,
});
