import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = {
  plainTextToken: '',
};

export const { Types, Creators } = createActions({
  resetToken: [''],
  removeToken: [''],
  insertToken: ['data'],
});

const insert = (state = INITIAL_STATE, action) => ({
  ...state,
  ...action.data,
});

const remove = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

const reset = () => INITIAL_STATE;

export default createReducer(INITIAL_STATE, {
  [Types.RESET_TOKEN]: reset,
  [Types.INSERT_TOKEN]: insert,
  [Types.REMOVE_TOKEN]: remove,
});
