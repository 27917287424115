import React, { useState } from 'react';
import { filter, get } from 'lodash';
import { Platform } from 'react-native';
import { Badge, Box, Flex, Pressable } from 'native-base';
import { User } from 'react-native-feather';
import { useSelector } from 'react-redux';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigation } from '@react-navigation/native';
import UserMenu from '../Menu/UserMenu';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { servicesAtom } from '../../Recoil/Atoms/services';
import { selectedCompanyAtom } from '../../Recoil/Atoms/selectedCompany';
import ServicesIcon from '../../utilites/ServicesIcon';
import { notificationsAtom } from '../../Recoil/Atoms/notifications';

const HeaderApps = () => {
  const navigation = useNavigation();
  const colors = useRecoilValue(themeAtom).layout;
  const userCompany = useRecoilValue(selectedCompanyAtom);
  const userToken = useSelector((state) => state.token.plainTextToken);
  const isLogged = userToken !== '';
  const apps = filter(
    useRecoilValue(servicesAtom),
    (o) => o.layout.position === 2
  );
  const currentRoute =
    navigation &&
    navigation.getCurrentRoute() &&
    navigation.getCurrentRoute().name;

  const [showUserMenu, setShowUserMenu] = useState(false);
  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const notificationsCounter = notifications.counter;

  const handleApp = (path) => {
    if (path === 'notifications') {
      setNotifications({
        ...notifications,
        show: !notifications.show,
      });
    } else {
      setTimeout(() => {
        if (path === 'company_cnpj') {
          const companyId = get(userCompany, 'id', null);
          navigation.navigate(path, {
            id: companyId,
          });
        } else {
          navigation.navigate(path);
        }
      }, 100);
    }
  };

  return (
    <Flex
      top={12}
      right={12}
      display={['none', 'none', 'flex']}
      position={Platform.OS === 'web' ? 'fixed' : 'absolute'}
    >
      <Box display={['none', 'none', 'flex']} flexDirection="row">
        {apps.map((app, key) => (
          <Pressable
            mr={2}
            padding={2}
            borderRadius={10}
            flexDirection="row"
            key={key.toString()}
            bgColor={
              currentRoute === app.path
                ? 'rgba(0, 0, 0, 0.1)'
                : 'rgba(255, 255, 255, 1)'
            }
            onPress={() => handleApp(app.path)}
          >
            {ServicesIcon(app.path, 24, colors.bgPrimary)}
          </Pressable>
        ))}
        {notificationsCounter !== 0 && (
          <Badge
            mr={-3}
            mt={-2}
            position="relative"
            bgColor="#007fc6"
            rounded="999px"
            zIndex={1}
            alignSelf="flex-start"
            variant="solid"
            _text={{
              fontSize: 12,
            }}
          >
            {notificationsCounter}
          </Badge>
        )}
        <Pressable
          mr={2}
          padding={2}
          borderRadius={10}
          flexDirection="row"
          bgColor={
            currentRoute === 'notifications'
              ? 'rgba(0, 0, 0, 0.1)'
              : 'rgba(255, 255, 255, 1)'
          }
          onPress={() => handleApp('notifications')}
        >
          {ServicesIcon('notifications', 24, colors.bgPrimary)}
        </Pressable>
        <Pressable
          padding={2}
          borderRadius={10}
          flexDirection="row"
          bgColor="rgba(255, 255, 255, 1)"
          onPress={() => setShowUserMenu(!showUserMenu)}
        >
          <User stroke={colors.bgPrimary} />
        </Pressable>
      </Box>
      <UserMenu
        openAuth={() => {}}
        isLogged={isLogged}
        isOpen={showUserMenu}
        setOpen={setShowUserMenu}
        display={['none', 'none', 'flex']}
      />
    </Flex>
  );
};

export default HeaderApps;
