import React from 'react';
import { useRecoilValue } from 'recoil';
import { Box, Flex, Image, Text } from 'native-base';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { defaultTextColors } from '../../utilites/utilities';

import das from '../../assets/CompanyBenefits/das.svg';
import dasn from '../../assets/CompanyBenefits/dasn.svg';
import cnpj from '../../assets/CompanyBenefits/cnpj.svg';
import notas from '../../assets/CompanyBenefits/notas.svg';
import extratoDas from '../../assets/CompanyBenefits/extratoDas.svg';

const itemImages = {
  cartao_cnpj: cnpj,
  extrato_das: extratoDas,
  boleto_das: das,
  declaracao_dasn: dasn,
  nfe: notas,
};

const CompanyBenefitItem = ({ item, available = true }) => {
  const colors = useRecoilValue(themeAtom).layout;

  return (
    <Flex
      pt={6}
      px={4}
      flexDirection={['row', 'row', 'row', 'column']}
      w={['full', 'full', 'full', '1/3', '1/3', '1/4']}
      alignItems={['center', 'center', 'center', 'flex-start']}
    >
      <Image source={itemImages[item.icon]} resizeMode="contain" size="xs" />
      <Flex flexDirection="column" ml={[4, 4, 4, 0]} mt={[0, 0, 0, 4]}>
        {!available && (
          <Box mr="auto" px={2} py={0.5} rounded="full" bgColor="#007fc6">
            <Text color="#f0f2f6" fontSize="xs" bold>
              Em breve
            </Text>
          </Box>
        )}
        <Text
          fontWeight="bold"
          color={[
            defaultTextColors.default,
            defaultTextColors.default,
            defaultTextColors.default,
            colors.bgSecondary,
          ]}
        >
          {item.title}
        </Text>
        {item.description && (
          <Text
            fontSize="sm"
            w={['80%', '80%', '80%', 'full']}
            color={defaultTextColors.default}
          >
            {item.description}
          </Text>
        )}
      </Flex>
    </Flex>
  );
};

export default CompanyBenefitItem;
