import React, { useEffect, useState } from 'react';
import { Center, FlatList, ScrollView } from 'native-base';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import SimpleRequestHandler from '../../services/meishop';
import { viewingCategoryAtom } from '../../Recoil/Atoms/viewingCategory';
import CircleSnail from '../../components/Global/Loading';
import FeedCategory from './FeedCategory';
import { themeAtom } from '../../Recoil/Atoms/theme';

const Feed = ({ navigation }) => {
  const colors = useRecoilValue(themeAtom).layout;

  const [tips, setTips] = useState([]);
  const [loading, setLoading] = useState(true);
  const setViewingCategory = useSetRecoilState(viewingCategoryAtom);

  const fetchTips = () => {
    SimpleRequestHandler(
      'tipsforyou/feed?label_name=lista_default',
      'GET'
    ).then(({ data }) => {
      setTips(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (tips.length === 0) {
      fetchTips();
    }
  }, []);

  const handleOpenCategory = (category) => {
    setViewingCategory(category);
    navigation.navigate(`tips`, {
      category,
    });
  };

  return (
    <ScrollView
      px={4}
      flex={1}
      _contentContainerStyle={{
        flexGrow: 1,
        paddingBottom: 10,
      }}
    >
      {loading ? (
        <Center mt={10} w="100%">
          <CircleSnail size={80} thickness={4} color={colors.bgSecondary} />
        </Center>
      ) : (
        <FlatList
          data={tips}
          renderItem={({ item }) => (
            <FeedCategory
              category={item}
              colors={colors}
              handleOpenCategory={handleOpenCategory}
            />
          )}
        />
      )}
    </ScrollView>
  );
};

export default Feed;
