import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { Center, Spinner, FlatList } from 'native-base';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import CompanyCard from '../../components/Company/CompanyCard';
import SimpleRequestHandler from '../../services/meishop';
import CompanyNeedRoute from '../../navigation/CompanyNeedRoute';
import { userCompaniesAtom } from '../../Recoil/Atoms/userCompanies';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { selectedCompanyAtom } from '../../Recoil/Atoms/selectedCompany';
import Container from '../../components/Container';
import { viewingCompanyAtom } from '../../Recoil/Atoms/viewingCompany';

const Companies = ({ navigation }) => {
  const colors = useRecoilValue(themeAtom).layout;
  const [loading, setLoading] = useState(true);
  const setViewingCompany = useSetRecoilState(viewingCompanyAtom);
  const setSelectedCompany = useSetRecoilState(selectedCompanyAtom);
  const [companies, setCompanies] = useRecoilState(userCompaniesAtom);

  const getUserCompanies = () => {
    if (companies.length < 1) {
      setLoading(true);
    }
    SimpleRequestHandler('company', 'GET')
      .then((response) => {
        setLoading(false);
        const companiesResponse = get(response.data, 'data', []);
        setCompanies(companiesResponse);
        setSelectedCompany(companiesResponse[0]);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getUserCompanies();
    });

    return unsubscribe;
  }, [navigation]);

  return (
    <Container
      mb={4}
      flexWrap="nowrap"
      flexDirection="column"
      justifyContent="flex-start"
    >
      <CompanyNeedRoute>
        {loading ? (
          <Center mt={24}>
            <Spinner
              size={40}
              mx="auto"
              color={colors.bgSecondary}
              accessibilityLabel="Carregando serviços"
            />
          </Center>
        ) : (
          <FlatList
            px={1}
            data={companies}
            renderItem={(props) => (
              <CompanyCard
                navigation={navigation}
                setViewingCompany={setViewingCompany}
                {...props}
              />
            )}
          />
        )}
      </CompanyNeedRoute>
    </Container>
  );
};

export default Companies;
