import { atom } from 'recoil';

export const selectedCompanyAtom = atom({
  key: 'selectedCompanyState',
  default: {
    id: 0,
    user_id: 0,
    created_at: '',
    updated_at: '',
    deleted_at: null,
    cnpj: {
      id: 0,
      company_id: 0,
      activity_id: 0,
      cnpj: '',
      data_abertura: '',
      razao_social: '',
      nome_fantasia: '',
      porte: '',
      natureza_juridica: '',
      endereco_cidade: '',
      endereco_estado: '',
      endereco_logradouro: '',
      endereco_numero: '',
      endereco_complemento: '',
      endereco_bairro: '',
      endereco_cep: '',
      email: '',
      telefone: '',
      matriz_filial: '',
      capital_social: 0,
      situacao_cadastral: '',
      data_consulta: '',
      created_at: '',
      updated_at: '',
      deleted_at: null,
    },
    simples: {
      id: 0,
      company_id: 0,
      cnpj: '',
      simei: false,
      simei_situacao: '',
      simples: false,
      simples_situacao: '',
      data_consulta: '',
      created_at: '',
      updated_at: '',
    },
  },
});
