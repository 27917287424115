import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, ScrollView, Text } from 'native-base';
import { maskBr, validateBr } from 'js-brasil';
import { cpf as cpfFormater } from 'cpf-cnpj-validator';
import { find, get } from 'lodash';
import {
  ufs,
  defaultTextColors,
  defaultComponentsColors,
} from '../../../utilites/utilities';
import { userAtom } from '../../../Recoil/Atoms/user';
import { showToastMessage } from '../../../utilites/ToastProvider';
import FormInput from '../../../components/Global/FormInput';
import FormSelect from '../../../components/Global/FormSelect';
import { formalizationAtom } from '../../../Recoil/Atoms/formalization';
import { requestErrorHandler } from '../../../services/meishop';

const PersonalData = ({ handleNextState, handleSendStep }) => {
  const user = useRecoilValue(userAtom);
  const personal_data = useRecoilValue(formalizationAtom)['personal-data'];

  const [rg, setRg] = useState(personal_data.rg_numero);
  const [rgState, setRgState] = useState({});
  const [cpf, setCpf] = useState(cpfFormater.format(user.cpf));
  const [requestError, setRequestError] = useState('');
  const [rgIssuer, setRgIssuer] = useState(personal_data.rg_emissor);
  const [userTitle, setUserTitle] = useState(personal_data.titulo_de_eleitor);

  const validate = () => {
    if (!validateBr.titulo(userTitle)) {
      showToastMessage(
        'Título inválido',
        'O Título informado não é válido.',
        'warn'
      );
      return false;
    }
    if (!cpfFormater.isValid(cpf)) {
      showToastMessage('CPF inválido', 'O CPF informado não é válido.', 'warn');
      return false;
    }
    if (rg === '') {
      showToastMessage(
        'RG inválido',
        'Você precisa informar seu RG para continuar.',
        'warn'
      );
      return false;
    }
    if (rgIssuer === '') {
      showToastMessage(
        'Emissor inválido',
        'Você precisa informar o orgão emissor do seu RG para continuar.',
        'warn'
      );
      return false;
    }
    if (get(rgState, 'id', null) === null) {
      showToastMessage(
        'Estádo inválido',
        'Você precisa selecionar o estado em que emitiu seu RG para continuar.',
        'warn'
      );
      return false;
    }
    return true;
  };

  const fixNumberInput = (value) => {
    if (value === '') {
      return '';
    }

    if (value.match(/^\D+/g)) {
      return '';
    }
    return parseInt(value.replace(/^\D+/g, '')).toString();
  };

  const handleTitleBlur = () => {
    setUserTitle(maskBr.titulo(userTitle));
  };

  const handleSubmit = () => {
    const requestData = {};
    if (validate()) {
      requestData.rg_emissor = rgIssuer;
      requestData.rg_uf = get(rgState, 'sigla', 0);
      requestData.rg_numero = rg;
      requestData.cpf_numero = cpf.match(/\d/g).join('');
      requestData.titulo_de_eleitor = userTitle.replace(/^\D+/g, '');
      handleSendStep(requestData)
        .then(() => {
          handleNextState();
        })
        .catch((error) => {
          setRequestError(requestErrorHandler(error).replace(' <br />', ''));
        });
    }
  };

  useEffect(() => {
    const cachedUf = find(ufs(), ['sigla', get(personal_data, 'rg_uf', null)]);
    if (cachedUf) {
      setRgState(cachedUf);
    }
  }, []);

  return (
    <ScrollView
      px={[4, 8, 16, 20]}
      _contentContainerStyle={{
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      <Text
        fontSize="xl"
        lineHeight="md"
        textAlign="center"
        fontWeight="semibold"
        color={defaultTextColors.default}
      >
        Preencha com seus documentos
      </Text>
      <FormInput
        value={userTitle}
        keyboardType="numeric"
        setValue={setUserTitle}
        onBlur={handleTitleBlur}
        label="Número do seu título de eleitor"
      />
      <FormInput
        label="CPF"
        value={cpf}
        setValue={(value) => setCpf(cpfFormater.format(value))}
      />
      <FormInput
        label="RG"
        value={rg}
        setValue={(value) => setRg(fixNumberInput(value))}
      />
      <FormInput
        value={rgIssuer}
        setValue={setRgIssuer}
        label="Orgão emissor do RG"
      />
      <FormSelect
        options={ufs()}
        value={rgState}
        loading={false}
        loadingPlaceholder=""
        setValue={setRgState}
        label="Estado emissor do RG"
      />
      {requestError !== '' && (
        <Text
          mt={6}
          fontSize="sm"
          textAlign="center"
          color={defaultTextColors.error}
        >
          {requestError}
        </Text>
      )}
      <Button
        py={3}
        px={5}
        mt={2}
        mb={4}
        mx="auto"
        fontSize="md"
        rounded="full"
        onPress={handleSubmit}
        w={['full', 'full', 'full', '1/3']}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="md" fontWeight="medium" color="#FFF">
          Continuar
        </Text>
      </Button>
    </ScrollView>
  );
};

export default PersonalData;
