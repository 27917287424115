import React, { useState } from 'react';
import { Button, Flex, Text } from 'native-base';
import { defaultComponentsColors } from '../../utilites/utilities';
import Container from '../../components/Container';
import SimpleRequestHandler, {
  requestErrorHandler,
} from '../../services/meishop';
import { showToastMessage } from '../../utilites/ToastProvider';
import FormPasswordInput from '../../components/Global/FormPasswordInput';

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const handleButton = () => {
    if (currentPassword === '') {
      showToastMessage(
        'Senha atual inválida',
        'Você deve digitar sua senha atual para continuar.',
        'warn'
      );
      return;
    }
    if (newPassword.length < 6) {
      showToastMessage(
        'Tamanho inválido',
        'Sua nova senha deve possuir no mínimo 6 caracteres.',
        'warn'
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      showToastMessage(
        'Senhas diferentes',
        'Senha e confirmação da senha não são iguais.',
        'warn'
      );
      return;
    }
    setLoading(true);
    SimpleRequestHandler(`user/password`, 'PUT', {
      password: newPassword,
      current_password: currentPassword,
      password_confirmation: confirmPassword,
    })
      .then(() => {
        setLoading(false);
        showToastMessage('Sucesso', 'Senha alterada com sucesso.', 'success');
      })
      .catch((error) => {
        setLoading(false);
        showToastMessage('Erro', requestErrorHandler(error), 'error');
      });
  };

  return (
    <Container
      width="100%"
      justifyContent="flex-start"
      flexDirection="column"
      flexWrap="nowrap"
    >
      <Flex mt={2} bgColor="#FFF" p={4} borderRadius="2xl">
        <Flex flexDirection="column">
          <FormPasswordInput
            label="Senha atual"
            value={currentPassword}
            setValue={setCurrentPassword}
          />
          <FormPasswordInput
            label="Nova senha"
            value={newPassword}
            setValue={setNewPassword}
          />
          <Text
            mt={-1}
            mb={2}
            fontSize="xs"
            lineHeight="sm"
            color="rgba(44, 45, 73, 0.4)"
          >
            Sua senha deve possuir no mínimo 6 caracteres.
          </Text>
          <FormPasswordInput
            label="Confirmar nova senha"
            value={confirmPassword}
            setValue={setConfirmPassword}
          />
        </Flex>
      </Flex>
      <Button
        mt={6}
        py={2}
        rounded="full"
        isLoading={loading}
        onPress={handleButton}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="lg" fontWeight="semibold" color="#FFF">
          {loading ? 'Alterando senha...' : 'Confirmar alteração'}
        </Text>
      </Button>
    </Container>
  );
};

export default ChangePassword;
