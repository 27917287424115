import React, { useEffect, useState } from 'react';
import { get, uniqBy } from 'lodash';
import { Center, Flex, FlatList, ScrollView } from 'native-base';
import { useRecoilValue } from 'recoil';
import { Dimensions } from 'react-native';
import MiniCards from './MiniCards';
import SimpleRequestHandler from '../../services/meishop';
import { ampLogEvent } from '../../utilites/utilities';
import { userAtom } from '../../Recoil/Atoms/user';
import CircleSnail from '../../components/Global/Loading';
import { themeAtom } from '../../Recoil/Atoms/theme';

const CategoryFeed = ({ route }) => {
  const windowWidth = Dimensions.get('window').width;

  const userId = useRecoilValue(userAtom).id;
  const colors = useRecoilValue(themeAtom).layout;
  const category = get(route, 'params.category', '');

  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const fetchCategoryPosts = () => {
    SimpleRequestHandler(
      `tipsforyou/feed/${category}?label_name=lista_default&offset=${currentPage}&limit=${
        currentPage + 20
      }`,
      'GET'
    ).then(({ data }) => {
      setPosts(uniqBy([...posts, ...data], (o) => o.id));
      setLoading(false);
      setLoadingMore(false);
    });
  };

  const loadMorePost = () => {
    setLoadingMore(true);
    setCurrentPage(currentPage + 20);
  };

  useEffect(() => {
    fetchCategoryPosts(category);
  }, [currentPage]);

  useEffect(() => {
    if (userId !== 0) {
      ampLogEvent(userId, 'Content - View Category', {
        category,
      });
    }
  }, []);

  return (
    <Flex flex={1} flexDirection="column">
      {loading ? (
        <Center mt={12}>
          <CircleSnail size={60} thickness={4} color={colors.bgSecondary} />
        </Center>
      ) : (
        <Flex w="full" flex={1} alignItems="center" flexDirection="column">
          {windowWidth >= 768 ? (
            <ScrollView
              my={4}
              contentContainerStyle={{
                flexGrow: 1,
                flexWrap: 'wrap',
                flexDirection: 'row',
              }}
            >
              {posts.map((post, key) => (
                <MiniCards key={key.toString()} item={post} />
              ))}
            </ScrollView>
          ) : (
            <FlatList
              w="full"
              data={posts}
              renderItem={(props) => <MiniCards {...props} />}
              _contentContainerStyle={{ paddingBottom: 10 }}
            />
          )}
          {/* {posts.length >= 19 && ( */}
          {/*  <Button */}
          {/*    mt={4} */}
          {/*    size="lg" */}
          {/*    onPress={loadMorePost} */}
          {/*    isLoading={loadingMore} */}
          {/*    bgColor={colors.bgPrimary} */}
          {/*    isLoadingText="Carregando posts" */}
          {/*    _text={{ color: defaultTextColors.disabled }} */}
          {/*  > */}
          {/*    Carregar mais */}
          {/*  </Button> */}
          {/* )} */}
        </Flex>
      )}
    </Flex>
  );
};

export default CategoryFeed;
