import React from 'react';
import {
  S3_URL,
  // eslint-disable-next-line
} from '@env';
import { X, Menu, User } from 'react-native-feather';
import { useNavigation } from '@react-navigation/native';
import {
  Box,
  Image,
  HStack,
  VStack,
  StatusBar,
  Pressable,
  Badge,
} from 'native-base';
import { filter, get } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Platform } from 'react-native';
import Logo from '../../assets/Logo';
import UserMenu from '../Menu/UserMenu';
import HeaderMenu from './HeaderMenu';
import { servicesAtom } from '../../Recoil/Atoms/services';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { selectedCompanyAtom } from '../../Recoil/Atoms/selectedCompany';
import { servicesStatusAtom } from '../../Recoil/Atoms/servicesStatus';
import ServicesIcon from '../../utilites/ServicesIcon';
import { notificationsAtom } from '../../Recoil/Atoms/notifications';

const Header = ({
  sideBarOpen,
  showSideBar,
  showUserMenu,
  userMenuOpen,
  openAuth,
  isLogged,
  currentRoute,
}) => {
  const navigation = useNavigation();
  const theme = useRecoilValue(themeAtom);
  const services = useRecoilValue(servicesAtom);
  const userCompany = useRecoilValue(selectedCompanyAtom);
  const servicesState = useRecoilValue(servicesStatusAtom);
  const headerServices = filter(
    useRecoilValue(servicesAtom),
    (o) => o.layout.position === 2
  );
  const { image } = theme;
  const colors = theme.layout;

  const [notifications, setNotifications] = useRecoilState(notificationsAtom);
  const notificationsCounter = notifications.counter;

  const headerHeight = {
    ios: 32,
    android: 'auto',
    web: [20, 20, 20, 'auto'],
  };

  const handleScreenLoad = (path) => {
    showSideBar(false);
    if (path === 'notifications') {
      setNotifications({
        ...notifications,
        show: !notifications.show,
      });
    } else {
      setTimeout(() => {
        if (path === 'company_cnpj') {
          const companyId = get(userCompany, 'id', null);
          navigation.navigate(path, {
            id: companyId,
          });
        } else {
          navigation.navigate(path);
        }
      }, 100);
    }
  };

  if (Platform.OS === 'web') {
    document
      .querySelector('meta[name=theme-color]')
      .setAttribute('content', colors.bgSecondary);
    document.body.style.backgroundColor = colors.bgSecondary;
  }

  return (
    <Box
      zIndex={50}
      elevation={5}
      bgColor="rgba(0, 0, 0, 0)"
      height={sideBarOpen ? 'full' : headerHeight[Platform.OS]}
    >
      <StatusBar
        barStyle="light-content"
        backgroundColor={colors.bgSecondary}
      />
      <UserMenu
        openAuth={openAuth}
        isLogged={isLogged}
        isOpen={userMenuOpen}
        setOpen={showUserMenu}
        showSideBar={showSideBar}
        sideBarOpen={sideBarOpen}
      />
      <Box safeAreaTop backgroundColor={colors.bgSecondary} />
      <HStack
        px={4}
        pt={2}
        alignItems="center"
        justifyContent="space-between"
        display={['flex', 'flex', 'none']}
        pb={Platform.OS !== 'android' ? 2 : 4}
        h={Platform.OS === 'android' ? 'auto' : '20'}
      >
        <HStack
          space={1}
          padding={2}
          zIndex={60}
          display="flex"
          borderRadius={10}
          alignItems="center"
          bgColor={`rgba(255, 255, 255, ${sideBarOpen ? 1 : 0.1})`}
        >
          <Pressable onPress={() => showSideBar(!sideBarOpen)}>
            {sideBarOpen ? (
              <X stroke={colors.bgSecondary} height={24} />
            ) : (
              <Menu stroke="#FFF" height={24} />
            )}
          </Pressable>
        </HStack>
        <HStack space={4} alignItems="center">
          {image && image !== '' ? (
            <Image
              source={{ uri: `${S3_URL + image}` }}
              height={16}
              width={32}
              resizeMode="contain"
            />
          ) : (
            <Logo />
          )}
        </HStack>
        <HStack space={2} zIndex={60}>
          {notificationsCounter !== 0 && (
            <Badge
              mr={-5}
              mt={-2}
              position="relative"
              bgColor="#007fc6"
              rounded="999px"
              zIndex={1}
              alignSelf="flex-start"
              variant="solid"
              _text={{
                fontSize: 12,
              }}
            >
              {notificationsCounter}
            </Badge>
          )}
          <Pressable
            padding={2}
            borderRadius={10}
            flexDirection="row"
            bgColor={
              currentRoute === 'notifications'
                ? 'rgba(0, 0, 0, 0.2)'
                : 'rgba(255, 255, 255, 0.1)'
            }
            onPress={() => {
              if (currentRoute === 'notifications') {
                navigation.goBack();
              } else {
                handleScreenLoad('notifications');
              }
            }}
          >
            {ServicesIcon('notifications', 24, '#FFF')}
          </Pressable>
          {headerServices.map((service, key) => (
            <Pressable
              key={key.toString()}
              padding={2}
              borderRadius={10}
              flexDirection="row"
              bgColor={
                currentRoute === service.path
                  ? 'rgba(0, 0, 0, 0.2)'
                  : 'rgba(255, 255, 255, 0.1)'
              }
              onPress={() => {
                if (currentRoute === service.path) {
                  navigation.goBack();
                } else {
                  handleScreenLoad(service.path);
                }
              }}
            >
              {ServicesIcon(service.path, 24, '#FFF')}
            </Pressable>
          ))}
          <Pressable
            padding={2}
            borderRadius={10}
            flexDirection="row"
            bgColor={
              userMenuOpen ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.1)'
            }
            onPress={() => showUserMenu(!userMenuOpen)}
          >
            <User stroke="#FFF" />
          </Pressable>
        </HStack>
      </HStack>
      {sideBarOpen && (
        <Box flex={1} px={4} py={2} mb={4}>
          <HeaderMenu
            services={services}
            isLogged={isLogged}
            showSideBar={showSideBar}
            currentRoute={currentRoute}
            servicesState={servicesState}
            handleScreenLoad={handleScreenLoad}
          />
        </Box>
      )}
      <VStack
        w={80}
        pl={2}
        pr={6}
        flex={1}
        space={6}
        alignItems="center"
        display={['none', 'none', 'flex']}
      >
        {image && image !== '' ? (
          <Image
            source={{ uri: `${S3_URL + image}` }}
            height={24}
            width={250}
            resizeMode="contain"
          />
        ) : (
          <Logo height={50} width={250} />
        )}
        <HeaderMenu
          services={services}
          isLogged={isLogged}
          showSideBar={showSideBar}
          currentRoute={currentRoute}
          servicesState={servicesState}
          handleScreenLoad={handleScreenLoad}
        />
      </VStack>
    </Box>
  );
};

export default Header;
