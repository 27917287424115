import React from 'react';
import { Text, VStack } from 'native-base';

const CardRow = ({ label, value }) => (
  <VStack>
    <Text
      fontWeight="semibold"
      fontSize="xs"
      textAlign="left"
      color="rgba(44, 45, 73, 0.5)"
    >
      {label}
    </Text>
    <Text fontWeight="semibold" fontSize="sm" textAlign="left" color="#2c2d49">
      {value}
    </Text>
  </VStack>
);

export default CardRow;
