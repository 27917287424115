import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { formatWithMask, Masks } from 'react-native-mask-input';
import {
  Box,
  Button,
  Divider,
  Flex,
  Input,
  Pressable,
  ScrollView,
  Switch,
  Text,
} from 'native-base';
import { get } from 'lodash';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';
import { DatePickerModal } from 'react-native-paper-dates';
import { themeAtom } from '../../../Recoil/Atoms/theme';
import {
  defaultComponentsColors,
  defaultTextColors,
  validateEmail,
  validateUserAge,
} from '../../../utilites/utilities';
import { userAtom } from '../../../Recoil/Atoms/user';
import FormInput from '../../../components/Global/FormInput';
import CompanyDetailRow from '../../../components/Company/CompanyDetailRow';
import ServiceIcon from '../../../utilites/ServicesIcon';
import { showToastMessage } from '../../../utilites/ToastProvider';
import { formalizationAtom } from '../../../Recoil/Atoms/formalization';
import { requestErrorHandler } from '../../../services/meishop';

const Credentials = ({ handleNextState, handleSendStep }) => {
  const user = useRecoilValue(userAtom);
  const colors = useRecoilValue(themeAtom).layout;
  const { credentials } = useRecoilValue(formalizationAtom);
  const paperTheme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: colors.bgSecondary,
      background: '#f0f2f6',
    },
  };
  const [email, setEmail] = useState(user.email);
  const [fullName, setFullName] = useState(user.name);
  const [openDate, setOpenDate] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [requestError, setRequestError] = useState('');
  const [dataChanged, setDataChanged] = useState(false);
  const [govPassword, setGovPassword] = useState(credentials.senha);
  const [motherName, setMotherName] = useState(credentials.nome_mae);
  const [fatherName, setFatherName] = useState(credentials.nome_pai);
  const [cellphone, setCellphone] = useState(credentials.numero_telefone);
  const [hasGovAcess, setHasGovAcess] = useState(credentials.requer_criacao);
  const [date, setDate] = useState(new Date(get(user, 'date_of_birth', '')));

  const { masked, unmasked, obfuscated } = formatWithMask({
    text: cellphone,
    mask: Masks.BRL_PHONE,
    obfuscationCharacter: '-',
  });

  const onDismissSingle = useCallback(() => {
    setOpenDate(false);
  }, [setOpenDate]);

  const onConfirmSingle = useCallback(
    (params) => {
      setDataChanged(true);
      setDate(params.date);
      setOpenDate(false);
    },
    [setOpenDate, setDate]
  );

  const validateIfHasAccess = () => {
    if (!validateEmail(email)) {
      showToastMessage(
        'Email inválido',
        'O Email informado não é válido.',
        'warn'
      );
      return false;
    }
    if (unmasked.length !== 11) {
      showToastMessage(
        'Celular inválido',
        'O Celular informado não é válido.',
        'warn'
      );
      return false;
    }
    if (govPassword === '') {
      showToastMessage(
        'Senha inválida',
        'Você precisa informar sua senha de acesso ao acesso.gov.br para continuar.',
        'warn'
      );
      return false;
    }
    return true;
  };

  const validateIfDoesntHasAccess = () => {
    const splitedDate = dateOfBirth.split('/');
    if (!validateEmail(email)) {
      showToastMessage(
        'Email inválido',
        'O Email informado não é válido.',
        'warn'
      );
      return false;
    }
    if (unmasked.length !== 11) {
      showToastMessage(
        'Celular inválido',
        'O Celular informado não é válido.',
        'warn'
      );
      return false;
    }
    if (fullName === '') {
      showToastMessage(
        'Nome vazio',
        'Você precisa informar seu nome completo para continuar.',
        'warn'
      );
      return false;
    }
    if (!validateUserAge(splitedDate[2])) {
      showToastMessage(
        'Idade inválida',
        'Você precisa ter no mínimo 16 anos para continuar.',
        'warn'
      );
      return false;
    }
    if (motherName === '') {
      showToastMessage(
        'Nome da mãe vazio',
        'Você precisa informar o nome da sua mãe para continuar.',
        'warn'
      );
      return false;
    }
    if (fatherName === '') {
      showToastMessage(
        'Nome do pai vazio',
        'Você precisa informar o nome do seu pai para continuar.',
        'warn'
      );
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    const requestData = {};
    if (hasGovAcess) {
      if (validateIfHasAccess()) {
        requestData.email = email;
        requestData.nome = fullName;
        requestData.senha = govPassword;
        requestData.numero_telefone = masked;
        requestData.requer_criacao = !hasGovAcess;
        handleSendStep(requestData)
          .then(() => {
            handleNextState();
          })
          .catch((error) => {
            setRequestError(requestErrorHandler(error).replace(' <br />', ''));
          });
      }
    } else if (validateIfDoesntHasAccess()) {
      const splitedDate = dateOfBirth.split('/');
      requestData.email = email;
      requestData.nome = fullName;
      requestData.nome_mae = motherName;
      requestData.nome_pai = fatherName;
      requestData.numero_telefone = masked;
      requestData.requer_criacao = !hasGovAcess;
      requestData.data_nascimento = `${splitedDate[2]}-${splitedDate[1]}-${splitedDate[0]}`;
      handleSendStep(requestData)
        .then(() => {
          handleNextState();
        })
        .catch((error) => {
          setRequestError(requestErrorHandler(error).replace(' <br />', ''));
        });
    }
  };

  useEffect(() => {
    if (user.date_of_birth !== '') {
      if (dataChanged) {
        setDateOfBirth(new Intl.DateTimeFormat('pt-BR').format(date));
      } else {
        setDateOfBirth(
          new Intl.DateTimeFormat('pt-BR').format(
            date.setDate(date.getDate() + 1)
          )
        );
      }
    }
  }, [date]);

  useEffect(() => {
    setHasGovAcess(!credentials.requer_criacao);
  }, []);

  return (
    <ScrollView
      px={[4, 8, 16, 20]}
      _contentContainerStyle={{
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      <Text
        fontSize="2xl"
        textAlign="center"
        fontWeight="semibold"
        color={defaultTextColors.default}
      >
        Então vamos lá!
      </Text>
      <Flex mt={4} flexDirection="column">
        <Text fontSize="md" color={defaultTextColors.default}>
          Você tem conta no Acesso.gov?
        </Text>
        <Flex mt={4} flexDirection="row">
          <Text
            mr={2}
            fontSize="md"
            textAlign="center"
            color={defaultTextColors.default}
          >
            Não
          </Text>
          <Switch
            onTrackColor={defaultTextColors.disabled}
            offTrackColor={defaultTextColors.disabled}
            offThumbColor={defaultTextColors.secondary}
            onThumbColor={defaultComponentsColors.success}
            isChecked={hasGovAcess}
            onToggle={() => setHasGovAcess(!hasGovAcess)}
          />
          <Text
            ml={2}
            fontSize="md"
            textAlign="center"
            color={defaultTextColors.default}
          >
            Sim
          </Text>
        </Flex>
      </Flex>
      <Divider my={2} w="100%" tickness={1} bg="#f0f2f6" />
      <FormInput label="Email" value={email} setValue={setEmail} />
      <FormInput
        label="Celular"
        value={masked}
        keyboardType="numeric"
        setValue={setCellphone}
      />
      {hasGovAcess ? (
        <Flex flexDirection="column">
          <FormInput
            label="Sua senha no acesso.gov.br"
            value={govPassword}
            setValue={setGovPassword}
          />
        </Flex>
      ) : (
        <Flex flexDirection="column">
          <FormInput
            label="Nome completo"
            value={fullName}
            setValue={setFullName}
          />
          <PaperProvider theme={paperTheme}>
            <DatePickerModal
              date={date}
              mode="single"
              locale="pt-BR"
              visible={openDate}
              saveLabel="Continuar"
              label="Data de nascimento"
              onDismiss={onDismissSingle}
              onConfirm={onConfirmSingle}
              validRange={{
                endDate: new Date(),
              }}
            />
          </PaperProvider>
          <CompanyDetailRow label="Data de nascimento" noDivisor />
          <Pressable mr={2} onPress={() => setOpenDate(true)}>
            <Input
              mt={-4}
              pl={4}
              size="lg"
              variant="rounded"
              value={dateOfBirth}
              onChange={() => {}}
              placeholder="Data de nascimento"
              onFocus={() => setOpenDate(true)}
              InputRightElement={
                <Box mr={2}>
                  {ServiceIcon('calendar', 24, defaultTextColors.default)}
                </Box>
              }
            />
          </Pressable>
          <FormInput
            label="Nome da mãe"
            value={motherName}
            setValue={setMotherName}
          />
          <FormInput
            label="Nome da pai"
            value={fatherName}
            setValue={setFatherName}
          />
        </Flex>
      )}
      {requestError !== '' && (
        <Text
          mt={6}
          fontSize="sm"
          textAlign="center"
          color={defaultTextColors.error}
        >
          {requestError}
        </Text>
      )}
      <Button
        py={3}
        px={5}
        mt={2}
        mb={4}
        mx="auto"
        fontSize="md"
        rounded="full"
        onPress={handleSubmit}
        w={['full', 'full', 'full', '1/3']}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="md" fontWeight="medium" color="#FFF">
          Continuar
        </Text>
      </Button>
    </ScrollView>
  );
};

export default Credentials;
