import React, { useEffect, useState } from 'react';
import Echo from 'laravel-echo';
import { cnpj as validator } from 'cpf-cnpj-validator';
import {
  Flex,
  FormControl,
  Heading,
  Input,
  Text,
  Button,
  ScrollView,
} from 'native-base';
import { Platform } from 'react-native';
import { useRecoilValue } from 'recoil';
import { useSelector } from 'react-redux';
import pusher from '../../services/pusher';
import SimpleRequestHandler from '../../services/meishop';
import {
  ampLogEvent,
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';
import { userAtom } from '../../Recoil/Atoms/user';
import CompanyBenefitItem from '../../components/Company/CompanyBenefitItem';

const NewCompany = ({ navigation }) => {
  const userId = useRecoilValue(userAtom).id;
  const [cnpj, setCnpj] = useState('');
  const [valid, setValid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [documentId, setDocumentId] = useState(null);
  const [startValidation, setStartValidation] = useState(false);

  const userToken = useSelector((state) => state.token.plainTextToken);

  const handleSubmit = () => {
    if (valid && !loading) {
      setLoading(true);
      SimpleRequestHandler('company/validate', 'POST', {
        cnpj: cnpj.replace(/[^0-9]/g, ''),
      })
        .then((response) => {
          setDocumentId(response.data.id);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleCnpjChange = (text) => {
    setCnpj(validator.format(text));
  };

  useEffect(() => {
    if (cnpj !== '' && cnpj.length === 18) {
      setValid(validator.isValid(cnpj));
    } else if (startValidation && cnpj !== '') {
      setValid(validator.isValid(cnpj));
    }
  }, [startValidation, cnpj]);

  useEffect(() => {
    if (documentId) {
      const echo = new Echo({
        client: pusher(userToken),
        broadcaster: 'pusher',
      });

      echo
        .private(`document.${documentId}`)
        .listen('.DocumentQueryUpdated', (data) => {
          setLoading(false);
          navigation.navigate('company_confirm', { data });
          echo.leave(`document.${documentId}`);
        });
    }

    return () => {};
    // eslint-disable-next-line
  }, [documentId]);

  const ampLogInputChange = async () => {
    await ampLogEvent(userId, 'Company Register - Input CNPJ', {
      cnpj,
      isValid: validator.isValid(cnpj),
    });
  };

  useEffect(() => {
    if (userId !== 0) {
      ampLogEvent(userId, 'Company Register - Start', {});
    }
  }, []);

  return (
    <FormControl
      px={4}
      flex={1}
      isRequired
      onBlur={() => setStartValidation(true)}
      isInvalid={startValidation ? valid !== null && !valid : false}
    >
      <ScrollView
        _contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 10,
        }}
      >
        <Flex flexDirection="column" bgColor="white" p={6} rounded="2xl">
          <Heading fontSize="md" color={defaultTextColors.default}>
            Preencha o CNPJ da empresa
          </Heading>
          <Input
            mt={4}
            pl={4}
            autoFocus
            value={cnpj}
            variant="rounded"
            keyboardType="numeric"
            onBlur={ampLogInputChange}
            placeholder="Insira o CNPJ"
            onChangeText={handleCnpjChange}
            size={Platform.OS === 'web' ? 'lg' : '2xl'}
          />
          <FormControl.ErrorMessage mt={1} ml={3}>
            O CNPJ informado não é válido.
          </FormControl.ErrorMessage>
          <Button
            py={3}
            px={5}
            mt={4}
            fontSize="md"
            rounded="full"
            isLoading={loading}
            onPress={handleSubmit}
            w={['full', 'full', 'full', '1/3']}
            bgColor={defaultComponentsColors.warning}
          >
            <Text fontSize="md" fontWeight="medium" color="#FFF">
              {loading ? 'Consultando empresa' : 'Continuar'}
            </Text>
          </Button>
        </Flex>
        <Flex
          mt={4}
          rounded="2xl"
          py={[2, 2, 2, 6]}
          flexDirection="column"
          bgColor={['transparent', 'transparent', 'transparent', 'white']}
        >
          <Heading ml={4} fontSize="md" color={defaultTextColors.default}>
            Ao cadastrar seu CNPJ aqui você tem:
          </Heading>
          <Flex
            flexWrap={['nowrap', 'nowrap', 'nowrap', 'wrap']}
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <CompanyBenefitItem
              item={{
                icon: 'cartao_cnpj',
                title: 'Cartão CNPJ e Certificado MEI',
                description: 'Acesse rápido e fácil',
              }}
            />
            <CompanyBenefitItem
              item={{
                icon: 'extrato_das',
                title: 'Acesso ao extrato DAS',
                description: 'para não deixar nenhuma pendência no seu MEI',
              }}
            />
            <CompanyBenefitItem
              item={{
                icon: 'boleto_das',
                title: 'Boleto DAS todo mês por email',
                description: 'para manter sua empresa em dia',
              }}
            />
            <CompanyBenefitItem
              item={{
                icon: 'declaracao_dasn',
                title: 'Declaração anual do MEI (DASN)',
              }}
            />
            <CompanyBenefitItem
              available={false}
              item={{
                icon: 'nfe',
                title: 'Emissão de notas fiscais',
              }}
            />
          </Flex>
        </Flex>
      </ScrollView>
    </FormControl>
  );
};

export default NewCompany;
