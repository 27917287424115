import React from 'react';
import { Box, Pressable, Text } from 'native-base';
import { MotiView } from 'moti';
import { useRecoilValue } from 'recoil';
import { find, get } from 'lodash';
import { themeAtom } from '../../Recoil/Atoms/theme';
import Icons from '../../utilites/ServicesIcon';

const ServiceItem = ({
  item,
  servicesState,
  index,
  currentRoute,
  handleScreenLoad,
}) => {
  const colors = useRecoilValue(themeAtom).layout;
  let isPublished = 0;

  if (item.path === 'Dashboard') {
    isPublished = 1;
  } else {
    isPublished =
      parseInt(
        get(find(servicesState, ['name', item.name]), 'is_published', 0),
        10
      ) !== 0;
  }

  return (
    <MotiView
      delay={index * 100}
      exit={{ opacity: 0 }}
      from={{ opacity: 0 }}
      key={index.toString()}
      animate={{ opacity: 1 }}
    >
      <Pressable
        my={2}
        rounded="lg"
        _hover={{
          bg:
            currentRoute === item.path
              ? 'rgba(0, 0, 0, 0.2)'
              : 'rgba(255, 255, 255, 0.1)',
        }}
        onPress={() => {
          if (isPublished) {
            handleScreenLoad(item.path);
          }
        }}
      >
        <Box
          p={4}
          rounded="lg"
          display="flex"
          flexDirection="row"
          alignItems="center"
          bg={
            currentRoute === item.path
              ? 'rgba(0, 0, 0, 0.2)'
              : 'rgba(255, 255, 255, 0.1)'
          }
        >
          {Icons(item.path, 20, colors.secondary)}
          <Text color="white" fontSize="lg" ml={4}>
            {item.name}
          </Text>
          {!isPublished && (
            <Box px={2} py={1} ml="auto" rounded="full" bgColor="#007fc6">
              <Text color="#f0f2f6" fontSize="xs" bold>
                Em breve
              </Text>
            </Box>
          )}
        </Box>
      </Pressable>
    </MotiView>
  );
};

export default ServiceItem;
