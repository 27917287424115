import React, { useEffect } from 'react';
import { get } from 'lodash';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Image,
  ScrollView,
  Text,
} from 'native-base';
import { useRecoilValue } from 'recoil';
import {
  ampLogEvent,
  defaultComponentsColors,
  defaultTextColors,
  handleOpenLink,
} from '../../utilites/utilities';
import { userAtom } from '../../Recoil/Atoms/user';

const Post = ({ navigation, route }) => {
  const userId = useRecoilValue(userAtom).id;
  const post = get(route, 'params', null);

  const hasPostData = get(post, 'medium', false);

  useEffect(() => {
    if (navigation && !hasPostData) {
      setTimeout(() => {
        navigation.navigate('Dashboard');
      }, 1000);
    }
  }, [navigation, hasPostData]);

  if (!hasPostData) {
    navigation.navigate('tips_for_you');
  }

  const fixExcerpt = (excerpt) => {
    let fixedExcerpt = excerpt.replace('<p>', '');
    fixedExcerpt = fixedExcerpt.replace('</p>', '');
    fixedExcerpt = fixedExcerpt.replace('&nbsp;', '');
    fixedExcerpt = fixedExcerpt.replace(/\u00a0/g, '');
    fixedExcerpt = fixedExcerpt.replace('&#8230;', '...');
    return fixedExcerpt;
  };

  const handleReadMore = async () => {
    await ampLogEvent(userId, 'Content - Click Through Post', {
      post: post.title,
    });
    await handleOpenLink(post.link);
  };

  useEffect(() => {
    if (userId !== 0) {
      ampLogEvent(userId, 'Content - View Post', {
        post: post.title,
      });
    }
  }, []);

  return (
    <Flex h="full" w="full" flexDirection="column">
      <ScrollView
        _contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 40,
          flexDirection: 'column',
        }}
      >
        <Box px={4}>
          <Image
            w="100%"
            rounded="2xl"
            h={[32, 32, 32, 40, 48]}
            alt="imagem destaque da noticia"
            source={{ uri: get(post, 'post-thumbnail', '') }}
          />
        </Box>
        <Heading px={4} mt={[4, 4, 6]} color={defaultTextColors.default}>
          {post.title}
        </Heading>
        <Text px={4} mt={[2, 2, 4]} color="rgba(44, 45, 73, 0.4)">
          {new Intl.DateTimeFormat('pt-BR').format(new Date(post.date))}
        </Text>
        <Divider px={4} mt={[4, 4, 6]} w="100%" thickness={1} color="#d7dce4" />
        <Text mt={4} px={4} flex={1} fontSize="md">
          {fixExcerpt(post.excerpt)}
        </Text>
      </ScrollView>
      <Box
        left={0}
        right={0}
        bottom={0}
        roundedTop="2xl"
        py={[4, 4, 4, 6]}
        position="absolute"
        bgColor={['#FFF', '#FFF', '#FFF', 'rgba(0, 0, 0, 0)']}
      >
        <Button
          py={2}
          px={2}
          w="2/3"
          mx="auto"
          rounded="full"
          onPress={handleReadMore}
          _text={{ color: '#FFF', fontSize: 'lg' }}
          isLoadingText="Carregando posts"
          bgColor={defaultComponentsColors.warning}
        >
          Ler mais
        </Button>
      </Box>
    </Flex>
  );
};

export default Post;
