import { Notifier } from 'react-native-notifier';
// eslint-disable-next-line import/no-cycle
import Toast from '../components/Global/Toast';

export const showToastMessage = (title, message, type) => {
  Notifier.showNotification({
    title,
    duration: 5000,
    description: message,
    Component: Toast,
    componentProps: {
      type,
    },
  });
};
