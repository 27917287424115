import React from 'react';
import { useRecoilValue } from 'recoil';
import { Pressable, Text } from 'native-base';
import { themeAtom } from '../../Recoil/Atoms/theme';
import ServicesIcon from '../../utilites/ServicesIcon';

const UserMenuItem = (props) => {
  const colors = useRecoilValue(themeAtom).layout;
  const { children, iconName, iconSize = 18 } = props;
  return (
    <Pressable
      px={4}
      w={48}
      borderTopWidth={1}
      flexDirection="row"
      borderTopColor="gray.200"
      borderBottomColor="gray.200"
      _pressed={{ bg: 'rgba(0, 0, 0, 0.1)' }}
      {...props}
    >
      {iconName && ServicesIcon(iconName, iconSize, colors.bgSecondary)}
      <Text ml={2} fontSize="sm" textAlign="left">
        {children}
      </Text>
    </Pressable>
  );
};

export default UserMenuItem;
