import React from 'react';
import { Button, Flex, Pressable, ScrollView, Text } from 'native-base';
import { useRecoilValue } from 'recoil';
import { find, get } from 'lodash';
import { maskBr } from 'js-brasil';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../../utilites/utilities';
import { formalizationAtom } from '../../../Recoil/Atoms/formalization';
import { themeAtom } from '../../../Recoil/Atoms/theme';

const Confirmation = ({ handleNextState, handleSendStep, setCurrentState }) => {
  const colors = useRecoilValue(themeAtom).layout;
  const formalization = useRecoilValue(formalizationAtom);
  const methods = get(formalization, 'availableMethods', []);
  const activities = get(formalization, 'availableActivities', []);
  const companyPrincipalActivity = find(activities, [
    'id',
    formalization['company-data'].atividade_principal_mei,
  ]);
  const companySecondaryActivity = find(activities, [
    'id',
    formalization['company-data'].atividade_secundaria_mei,
  ]);
  const companyMethods = methods.filter((item) =>
    formalization['company-data'].formas_atuacao_mei.includes(item.id)
  );

  const handleSubmit = () => {
    handleSendStep().then(() => {
      handleNextState();
    });
  };

  return (
    <ScrollView
      px={[4, 8, 16, 20]}
      _contentContainerStyle={{
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      <Text
        fontSize="xl"
        textAlign="center"
        fontWeight="semibold"
        color={defaultTextColors.default}
      >
        Quase lá
      </Text>
      <Text
        mt={4}
        fontSize="md"
        fontWeight="medium"
        color={defaultTextColors.default}
      >
        Agora revise suas informações:
      </Text>
      <Flex shadow={1} mt={4} bgColor="#FFF" rounded="lg" p={2}>
        <Flex flexDirection="row" justifyContent="space-between">
          <Text
            fontSize="lg"
            color={defaultTextColors.default}
            fontWeight="bold"
          >
            Credenciais
          </Text>
          <Pressable
            py={1}
            px={2}
            bgColor={colors.bgSecondary}
            rounded="full"
            onPress={() => setCurrentState(2)}
          >
            <Text fontSize="sm" color="#FFF" fontWeight="medium">
              Editar
            </Text>
          </Pressable>
        </Flex>
        <Flex mt={2} flexDirection="row">
          <Flex w="1/2" flexDirection="column" pr={2}>
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Email
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization.credentials.email}
            </Text>
          </Flex>
          <Flex w="1/2" flexDirection="column">
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Celular
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization.credentials.numero_telefone}
            </Text>
          </Flex>
        </Flex>
        {formalization.credentials.requer_criacao ? (
          <>
            <Flex mt={2} flexDirection="row">
              <Flex w="1/2" flexDirection="column" pr={2}>
                <Text
                  fontSize="md"
                  color="rgba(44, 45, 73, 0.4)"
                  fontWeight="semibold"
                >
                  Nome da mãe
                </Text>
                <Text
                  mt={1}
                  fontSize="md"
                  rounded="full"
                  fontWeight="semibold"
                  color={defaultTextColors.secondary}
                >
                  {formalization.credentials.nome_mae}
                </Text>
              </Flex>
              <Flex w="1/2" flexDirection="column">
                <Text
                  fontSize="md"
                  color="rgba(44, 45, 73, 0.4)"
                  fontWeight="semibold"
                >
                  Nome do pai
                </Text>
                <Text
                  mt={1}
                  fontSize="md"
                  rounded="full"
                  fontWeight="semibold"
                  color={defaultTextColors.secondary}
                >
                  {formalization.credentials.nome_pai}
                </Text>
              </Flex>
            </Flex>
            <Flex flexDirection="column" pr={2}>
              <Text
                fontSize="md"
                color="rgba(44, 45, 73, 0.4)"
                fontWeight="semibold"
              >
                Data de nascimento
              </Text>
              <Text
                mt={1}
                fontSize="md"
                rounded="full"
                fontWeight="semibold"
                color={defaultTextColors.secondary}
              >
                {formalization.credentials.data_nascimento}
              </Text>
            </Flex>
          </>
        ) : (
          <Flex flexDirection="column" pr={2}>
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Senha
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization.credentials.senha}
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex shadow={1} mt={2} bgColor="#FFF" rounded="lg" p={2}>
        <Flex flexDirection="row" justifyContent="space-between">
          <Text
            fontSize="lg"
            color={defaultTextColors.default}
            fontWeight="bold"
          >
            Dados da empresa
          </Text>
          <Pressable
            py={1}
            px={2}
            bgColor={colors.bgSecondary}
            rounded="full"
            onPress={() => setCurrentState(3)}
          >
            <Text fontSize="sm" color="#FFF" fontWeight="medium">
              Editar
            </Text>
          </Pressable>
        </Flex>
        <Flex mt={2} flexDirection="row">
          <Flex w="1/2" flexDirection="column" pr={2}>
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Nome da empresa
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization['company-data'].nome_empresarial}
            </Text>
          </Flex>
          <Flex w="1/2" flexDirection="column">
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Valor em caixa
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(formalization['company-data'].capital)}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column" pr={2}>
          <Text
            fontSize="md"
            color="rgba(44, 45, 73, 0.4)"
            fontWeight="semibold"
          >
            Ocupação principal
          </Text>
          <Text
            mt={1}
            fontSize="md"
            rounded="full"
            fontWeight="semibold"
            color={defaultTextColors.secondary}
          >
            {get(companyPrincipalActivity, 'item', '')}
          </Text>
        </Flex>
        {companySecondaryActivity && (
          <Flex flexDirection="column" pr={2}>
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Ocupação secundária
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {get(companySecondaryActivity, 'item', '')}
            </Text>
          </Flex>
        )}
        <Flex flexDirection="column" pr={2}>
          <Text
            fontSize="md"
            color="rgba(44, 45, 73, 0.4)"
            fontWeight="semibold"
          >
            Formas de atuação
          </Text>
          {companyMethods.map((method, key) => (
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              key={key.toString()}
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {method.item}
            </Text>
          ))}
        </Flex>
      </Flex>
      <Flex shadow={1} mt={2} bgColor="#FFF" rounded="lg" p={2}>
        <Flex flexDirection="row" justifyContent="space-between">
          <Text
            fontSize="lg"
            color={defaultTextColors.default}
            fontWeight="bold"
          >
            Seus dados
          </Text>
          <Pressable
            py={1}
            px={2}
            bgColor={colors.bgSecondary}
            rounded="full"
            onPress={() => setCurrentState(4)}
          >
            <Text fontSize="sm" color="#FFF" fontWeight="medium">
              Editar
            </Text>
          </Pressable>
        </Flex>
        <Flex flexDirection="column" pr={2}>
          <Text
            fontSize="md"
            color="rgba(44, 45, 73, 0.4)"
            fontWeight="semibold"
          >
            CPF
          </Text>
          <Text
            mt={1}
            fontSize="md"
            rounded="full"
            fontWeight="semibold"
            color={defaultTextColors.secondary}
          >
            {maskBr.cpf(formalization['personal-data'].cpf_numero)}
          </Text>
        </Flex>
        <Flex flexDirection="column" pr={2}>
          <Text
            fontSize="md"
            color="rgba(44, 45, 73, 0.4)"
            fontWeight="semibold"
          >
            Título de eleitor
          </Text>
          <Text
            mt={1}
            fontSize="md"
            rounded="full"
            fontWeight="semibold"
            color={defaultTextColors.secondary}
          >
            {formalization['personal-data'].titulo_de_eleitor}
          </Text>
        </Flex>
        <Flex mt={2} flexDirection="row">
          <Flex w="1/2" flexDirection="column" pr={2}>
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              RG
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization['personal-data'].rg_numero}
            </Text>
          </Flex>
          <Flex w="1/2" flexDirection="column">
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Orgão emissor
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization['personal-data'].rg_emissor}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column" pr={2}>
          <Text
            fontSize="md"
            color="rgba(44, 45, 73, 0.4)"
            fontWeight="semibold"
          >
            Estado de emissão
          </Text>
          <Text
            mt={1}
            fontSize="md"
            rounded="full"
            fontWeight="semibold"
            color={defaultTextColors.secondary}
          >
            {formalization['personal-data'].rg_uf}
          </Text>
        </Flex>
      </Flex>
      <Flex shadow={1} mt={2} bgColor="#FFF" rounded="lg" p={2}>
        <Flex flexDirection="row" justifyContent="space-between">
          <Text
            fontSize="lg"
            color={defaultTextColors.default}
            fontWeight="bold"
          >
            Endereço
          </Text>
          <Pressable
            py={1}
            px={2}
            bgColor={colors.bgSecondary}
            rounded="full"
            onPress={() => setCurrentState(5)}
          >
            <Text fontSize="sm" color="#FFF" fontWeight="medium">
              Editar
            </Text>
          </Pressable>
        </Flex>
        <Flex flexDirection="column" pr={2}>
          <Text
            fontSize="md"
            color="rgba(44, 45, 73, 0.4)"
            fontWeight="semibold"
          >
            CEP
          </Text>
          <Text
            mt={1}
            fontSize="md"
            rounded="full"
            fontWeight="semibold"
            color={defaultTextColors.secondary}
          >
            {formalization.address.cep}
          </Text>
        </Flex>
        <Flex mt={2} flexDirection="row">
          <Flex w="1/2" flexDirection="column" pr={2}>
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Rua
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization.address.logradouro}
            </Text>
          </Flex>
          <Flex w="1/2" flexDirection="column">
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Número
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization.address.numero}
            </Text>
          </Flex>
        </Flex>
        {formalization.address.complemento !== '' && (
          <Flex flexDirection="column" pr={2}>
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Complemento
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization.address.complemento}
            </Text>
          </Flex>
        )}
        <Flex mt={2} flexDirection="row">
          <Flex w="1/2" flexDirection="column" pr={2}>
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Bairro
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization.address.bairro}
            </Text>
          </Flex>
          <Flex w="1/2" flexDirection="column">
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Estado
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization.address.uf_sigla}
            </Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column" pr={2}>
          <Text
            fontSize="md"
            color="rgba(44, 45, 73, 0.4)"
            fontWeight="semibold"
          >
            Cidade
          </Text>
          <Text
            mt={1}
            fontSize="md"
            rounded="full"
            fontWeight="semibold"
            color={defaultTextColors.secondary}
          >
            {formalization.address.municipio_nome}
          </Text>
        </Flex>
      </Flex>
      {formalization.address.possui_endereco_comercial && (
        <Flex shadow={1} mt={2} bgColor="#FFF" rounded="lg" p={2}>
          <Flex flexDirection="row" justifyContent="space-between">
            <Text
              fontSize="lg"
              color={defaultTextColors.default}
              fontWeight="bold"
            >
              Endereço Comercial
            </Text>
            <Pressable
              py={1}
              px={2}
              bgColor={colors.bgSecondary}
              rounded="full"
              onPress={() => setCurrentState(5)}
            >
              <Text fontSize="sm" color="#FFF" fontWeight="medium">
                Editar
              </Text>
            </Pressable>
          </Flex>
          <Flex flexDirection="column" pr={2}>
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              CEP
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization.address.comercial_cep}
            </Text>
          </Flex>
          <Flex mt={2} flexDirection="row">
            <Flex w="1/2" flexDirection="column" pr={2}>
              <Text
                fontSize="md"
                color="rgba(44, 45, 73, 0.4)"
                fontWeight="semibold"
              >
                Rua
              </Text>
              <Text
                mt={1}
                fontSize="md"
                rounded="full"
                fontWeight="semibold"
                color={defaultTextColors.secondary}
              >
                {formalization.address.comercial_logradouro}
              </Text>
            </Flex>
            <Flex w="1/2" flexDirection="column">
              <Text
                fontSize="md"
                color="rgba(44, 45, 73, 0.4)"
                fontWeight="semibold"
              >
                Número
              </Text>
              <Text
                mt={1}
                fontSize="md"
                rounded="full"
                fontWeight="semibold"
                color={defaultTextColors.secondary}
              >
                {formalization.address.comercial_numero}
              </Text>
            </Flex>
          </Flex>
          {formalization.address.comercial_complemento !== '' && (
            <Flex flexDirection="column" pr={2}>
              <Text
                fontSize="md"
                color="rgba(44, 45, 73, 0.4)"
                fontWeight="semibold"
              >
                Complemento
              </Text>
              <Text
                mt={1}
                fontSize="md"
                rounded="full"
                fontWeight="semibold"
                color={defaultTextColors.secondary}
              >
                {formalization.address.comercial_complemento}
              </Text>
            </Flex>
          )}
          <Flex mt={2} flexDirection="row">
            <Flex w="1/2" flexDirection="column" pr={2}>
              <Text
                fontSize="md"
                color="rgba(44, 45, 73, 0.4)"
                fontWeight="semibold"
              >
                Bairro
              </Text>
              <Text
                mt={1}
                fontSize="md"
                rounded="full"
                fontWeight="semibold"
                color={defaultTextColors.secondary}
              >
                {formalization.address.comercial_bairro}
              </Text>
            </Flex>
            <Flex w="1/2" flexDirection="column">
              <Text
                fontSize="md"
                color="rgba(44, 45, 73, 0.4)"
                fontWeight="semibold"
              >
                Estado
              </Text>
              <Text
                mt={1}
                fontSize="md"
                rounded="full"
                fontWeight="semibold"
                color={defaultTextColors.secondary}
              >
                {formalization.address.comercial_uf_sigla}
              </Text>
            </Flex>
          </Flex>
          <Flex flexDirection="column" pr={2}>
            <Text
              fontSize="md"
              color="rgba(44, 45, 73, 0.4)"
              fontWeight="semibold"
            >
              Cidade
            </Text>
            <Text
              mt={1}
              fontSize="md"
              rounded="full"
              fontWeight="semibold"
              color={defaultTextColors.secondary}
            >
              {formalization.address.comercial_municipio_nome}
            </Text>
          </Flex>
        </Flex>
      )}
      <Button
        py={3}
        px={5}
        mt={6}
        mb={4}
        mx="auto"
        fontSize="md"
        rounded="full"
        onPress={handleSubmit}
        w={['full', 'full', 'full', '1/3']}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="md" fontWeight="medium" color="#FFF">
          Confirmar
        </Text>
      </Button>
    </ScrollView>
  );
};

export default Confirmation;
