import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = [];

export const { Types, Creators } = createActions({
  removeCompanies: [''],
  insertCompany: ['data'],
  insertCompanies: ['data'],
});

const insert = (state = INITIAL_STATE, action) => [...state, action.data];

const insertMany = (state, action) => [...action.data];

const remove = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [Types.INSERT_COMPANY]: insert,
  [Types.REMOVE_COMPANIES]: remove,
  [Types.INSERT_COMPANIES]: insertMany,
});
