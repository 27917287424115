import { createActions, createReducer } from 'reduxsauce';

export const INITIAL_STATE = [];

export const { Types, Creators } = createActions({
  removeServicesState: [''],
  insertServiceState: ['data'],
  insertServicesState: ['data'],
});

const insert = (state = INITIAL_STATE, action) => [...state, action.data];

const insertMany = (state, action) => [...action.data];

const remove = (state = INITIAL_STATE) => ({
  ...state,
  ...INITIAL_STATE,
});

export default createReducer(INITIAL_STATE, {
  [Types.INSERT_SERVICE_STATE]: insert,
  [Types.REMOVE_SERVICES_STATE]: remove,
  [Types.INSERT_SERVICES_STATE]: insertMany,
});
