import React, { useState, useEffect } from 'react';
import { Flex, Pressable, Text } from 'native-base';
import { Platform } from 'react-native';
import ServicesIcon from '../../utilites/ServicesIcon';

const DasFilterDropdown = ({ years, callback }) => {
  const [open, setOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState('');

  const handleSelect = (value) => {
    callback(value);
    setOpen(false);
    setSelectedYear(value);
  };

  useEffect(() => {
    if (selectedYear === '') {
      handleSelect(years[1]);
    }
    // eslint-disable-next-line
  }, [selectedYear]);

  return (
    <Flex flexDirection="column">
      <Pressable onPress={() => setOpen(!open)}>
        <Flex
          py={2}
          px={4}
          borderWidth={1}
          borderRadius="full"
          alignItems="center"
          flexDirection="row"
          borderColor="#d7dce4"
          justifyContent="space-between"
        >
          <Text
            bold
            color="#3c4858"
            fontSize="sm"
            pt={Platform.OS === 'web' ? 1 : 0}
            mr={4}
          >
            {selectedYear}
          </Text>
          {ServicesIcon('down', 18, '#60519a')}
        </Flex>
      </Pressable>
      {open && (
        <Flex
          zIndex={40}
          py={2}
          px={4}
          position="absolute"
          top={10}
          flexDirection="column"
          shadow={2}
          rounded="lg"
          bgColor="#FFF"
        >
          {years.map((year, key) => (
            <Pressable
              my={1}
              onPress={() => handleSelect(year)}
              key={key.toString()}
            >
              <Flex flexDirection="row" justifyContent="space-between">
                <Text bold color="#3c4858" fontSize="sm" mr={4}>
                  {year}
                </Text>
                {selectedYear === year && ServicesIcon('check', 18, '#60519a')}
              </Flex>
            </Pressable>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default DasFilterDropdown;
