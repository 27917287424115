import React from 'react';
import { Box, Button, FlatList, Flex, Heading } from 'native-base';
import { Platform } from 'react-native';
import { defaultTextColors } from '../../utilites/utilities';
import CircleSnail from '../../components/Global/Loading';
import MiniCards from './MiniCards';

const FeedCategory = ({ category, colors, handleOpenCategory }) => {
  if (category.posts.length >= 1) {
    return (
      <Flex w="full" flexDirection="column">
        <Flex
          mb={2}
          w="100%"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Heading
            fontSize="2xl"
            alignSelf="center"
            color={defaultTextColors.default}
          >
            {category.category}
          </Heading>
          <Button
            py={2}
            px={4}
            rounded="full"
            variant="outline"
            onPress={() => handleOpenCategory(category.category)}
            borderColor="#d7dce4"
            _text={{
              color: defaultTextColors.default,
            }}
            _hover={{
              backgroundColor: '#d7dce4',
              borderColor: defaultTextColors.default,
            }}
          >
            Ver mais
          </Button>
        </Flex>
        <Box w="100%" mb={2}>
          {category.posts && category.posts.length === 0 && (
            <CircleSnail size={40} thickness={4} color={colors.bgSecondary} />
          )}
          <FlatList
            horizontal
            data={category.posts}
            renderItem={({ item, index }) => (
              <MiniCards
                item={item}
                index={index}
                key={item.id}
                isCategoryShowUp
              />
            )}
            keyExtractor={(item) => item.id.toString()}
            showsHorizontalScrollIndicator={Platform.OS === 'web'}
          />
        </Box>
      </Flex>
    );
  }

  return null;
};

export default FeedCategory;
