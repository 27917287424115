import React from 'react';
import {
  SOLUTIONS_URL,
  // eslint-disable-next-line
} from '@env';
import {
  Flex,
  Image,
  Heading,
  Text,
  Button,
  Box,
  ScrollView,
  Divider,
  VStack,
  Stack,
  Link,
} from 'native-base';
import {
  defaultComponentsColors,
  defaultTextColors,
  handleOpenLink,
} from '../../utilites/utilities';
import LogoImage from '../../assets/logosolution.svg';
import MultiImage from '../../assets/multi.png';

const Solutions = () => {
  const handleOpen = async () => {
    await handleOpenLink(SOLUTIONS_URL);
  };

  return (
    <Flex flex={1} flexDirection="column">
      <ScrollView
        px={4}
        _contentContainerStyle={{
          flexGrow: 1,
          paddingBottom: 20,
          flexWrap: 'nowrap',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Image
          my={2}
          source={LogoImage}
          h={[70, 90, 100, 110]}
          w={[110, 130, 130, 150]}
          alt="Imagem Logo"
          resizeMode="contain"
        />
        <Heading
          mt={4}
          color={defaultTextColors.secondary}
          fontSize={['2xl', '2xl', '2xl', '3xl']}
          textAlign={['left', 'left', 'left', 'left']}
        >
          Aproveite os descontos e serviços exclusivos para assinantes
        </Heading>
        <Text
          mt={4}
          fontWeight="medium"
          color="rgba(44, 45, 73, 0.4)"
          fontSize={['md', 'md', 'md', 'lg']}
          textAlign={['left', 'left', 'left', 'left']}
        >
          No MEiShop Soluções você encontra benefícios para você, seu negócio e
          sua família
        </Text>

        <Stack direction={'row'} space={4}>
          <Box
            bgColor={['#FFF', '#FFF', '#FFF', '#FFF']}
            border="1"
            borderRadius="3xl"
            maxW={['100%', '100%', '100%', '50%', '100%', '50%']}
            p={2}
            mt={8}
          >
            <Flex direction={['col', 'row']}>
              <Image
                my={2}
                source={MultiImage}
                h={[120, 90, 100, 130, 160, 230]}
                w={['full', 130, 130, 150, 180, 250]}
                alt="Imagem Logo"
                resizeMode="contain"
              />
              <VStack
                space="1"
                maxW={['100%', '100%', '100%', '50%', '50%', '50%']}
                maxH={['70%', '90%', '100%', '140%']}
              >
                <Heading
                  mt={1}
                  color={defaultTextColors.secondary}
                  fontSize={['2xl', 'xl', 'xl', '2xl', 'xl', '2xl']}
                  textAlign={['left', 'left', 'left', 'left']}
                >
                  Loja Multi
                </Heading>
                <Text
                  mt={1}
                  fontWeight="medium"
                  color="rgba(44, 45, 73, 0.4)"
                  fontSize={['sm', 'sm', 'md', 'md', 'xs', 'md']}
                  textAlign={['left', 'left', 'left', 'left']}
                >
                  MEI, aproveite os descontos especiais em eletrônicos
                  Multilaser. As vantagens são exclusivas para assinantes. 
                  {'\n'} 
                  Não esqueça de usar o cupom: meishop
                </Text>
                <Box
                  roundedTop="2xl"
                  py={[4, 4, 4, 6]}
                  bgColor={['#FFF', '#FFF', '#FFF', 'rgba(0, 0, 0, 0)']}
                >
                  <Link
                    href="https://www.multilaser.com.br/minhaloja#meishop"
                    isExternal
                    _text={{
                      color: 'blue.400',
                    }}
                  >
                    <Button
                      py={2}
                      px={2}
                      w="full"
                      rounded="full"
                      _text={{ color: '#FFF', fontSize: 'lg' }}
                      bgColor={defaultComponentsColors.warning}
                    >
                      Acesse agora!
                    </Button>
                  </Link>
                </Box>
              </VStack>
            </Flex>
          </Box>
         
        </Stack>
      </ScrollView>
    </Flex>
  );
};

export default Solutions;
