import { atom } from 'recoil';

export const userAtom = atom({
  key: 'userAtom',
  default: {
    id: 0,
    name: '',
    email: '',
    whitelabel_customer: {},
  },
});
