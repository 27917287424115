import React from 'react';
import CompanyNeedRoute from '../../navigation/CompanyNeedRoute';
import DasStatusController from '../../components/Das/DasStatusController';

const Das = ({ navigation }) => (
  <CompanyNeedRoute px={4}>
    <DasStatusController navigation={navigation} />
  </CompanyNeedRoute>
);

export default Das;
