import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import mei, { INITIAL_STATE as INITIAL_MEI_STATE } from './Ducks/mei';
import user, { INITIAL_STATE as INITIAL_USER_STATE } from './Ducks/user';
import theme, { INITIAL_STATE as INITIAL_THEME_STATE } from './Ducks/theme';
import companies, {
  INITIAL_STATE as INITIAL_COMPANIES_STATE,
} from './Ducks/companies';
import services, {
  INITIAL_STATE as INITIAL_SERVICES_STATE,
} from './Ducks/services';
import servicesState, {
  INITIAL_STATE as INITIAL_SERVICES_STATE_STATE,
} from './Ducks/servicesState';
import token, { INITIAL_STATE as INITIAL_TOKEN_STATE } from './Ducks/token';
import amplitude, {
  INITIAL_STATE as INITIAL_AMP_STATE,
} from './Ducks/amplitude';
import loading, {
  INITIAL_STATE as INITIAL_LOADING_STATE,
} from './Ducks/loading';

const root = combineReducers({
  mei,
  user,
  token,
  theme,
  loading,
  services,
  companies,
  amplitude,
  servicesState,
});

const initialState = {
  mei: INITIAL_MEI_STATE,
  user: INITIAL_USER_STATE,
  token: INITIAL_TOKEN_STATE,
  theme: INITIAL_THEME_STATE,
  amplitude: INITIAL_AMP_STATE,
  loading: INITIAL_LOADING_STATE,
  services: INITIAL_SERVICES_STATE,
  companies: INITIAL_COMPANIES_STATE,
  servicesState: INITIAL_SERVICES_STATE_STATE,
};

const store = configureStore({
  reducer: root,
  preloadedState: initialState,
});

export default store;
