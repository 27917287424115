import React from 'react';
import { Divider, Flex, Input, Text } from 'native-base';
import { defaultTextColors } from '../../utilites/utilities';

const CompanyDetailRow = ({
  label,
  value,
  noDivisor = false,
  setValue = () => {},
  editing = false,
}) => (
  <Flex flexDirection="column" my={noDivisor ? 2 : 0}>
    <Text fontSize="md" color="rgba(44, 45, 73, 0.4)" fontWeight="semibold">
      {label}
    </Text>
    {editing ? (
      <Input
        mt={1}
        fontSize="md"
        value={value}
        rounded="full"
        fontWeight="semibold"
        color={defaultTextColors.secondary}
        onChangeText={(text) => setValue(text)}
      />
    ) : (
      <Text
        mt={1}
        fontSize="md"
        color={defaultTextColors.secondary}
        fontWeight="semibold"
      >
        {value}
      </Text>
    )}
    {!noDivisor && <Divider my={2} w="100%" tickness={1} bg="#f0f2f6" />}
  </Flex>
);

export default CompanyDetailRow;
