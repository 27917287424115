import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, ScrollView, Text } from 'native-base';
import { find, get, xorBy } from 'lodash';
import FormInput from '../../../components/Global/FormInput';
import FormCurrencyInput from '../../../components/Global/FormCurrencyInput';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../../utilites/utilities';
import FormSelect from '../../../components/Global/FormSelect';
import { showToastMessage } from '../../../utilites/ToastProvider';
import { formalizationAtom } from '../../../Recoil/Atoms/formalization';
import { requestErrorHandler } from '../../../services/meishop';

const CompanyData = ({ handleSendStep, handleNextState }) => {
  const formalizationData = useRecoilValue(formalizationAtom);
  const methods = get(formalizationData, 'availableMethods', []);
  const company_data = get(formalizationData, 'company-data', {});
  const activities = get(formalizationData, 'availableActivities', []);

  const [activity, setActivity] = useState({});
  const [requestError, setRequestError] = useState('');
  const [companyMethods, setCompanyMethods] = useState([]);
  const [secondaryActivity, setSecondaryActivity] = useState({});
  const [companyName, setCompanyName] = useState(company_data.nome_empresarial);
  const [companyValue, setCompanyValue] = useState(
    company_data.capital === '' ? null : parseInt(company_data.capital)
  );

  const handleSubmit = () => {
    const requestData = {};
    if (companyName === '') {
      showToastMessage(
        'Nome inválido',
        'Você precisa informar um nome para sua empresa.',
        'warn'
      );
    } else if (companyValue === 0) {
      showToastMessage(
        'Valor inválido',
        'O valor mínimo para caixa é de R$ 1.',
        'warn'
      );
    } else if (get(activity, 'id', null) === null) {
      showToastMessage(
        'Atividade inválida',
        'Selecione uma ocupação principal para sua empresa.',
        'warn'
      );
    } else if (companyMethods.length === 0) {
      showToastMessage(
        'Forma de atuação inválida',
        'Selecione pelo menos uma forma de atuação.',
        'warn'
      );
    } else {
      requestData.nome_empresarial = companyName;
      requestData.capital = companyValue.toString();
      requestData.atividade_principal_mei = get(activity, 'id', '');
      if (get(secondaryActivity, 'id', null)) {
        requestData.atividade_secundaria_mei = get(secondaryActivity, 'id', '');
      }
      const fixedCompanyMethods = [];
      companyMethods.map((item) => {
        fixedCompanyMethods.push(item.id);
      });
      requestData.formas_atuacao_mei = fixedCompanyMethods;
      handleSendStep(requestData)
        .then(() => {
          handleNextState();
        })
        .catch((error) => {
          setRequestError(requestErrorHandler(error).replace(' <br />', ''));
        });
    }
  };

  const onChangeActivity = (value) => {
    setActivity(value);
  };

  const onChangeSecondaryActivity = (value) => {
    setSecondaryActivity(value);
  };

  const onChangeCompanyMethods = (value) => {
    setCompanyMethods(xorBy(companyMethods, [value], 'id'));
  };

  useEffect(() => {
    const cachedMethodsMei = get(company_data, 'formas_atuacao_mei', null);
    const cachedPrincipalActivity = get(
      company_data,
      'atividade_principal_mei',
      null
    );
    if (cachedPrincipalActivity) {
      setActivity(find(activities, ['id', cachedPrincipalActivity]));
    }
    const cachedSecondaryActivity = get(
      company_data,
      'atividade_secundaria_mei',
      null
    );
    if (cachedSecondaryActivity) {
      setSecondaryActivity(find(activities, ['id', cachedSecondaryActivity]));
    }
    if (cachedMethodsMei) {
      setCompanyMethods(
        methods.filter(({ id }) => cachedMethodsMei.includes(id))
      );
    }
  }, []);

  return (
    <ScrollView
      px={[4, 8, 16, 20]}
      _contentContainerStyle={{
        flexGrow: 1,
        flexDirection: 'column',
      }}
    >
      <Text
        fontSize="xl"
        lineHeight="md"
        textAlign="center"
        fontWeight="semibold"
        color={defaultTextColors.default}
      >
        Preencha os dados referentes a sua empresa
      </Text>
      <FormInput
        label="Nome da sua empresa"
        value={companyName}
        setValue={setCompanyName}
      />
      <FormCurrencyInput
        value={companyValue}
        setValue={setCompanyValue}
        label="Valor que sua empresa possui em caixa"
      />
      <FormSelect
        loading={false}
        value={activity}
        options={activities}
        setValue={onChangeActivity}
        label="Ocupação principal da sua empresa"
        loadingPlaceholder="Carregando atividades..."
      />
      <FormSelect
        loading={false}
        options={activities}
        value={secondaryActivity}
        setValue={onChangeSecondaryActivity}
        label="Ocupação secundária da sua empresa"
        loadingPlaceholder="Carregando atividades..."
      />
      <FormSelect
        isMulti
        loading={false}
        options={methods}
        label="Forma de atuação"
        value={secondaryActivity}
        selectedValues={companyMethods}
        setValue={onChangeCompanyMethods}
        onMultiSelect={onChangeCompanyMethods}
        loadingPlaceholder="Carregando métodos..."
      />
      {requestError !== '' && (
        <Text
          mt={6}
          fontSize="sm"
          textAlign="center"
          color={defaultTextColors.error}
        >
          {requestError}
        </Text>
      )}
      <Button
        py={3}
        px={5}
        mt={2}
        mb={4}
        mx="auto"
        fontSize="md"
        rounded="full"
        onPress={handleSubmit}
        w={['full', 'full', 'full', '1/3']}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="md" fontWeight="medium" color="#FFF">
          Continuar
        </Text>
      </Button>
    </ScrollView>
  );
};

export default CompanyData;
