import React from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { Platform } from 'react-native';
import { Box, VStack } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import UserMenuItem from './UserMenuItem';
import { userAtom } from '../../Recoil/Atoms/user';
import { handleLogout } from '../../utilites/utilities';
import SimpleRequestHandler from '../../services/meishop';
import { modalAtom } from '../../Recoil/Atoms/modal';
import { themeAtom } from '../../Recoil/Atoms/theme';
import { servicesAtom } from '../../Recoil/Atoms/services';
import { userCompaniesAtom } from '../../Recoil/Atoms/userCompanies';
import { notificationsAtom } from '../../Recoil/Atoms/notifications';
import { viewingCompanyAtom } from '../../Recoil/Atoms/viewingCompany';
import { servicesStatusAtom } from '../../Recoil/Atoms/servicesStatus';
import { selectedCompanyAtom } from '../../Recoil/Atoms/selectedCompany';
import { viewingCategoryAtom } from '../../Recoil/Atoms/viewingCategory';

const UserMenu = ({
  isOpen,
  setOpen,
  display = 'flex',
  showSideBar = () => {},
  sideBarOpen = false,
}) => {
  const navigation = useNavigation();
  const user = useRecoilValue(userAtom);
  const userName = user.name === '' ? user.name : user.name.split(' ')[0];

  // Reset Atoms Funcions
  const resetUser = useResetRecoilState(userAtom);
  const resetModal = useResetRecoilState(modalAtom);
  const resetTheme = useResetRecoilState(themeAtom);
  const resetServices = useResetRecoilState(servicesAtom);
  const resetUserCompanies = useResetRecoilState(userCompaniesAtom);
  const resetNotifications = useResetRecoilState(notificationsAtom);
  const resetViewingCompany = useResetRecoilState(viewingCompanyAtom);
  const resetServicesStatus = useResetRecoilState(servicesStatusAtom);
  const resetSelectedCompany = useResetRecoilState(selectedCompanyAtom);
  const resetViewingCategory = useResetRecoilState(viewingCategoryAtom);

  const clearAllRecoilStates = () => {
    resetUser();
    resetModal();
    resetTheme();
    resetServices();
    resetNotifications();
    resetUserCompanies();
    resetViewingCompany();
    resetServicesStatus();
    resetSelectedCompany();
    resetViewingCategory();
  };

  const requestLogout = () => {
    setOpen(false);
    SimpleRequestHandler('logout', 'POST').then(async () => {
      await handleLogout();
      clearAllRecoilStates();
    });
  };

  const handlePageChange = (page) => {
    setOpen(false);
    if (sideBarOpen) {
      showSideBar(false);
    }
    navigation.navigate(page);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Box
      w={48}
      shadow={4}
      bg="white"
      zIndex={80}
      elevation={8}
      flexDir="column"
      display={display}
      borderRadius={10}
      alignItems="center"
      position="absolute"
      mt={Platform.OS === 'web' ? [-1, -1, 10] : 24}
      top={Platform.OS === 'web' ? [16, 16, 1] : 4}
      right={Platform.OS === 'web' ? [4, 4, 0] : [2, 2, 2, 0]}
    >
      <VStack>
        <UserMenuItem
          py={3}
          zIndex={60}
          borderTopWidth={0}
          borderTopRadius={10}
        >
          {`Olá ${userName} 😄`}
        </UserMenuItem>
        <UserMenuItem
          py={3}
          zIndex={60}
          iconName="user"
          onPress={() => handlePageChange('profile')}
        >
          Meus dados
        </UserMenuItem>
        <UserMenuItem
          py={3}
          zIndex={60}
          iconName="logout"
          borderBottomRadius={10}
          onPress={requestLogout}
        >
          Sair
        </UserMenuItem>
      </VStack>
    </Box>
  );
};

export default UserMenu;
