import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import Welcome from './Steps/Welcome';
import Initial from './Steps/Initial';
import CompanyData from './Steps/CompanyData';
import Credentials from './Steps/Credentials';
import PersonalData from './Steps/PersonalData';
import SimpleRequestHandler from '../../services/meishop';
import Address from './Steps/Address';
import Confirmation from './Steps/Confirmation';
import Success from './Steps/Success';
import { formalizationAtom } from '../../Recoil/Atoms/formalization';

const FormalizationController = () => {
  const [currentState, setCurrentState] = useState(0);
  const [formalization, setFormalization] = useRecoilState(formalizationAtom);
  const stepsEnum = {
    0: 'welcome',
    1: 'initial',
    2: 'credentials',
    3: 'company-data',
    4: 'personal-data',
    5: 'address',
    6: 'confirmation',
    7: 'success',
  };

  const handleNextState = () => {
    setCurrentState(currentState + 1);
  };

  const handlePrevState = () => {
    setCurrentState(currentState - 1);
  };

  const handleSendStep = (data) => {
    const updatedData = { ...formalization };
    updatedData[stepsEnum[currentState]] = data;
    setFormalization(updatedData);
    return SimpleRequestHandler(
      `formalizacao/${formalization.id}/${stepsEnum[currentState]}`,
      'POST',
      data
    );
  };

  const steps = {
    welcome: (
      <Welcome
        setCurrentState={setCurrentState}
        handleNextState={handleNextState}
      />
    ),
    initial: (
      <Initial
        handleNextState={handleNextState}
        handlePrevState={handlePrevState}
      />
    ),
    credentials: (
      <Credentials
        handleSendStep={handleSendStep}
        handleNextState={handleNextState}
        handlePrevState={handlePrevState}
      />
    ),
    'company-data': (
      <CompanyData
        handleSendStep={handleSendStep}
        handleNextState={handleNextState}
        handlePrevState={handlePrevState}
      />
    ),
    'personal-data': (
      <PersonalData
        handleSendStep={handleSendStep}
        handleNextState={handleNextState}
        handlePrevState={handlePrevState}
      />
    ),
    address: (
      <Address
        handleSendStep={handleSendStep}
        handleNextState={handleNextState}
        handlePrevState={handlePrevState}
      />
    ),
    confirmation: (
      <Confirmation
        handleSendStep={handleSendStep}
        setCurrentState={setCurrentState}
        handleNextState={handleNextState}
        handlePrevState={handlePrevState}
      />
    ),
    success: <Success />,
  };

  return steps[stepsEnum[currentState]];
};

export default FormalizationController;
