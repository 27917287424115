import React, { useState } from 'react';
import { Flex, Text } from 'native-base';
import CurrencyInput from 'react-native-currency-input';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';

const FormCurrencyInput = ({
  label,
  value,
  isValid,
  setValue,
  onBlur = () => {},
  onFocus = () => {},
  setIsValid = () => {},
  disabled = false,
  validate = false,
  validation = () => false,
  customErroMessage = null,
}) => {
  const [startValidation, setStartValidation] = useState(false);

  const validationColors = {
    true: defaultTextColors.success,
    false: defaultTextColors.error,
  };

  const doValidation = (text) => {
    setStartValidation(true);
    if (validate) {
      setIsValid(validation(text));
    } else {
      setIsValid(true);
    }
  };

  const handleInputChange = (text) => {
    setValue(text);
    if (startValidation) {
      doValidation(text);
    }
  };

  return (
    <Flex flexDirection="column" my={2}>
      <Text fontSize="md" color="rgba(44, 45, 73, 0.4)" fontWeight="semibold">
        {label}
      </Text>
      <CurrencyInput
        disabled={disabled}
        prefix="R$ "
        delimiter="."
        separator=","
        precision={2}
        value={value}
        onFocus={onFocus}
        onChangeValue={handleInputChange}
        onBlur={async (params) => {
          onBlur(params);
          doValidation(value);
        }}
        style={{
          padding: 10,
          marginTop: 8,
          fontSize: 16,
          borderWidth: 1,
          borderRadius: 20,
          fontWeight: '600',
          borderStyle: 'solid',
          borderColor: startValidation ? validationColors[isValid] : '#d4d4d4',
          backgroundColor: disabled ? defaultComponentsColors.disabled : '#FFF',
          color: disabled
            ? defaultTextColors.disabled
            : defaultTextColors.secondary,
        }}
      />
      {validate && startValidation && !isValid && (
        <Text mt={2} ml={2} color={defaultTextColors.error}>
          {customErroMessage || `${label} inválido.`}
        </Text>
      )}
    </Flex>
  );
};

export default FormCurrencyInput;
