import React from 'react';
import { Box, Button, Flex, Text } from 'native-base';
import {
  defaultComponentsColors,
  defaultTextColors,
} from '../../utilites/utilities';
import servicesIcon from '../../utilites/ServicesIcon';

const DasnItem = ({ item, navigation }) => {
  const statusDictiornary = {
    DELIVERED: 'Finalizada',
    DELIVERED_PROCESSING: '',
    DELIVERED_PROCESSING_OVERDUE: '',
    NOT_DELIVERED: 'No prazo',
    NOT_DELIVERED_OVERDUE: 'Atrasado',
    NOT_DELIVERED_BLOCKED: 'No prazo',
    NOT_DELIVERED_BLOCKED_OVERDUE: 'Atrasado',
  };

  const statusBgColors = {
    DELIVERED: 'rgba(0, 168, 179, 0.15)',
    DELIVERED_PROCESSING: 'rgba(0, 0, 0, 0)',
    DELIVERED_PROCESSING_OVERDUE: 'rgba(0, 0, 0, 0)',
    NOT_DELIVERED: defaultComponentsColors.disabled,
    NOT_DELIVERED_OVERDUE: 'rgba(236, 170, 36, 0.15)',
    NOT_DELIVERED_BLOCKED: defaultComponentsColors.disabled,
    NOT_DELIVERED_BLOCKED_OVERDUE: 'rgba(236, 170, 36, 0.15)',
  };

  const situationDictionary = {
    DELIVERED: 'Você já entregou essa declaração',
    DELIVERED_PROCESSING: 'Declaração em processamento',
    DELIVERED_PROCESSING_OVERDUE: 'Declaração em processamento',
    NOT_DELIVERED: 'Fazer declaração',
    NOT_DELIVERED_OVERDUE: 'Fazer declaração',
    NOT_DELIVERED_BLOCKED: 'Declare os anos anteriores',
    NOT_DELIVERED_BLOCKED_OVERDUE: 'Declare os anos anteriores',
  };

  const situationIcons = {
    DELIVERED: servicesIcon(
      'check-circle',
      18,
      defaultComponentsColors.success
    ),
    DELIVERED_PROCESSING: '',
    DELIVERED_PROCESSING_OVERDUE: '',
    NOT_DELIVERED: '',
    NOT_DELIVERED_OVERDUE: servicesIcon(
      'alert',
      18,
      defaultComponentsColors.warning
    ),
    NOT_DELIVERED_BLOCKED: servicesIcon(
      'alert',
      18,
      defaultComponentsColors.warning
    ),
    NOT_DELIVERED_BLOCKED_OVERDUE: servicesIcon(
      'alert',
      18,
      defaultComponentsColors.warning
    ),
  };

  const goTo = (route) => {
    navigation.navigate(route, item);
  };

  return (
    <Flex
      mb={3}
      rounded="2xl"
      bgColor="#FFF"
      p={[4, 4, 4, 6]}
      flexDirection={['column', 'column', 'column', 'row']}
      alignItems={['normal', 'normal', 'normal', 'center']}
      justifyContent={[
        'flex-start',
        'flex-start',
        'flex-start',
        'space-between',
      ]}
    >
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        w={['auto', 'auto', 'auto', '1/3']}
      >
        <Text
          fontWeight="bold"
          color={defaultTextColors.default}
          fontSize={['md', 'md', 'md', 'lg']}
        >
          {`Declaração de ${item.ano}`}
        </Text>
        <Box
          h={7}
          px={3}
          rounded="full"
          ml={['auto', 'auto', 'auto', 4]}
          bgColor={statusBgColors[item.status]}
        >
          <Text
            my="auto"
            textAlign="center"
            fontWeight="bold"
            color={defaultTextColors.default}
            fontSize={['2xs', '2xs', '2xs', 'xs']}
          >
            {statusDictiornary[item.status]}
          </Text>
        </Box>
      </Flex>
      {(item.status === 'DELIVERED_PROCESSING' ||
        item.status === 'DELIVERED_PROCESSING_OVERDUE') && (
        <Text
          opacity={60}
          fontWeight="semibold"
          color={defaultTextColors.default}
          fontSize={['sm', 'sm', 'sm', 'md']}
          textAlign={['left', 'left', 'left', 'center']}
        >
          Declaração em processamento
        </Text>
      )}
      {item.status !== 'DELIVERED' &&
        item.status !== 'DELIVERED_PROCESSING' &&
        item.status !== 'DELIVERED_PROCESSING_OVERDUE' && (
          <Text
            opacity={60}
            fontWeight="semibold"
            color={defaultTextColors.default}
            w={['auto', 'auto', 'auto', '1/3']}
            fontSize={['sm', 'sm', 'sm', 'md']}
            textAlign={['left', 'left', 'left', 'center']}
          >
            {`Prazo limite 31/05/${item.ano + 1}`}
          </Text>
        )}
      {(item.status === 'NOT_DELIVERED' ||
        item.status === 'NOT_DELIVERED_OVERDUE') && (
        <Button
          py={4}
          rounded="full"
          h={[8, 8, 8, 10]}
          px={[4, 4, 4, 6]}
          mt={[4, 4, 4, 0]}
          w={['full', 'full', 'full', '1/3']}
          bgColor={defaultComponentsColors.warning}
          onPress={() => goTo('dasn_declaration')}
        >
          <Text
            color="#FFF"
            fontWeight="medium"
            fontSize={['md', 'md', 'md', 'lg']}
          >
            {situationDictionary[item.status]}
          </Text>
        </Button>
      )}
      {(item.status === 'DELIVERED' ||
        item.status === 'NOT_DELIVERED_BLOCKED' ||
        item.status === 'NOT_DELIVERED_BLOCKED_OVERDUE') && (
        <Flex
          mt={[4, 4, 4, 0]}
          alignItems="center"
          flexDirection="row"
          w={['auto', 'auto', 'auto', '1/3']}
          justifyContent={['center', 'center', 'center', 'flex-start']}
        >
          {situationIcons[item.status]}
          <Text
            ml={3}
            fontWeight="bold"
            color={defaultTextColors.default}
            fontSize={['md', 'md', 'md', 'lg']}
          >
            {situationDictionary[item.status]}
          </Text>
        </Flex>
      )}
      {/* Not implemented yet */}
      {/**/}
      {/* {item.status === 'DELIVERED_PROCESSING' && ( */}
      {/*  <Pressable */}
      {/*    mt={4} */}
      {/*    onPress={() => goTo('dasn_status')} */}
      {/*  > */}
      {/*    <Text */}
      {/*      underline */}
      {/*      fontSize='sm' */}
      {/*      fontWeight='bold' */}
      {/*      color={colors.bgPrimary} */}
      {/*    > */}
      {/*      Ver status */}
      {/*    </Text> */}
      {/*  </Pressable> */}
      {/* )} */}
    </Flex>
  );
};

export default DasnItem;
