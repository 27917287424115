import Pusher from 'pusher-js';
import {
  APP_URL,
  API_URL,
  PUSHER_APP_KEY,
  PUSHER_APP_CLUSTER,
  PUSHER_APP_FORCE_TLS,
  // eslint-disable-next-line
} from '@env';
export default (userToken) =>
  new Pusher(PUSHER_APP_KEY, {
    cluster: PUSHER_APP_CLUSTER,
    forceTLS: PUSHER_APP_FORCE_TLS,
    authEndpoint: `${APP_URL}${API_URL}broadcasting/auth`,
    auth: {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    },
  });
