import React from 'react';
import { Flex, Text, Button } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { find, get, last } from 'lodash';
import { useRecoilValue } from 'recoil';
import EmptyStateIlustration from '../../assets/EmptyStateIlustration';
import { ampLogEvent, defaultComponentsColors } from '../../utilites/utilities';
import { userAtom } from '../../Recoil/Atoms/user';
import { servicesStatusAtom } from '../../Recoil/Atoms/servicesStatus';

const NoCompany = ({ px }) => {
  const navigation = useNavigation();
  const userId = useRecoilValue(userAtom).id;
  const servicesState = useRecoilValue(servicesStatusAtom);
  const isFormalizationActive =
    parseInt(
      get(find(servicesState, ['name', 'Abra sua MEI']), 'is_published', 0),
      10
    ) === 1;

  const handleAddCompany = async () => {
    const { routes } = navigation.getState();
    await ampLogEvent(userId, 'Company Register CTA', {
      location: get(last(routes), 'name', 'undefined'),
    });
    navigation.navigate('company_add');
  };

  const handleCreateCompany = () => {
    navigation.navigate('formalizacao');
  };

  return (
    <Flex
      px={px}
      width="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <EmptyStateIlustration
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      />
      <Text mt={6} bold fontSize="xl" textColor="#2c2d49" textAlign="center">
        Cadastre seu CNPJ aqui no Meu Negócio
      </Text>
      <Text
        mt={4}
        fontWeight="semibold"
        fontSize="lg"
        color="rgba(44, 45, 73, 0.4)"
        textAlign="center"
      >
        Comece cadastrando seu CNPJ para ter acesso a todas as facilidades e
        conteúdo!
      </Text>
      <Button
        py={3}
        px={5}
        mt={4}
        fontSize="md"
        rounded="full"
        onPress={() => handleAddCompany()}
        w={['full', 'full', 'full', '1/3']}
        bgColor={defaultComponentsColors.warning}
      >
        <Text fontSize="md" fontWeight="medium" color="#FFF">
          Adicionar empresa
        </Text>
      </Button>
      {isFormalizationActive && (
        <Button
          py={3}
          px={5}
          mt={4}
          fontSize="md"
          rounded="full"
          borderWidth={2}
          onPress={() => handleCreateCompany()}
          w={['full', 'full', 'full', '1/3']}
          bgColor={defaultComponentsColors.transparent}
          borderColor={defaultComponentsColors.warning}
        >
          <Text
            fontSize="md"
            fontWeight="medium"
            color={defaultComponentsColors.warning}
          >
            Abrir MEI
          </Text>
        </Button>
      )}
    </Flex>
  );
};

export default NoCompany;
